import React from 'react';
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useTranslation } from '../../../hooks/useTranslation';
import ROUTES from '../../../routes';
import { Card } from './Card';
import Icon25years from '../../../images/25years.png';
import PGEButton from '../../buttons/PGE-Button';
import { navigate } from '@reach/router';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      width: 'inherit',
      objectFit: 'cover',
      [theme.breakpoints.down('sm')]: {
        height: '170px',
      },
    },
    disclaimer: {
      fontSize: '12px',
      marginTop: theme.spacing(2),
    },
    enrollButton: {
      marginTop: theme.spacing(2),
    },
    content: {
      fontSize: '14px',
    },
  }),
);

function renderSupportGreenFutureContent() {
  const { t, inlineRichT } = useTranslation();
  const classes = useStyles();

  return (
    <Box>
      <Typography className={classes.content}>
        {inlineRichT('SUPPORT_GREEN_FUTURE_CONTENT')}
      </Typography>
      <PGEButton
        className={classes.enrollButton}
        onClick={() => navigate(ROUTES.RENEWABLE_POWER)}
      >
        {t('ENROLL_IN_GREEN_FUTURE')}
      </PGEButton>
      <Typography className={classes.disclaimer}>
        {t('BASED_ON_2023_AVERAGE_RES_CUSTOMER')}
      </Typography>
    </Box>
  );
}

export function SupportGreenFutureCard() {
  const { inlineRichT } = useTranslation();
  const classes = useStyles();
  return (
    <Card
      type="full"
      theme="green"
      title={inlineRichT('SUPPORT_A_GREEN_FUTURE')}
      sectionA={renderSupportGreenFutureContent()}
      sectionB={<img alt={''} src={Icon25years} className={classes.img} />}
    />
  );
}
