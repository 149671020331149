import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Link,
  Typography,
} from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState } from 'react';
import colors from '../../../../themes/main-colors';
import theme from '../../../../themes/theme';

export function WelcomeBackAccordion({
  title,
  category,
  secondaryBar,
  cardContent,
}: {
  title: string;
  category: string;
  secondaryBar: React.ReactNode;
  cardContent: React.ReactNode;
}) {
  const [isExpanded, setIsExapanded] = useState<boolean>(false);

  return (
    <React.Fragment>
      <Accordion
        onChange={(_: React.ChangeEvent<{}>, expanded: boolean) =>
          setIsExapanded(expanded)
        }
        css={{
          marginTop: theme.spacing(4),
          boxShadow: 'none',
          minHeight: 'unset',
          '& .MuiAccordionSummary-content': {
            margin: '0 !important',
            flexDirection: 'column',
          },
          '& .MuiAccordionSummary-root.Mui-expanded': {
            minHeight: 'unset !important',
          },
          borderBottom: '1px solid #D8D8D8',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          css={{
            backgroundColor: colors.grey2,
            display: 'flex',
          }}
        >
          <Box
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Typography
              variant="h4"
              css={{
                color: colors.noirBlur,
                fontWeight: theme.typography.fontWeightBold,
                [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
                  textAlign: 'start',
                  fontSize: '12px',
                },
              }}
            >
              {title}
            </Typography>
            <Typography>{category}</Typography>
          </Box>
        </AccordionSummary>

        <AccordionDetails
          css={{
            padding: 0,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {secondaryBar}
          <Box>
            <Card
              raised={false}
              component="div"
              css={{
                borderRadius: 0,
                backgroundColor: colors.white,
                boxShadow: 'none',
                borderBottom: '1px solid #D8D8D8',
              }}
            >
              <CardContent
                css={{
                  padding: '0',
                  paddingBottom: '0 !important',
                }}
              >
                {cardContent}
              </CardContent>
            </Card>
          </Box>
        </AccordionDetails>
      </Accordion>
      {!isExpanded && secondaryBar}
    </React.Fragment>
  );
}
