import React from 'react';
import { Router } from '@reach/router';
import ROUTES from '../../routes';

import RebateOnly from './ev-charger/rebate-only/';
import PrivateRoute from '../../components/routing/PrivateRoute';
import NotFoundPage from '../../pages/404';
import GetStarted from './get-started/GetStarted';
import PayOutOfPocket from './pay-out-of-pocket';
import Consultation from './consultation';

export const PGEPlusRouter = () => {
  return (
    <Router basepath="/">
      <GetStarted path={ROUTES.PGE_PLUS_GET_STARTED} />
      <Consultation path={`${ROUTES.PGE_PLUS_SECURE_CONSULTATION}/*`} />
      <RebateOnly path={`${ROUTES.PGE_PLUS_REBATE_ONLY_HOME}/*`} />
      <PayOutOfPocket path={`${ROUTES.PGE_PLUS_PAY_OUT_OF_POCKET}/*`} />
      <PrivateRoute path="/*" component={NotFoundPage} />
    </Router>
  );
};
