/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import React from 'react';
import usePaymentForm from '../../../hooks/usePaymentForm';
import routes from '../../../routes';
import { OneTimePayConfigData } from '../../payment-form/oneTimePayment.types';
import PaymentVerificationForm from '../../payment-form/payment-verification-form';
import { toCurrencyString } from '../../../util/format';
import { navigate } from 'gatsby';
import useContentMgmt from '../../../hooks/useContentMgmt';
import microcopyGroupIds from '../../pge-plus-common/microcopyGroupIds';
import { Application } from '../../../__generated__/pgeplus-types';
import { getPOPUrl } from './utils/navigation.util';

type Props = RouteComponentProps & {
  application: Application;
  paymentInfo: OneTimePayConfigData;
  onSubmit: () => void;
};

const PaymentVerify: React.FC<Props> = ({
  application,
  paymentInfo,
  onSubmit,
}) => {
  const payBillProps = usePaymentForm();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const childProps = { isMobile, ...payBillProps };

  const { content } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_POP_PAYMENT_FORM,
  );

  const paymentMethodCapLimit: number = 2;

  const getFormattedPaymentAmount = () => {
    return paymentInfo?.paymentAmount
      ? `$${toCurrencyString(parseFloat(paymentInfo?.paymentAmount), false)}`
      : '';
  };

  const paymentVerificationCancelHandler = () => {
    void navigate(
      getPOPUrl(routes.PGE_PLUS_PAY_OUT_OF_POCKET_PAYMENT_INFO, application.id),
    );
  };

  return (
    <Grid container justify={'center'} alignItems={'center'} direction="column">
      <Grid item>
        <Typography variant={'h1'} css={{ marginBottom: 30 }}>
          {content.get('POP_VERIFY_PAYMENT_TITILE')}
        </Typography>
      </Grid>

      <Grid item css={{ width: '100%', marginBottom: theme.spacing(5) }}>
        <PaymentVerificationForm
          path={routes.PGE_PLUS_PAY_OUT_OF_POCKET_PAYMENT_INFO}
          {...childProps}
          oneTimePayConfigData={paymentInfo}
          formattedPaymentAmount={getFormattedPaymentAmount()}
          onSubmit={onSubmit}
          paymentMethodCapLimit={paymentMethodCapLimit}
          cancelHandler={paymentVerificationCancelHandler}
          backButtonRoute={getPOPUrl(
            routes.PGE_PLUS_PAY_OUT_OF_POCKET_PAYMENT_INFO,
            application.id,
          )}
          nextButtonText={content.get('PAY_BILL') || ''}
        />
      </Grid>
    </Grid>
  );
};

export default PaymentVerify;
