/** @jsx jsx */
import { jsx } from '@emotion/core';

import { STEP_STATUS } from '../../welcomeBack.types';
import React, { Fragment } from 'react';
import { WelcomeBackStepHeader } from '../StepHeader';
import { WelcomeBackStepDetails } from '../StepDetails';
import { WelcomeBackSubHeading } from '../../EVApplication';
import { Box, Grid, MenuItem, Typography } from '@material-ui/core';
import useContentMgmt, {
  PageTextContentType,
} from '../../../../../hooks/useContentMgmt';
import { Job } from '../../../../../__generated__/pgeplus-types';
import { formatDate, toCurrencyString } from '../../../../../util/format';
import { navigate } from 'gatsby';
import PGEPlusButtons from '../../../../pge-plus-common/PGEPlusButton';
import { getPOPUrl } from '../../../../pge-plus/pay-out-of-pocket/utils/navigation.util';
import routes from '../../../../../routes';
import { PictureAsPdf } from '@material-ui/icons';
import colors from '../../../../../themes/main-colors';
import useDownloadBill from '../../../../../hooks/useDownloadBill';
import useWrapWithLoader from '../../../../../hooks/useWrapWithLoading';
import moment from 'moment';

type Props = {
  status: STEP_STATUS;
  content: PageTextContentType;
  job: Job;
};

const InstallationCompleted = ({ status, content, job }: Props) => {
  const { inlineRichText } = useContentMgmt();

  const downloader = useDownloadBill();
  const { wrapWithLoader } = useWrapWithLoader();
  const downloadBill = wrapWithLoader(
    async (e: React.MouseEvent) => {
      if (e) {
        e.preventDefault();
      }

      const downloadLink = document.createElement('a');
      downloadLink.href = await downloader.downloadBill(job?.charges?.billId);

      downloadLink.download = (
        content.get('PGE_PLUS_BILL_FILE_NAME') || ''
      ).replace('{{APPLICATION_NO}}', job.applicationNo);

      downloadLink.click();
    },
    () => content.get('INSTALLATION_COMPLETED') || '',
  );
  //If final bill is generated, just look through the payments made after the final bill to get payments related to final payment.
  let finalPayments = job?.customerPayments;
  if (job?.charges?.isBillGenerated) {
    const billDate = moment(job?.charges?.billDate);
    finalPayments = job?.customerPayments?.filter(p =>
      moment(p.paymentDate).isSameOrAfter(billDate),
    );
  }

  const isDueDateMoreThanTenDaysAgo = () => {
    const dueDate = job?.charges?.dueDate ? moment(job.charges.dueDate) : null;
    const tenDaysAgo = moment().subtract(10, 'days');

    return dueDate && dueDate.isBefore(tenDaysAgo);
  };

  return (
    <Fragment>
      <WelcomeBackStepHeader
        title={content.get('INSTALLATION_COMPLETED') || ''}
      />
      {(status === STEP_STATUS.IN_PROGRESS ||
        status === STEP_STATUS.COMPLETED) && (
        <WelcomeBackStepDetails>
          {(job?.charges?.amountDue ?? 0) > 0 && finalPayments?.length === 0 && (
            <WelcomeBackSubHeading
              subHeading={inlineRichText(
                content.get('PGE_PLUS_FINAL_PAYMENT_DUE'),
                {
                  AMOUNT: toCurrencyString(job?.charges?.amountDue || 0, true),
                  DATE: formatDate(new Date(job?.charges?.dueDate)),
                },
              )}
            />
          )}
          {(job?.charges?.amountDue ?? 0) > 0 &&
            (finalPayments?.length ?? 0) > 0 && (
              <Fragment>
                {finalPayments?.length &&
                  finalPayments?.map(payment => (
                    <WelcomeBackSubHeading
                      subHeading={inlineRichText(
                        content.get('PGE_PLUS_MADE_PAYMENT'),
                        {
                          AMOUNT: toCurrencyString(payment?.amount || 0, true),
                          DATE: formatDate(new Date(payment.paymentDate)),
                        },
                      )}
                    />
                  ))}
              </Fragment>
            )}

          {(job?.charges?.amountDue ?? 0) === 0 && (
            <Fragment>
              <WelcomeBackSubHeading
                subHeading={content.get('READY_TO_ROLL')}
              />
              <Fragment>
                {finalPayments &&
                  finalPayments.slice(0, -1).map(payment => (
                    <WelcomeBackSubHeading
                      subHeading={inlineRichText(
                        content.get('PGE_PLUS_MADE_PAYMENT'),
                        {
                          AMOUNT: toCurrencyString(payment?.amount || 0, true),
                          DATE: formatDate(new Date(payment.paymentDate)),
                        },
                      )}
                    />
                  ))}
              </Fragment>
              {finalPayments && finalPayments?.length > 0 && (
                <Fragment>
                  <WelcomeBackSubHeading
                    subHeading={inlineRichText(
                      content.get('PGE_PLUS_MADE_FINAL_PAYMENT_WITH_NO_REFUND'),
                      {
                        AMOUNT: toCurrencyString(
                          finalPayments[finalPayments.length - 1]?.amount || 0,
                          true,
                        ),
                        DATE: formatDate(
                          new Date(
                            finalPayments[
                              finalPayments.length - 1
                            ]?.paymentDate,
                          ),
                        ),
                        LEADTYPE: job?.leadType || '',
                      },
                    )}
                  />
                </Fragment>
              )}
            </Fragment>
          )}
          {(finalPayments?.length ?? 0) > 0 &&
            (job?.charges?.amountDue ?? 0) > 0 &&
            !isDueDateMoreThanTenDaysAgo() && (
              <Typography css={{ paddingTop: 10, paddingBottom: 10 }}>
                {inlineRichText(content.get('PGE_PLUS_OWE_PAYMENT'), {
                  AMOUNT: toCurrencyString(job?.charges?.amountDue || 0, true),
                  DATE: formatDate(new Date(job?.charges?.dueDate)),
                })}
              </Typography>
            )}
          {(job?.charges?.amountDue ?? 0) > 0 && isDueDateMoreThanTenDaysAgo() && (
            <Fragment>
              <Typography
                style={{
                  color: colors.errorRed,
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                {inlineRichText(content.get('PGE_PLUS_OWE_PAYMENT'), {
                  AMOUNT: toCurrencyString(job?.charges?.amountDue || 0, true),
                  DATE: formatDate(new Date(job?.charges?.dueDate)),
                })}
              </Typography>
              <Typography>
                {content.get('PGE_PLUS_BALANCE_OUTSTANDING')}
              </Typography>
            </Fragment>
          )}
          {(job?.refunds?.length ?? 0) > 0 &&
            job?.refunds?.map(refund => (
              <WelcomeBackSubHeading
                subHeading={inlineRichText(content.get('PGE_PLUS_REFUND'), {
                  AMOUNT: toCurrencyString(refund?.amount || 0, true),
                  DATE: formatDate(new Date(refund?.refundDate)),
                })}
              />
            ))}
          <Grid container spacing={2} css={{ marginTop: 20 }}>
            {(job?.charges?.amountDue ?? 0) > 0 && (
              <Grid
                item
                md={6}
                sm={12}
                xs={12}
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <PGEPlusButtons
                  buttonMode="Emphasis"
                  onClick={() =>
                    navigate(
                      getPOPUrl(
                        routes.PGE_PLUS_PAY_OUT_OF_POCKET_PAYMENT_INFO,
                        job?.applicationId,
                      ),
                    )
                  }
                >
                  {content.get('MAKE_A_PAYMENT')}
                </PGEPlusButtons>
              </Grid>
            )}
            {job?.charges?.billId && (
              <Grid item md={6} sm={12} xs={12}>
                <Box>
                  <MenuItem
                    style={{ paddingLeft: 0, whiteSpace: 'normal' }}
                    onClick={e => downloadBill(e)}
                  >
                    <PictureAsPdf
                      style={{ color: colors.roseRed, marginRight: '10px' }}
                    />
                    <Typography
                      style={{
                        width: '100%',
                        letterSpacing: 0,
                        fontWeight: 600,
                        fontFamily: 'Untitled-Sans',
                        color: colors.sparkBlue,
                        fontSize: '1.2rem',
                        cursor: 'pointer',
                      }}
                    >
                      {content.get('PGE_PLUS_DOWNLOAD_BILL')}
                    </Typography>
                  </MenuItem>
                </Box>
              </Grid>
            )}
          </Grid>
        </WelcomeBackStepDetails>
      )}
    </Fragment>
  );
};

export default InstallationCompleted;
