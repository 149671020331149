import React, { useEffect } from 'react';
import useInstallerAuth from '../../../hooks/pge-plus/useInstallerAuth';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import ROUTES from '../../../routes';

interface IPrivateRoute {
  component: React.ComponentType<any>;
  path: string;
}

const PrivateInstallerRoute = ({
  component: Component,
  ...rest
}: IPrivateRoute) => {
  const location = useLocation();
  const { isAuthenticated, isLoading } = useInstallerAuth();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(ROUTES.PGE_PLUS_INSTALLER_PORTAL_SIGN_IN, {
        state: { redirectUrl: location.pathname },
        replace: true,
      });
    }
  }, [isAuthenticated, isLoading]);

  // Don't render the component until authenticated
  if (isLoading || !isAuthenticated) {
    return null;
  }

  return <Component {...rest} />;
};

export default PrivateInstallerRoute;
