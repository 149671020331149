import { RouteComponentProps, Router } from '@reach/router';
import React, { useEffect } from 'react';
import { ChooseAccount as ChooseAccountPage } from '../../../components/pge-plus/consultation/choose-account';
import PrivateRoute from '../../../components/routing';
import NotFoundPage from '../../../pages/404';
import DetailsPage from '../../../pages/pge-plus/consultation/details';
import routes from '../../../routes';
import SuccessPage from '../../../pages/pge-plus/consultation/success';
import { useConsultation } from '../../../components/pge-plus/consultation/useConsultation';
import { navigate } from 'gatsby';

const Consultation: React.FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const {
    isPGEPlusConsultationEnabled,
    isBrowser,
    featureFlagLoading,
  } = useConsultation();

  useEffect(() => {
    if (!isPGEPlusConsultationEnabled && isBrowser && !featureFlagLoading) {
      void navigate(routes.ACCOUNT);
    }
  }, []);

  return (
    <Router basepath="/">
      <ChooseAccountPage path={routes.PGE_PLUS_CONSULTATION_CHOOSE_ACCOUNT} />
      <DetailsPage path={routes.PGE_PLUS_SECURE_CONSULTATION_DETAILS} />
      <SuccessPage path={routes.PGE_PLUS_SECURE_CONSULTATION_SUCCESS} />
      <PrivateRoute path="/*" component={NotFoundPage} />
    </Router>
  );
};

export default Consultation;
