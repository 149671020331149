import gql from 'graphql-tag';
import { useEffect, useState } from 'react';
import {
  GetJobsInput,
  GetJobsOutput,
  GetJobsSortInput,
  JobStatus,
} from '../../__generated__/pgeplus-types';
import useInstallerAuthQuery from './useInstallerAuthQuery';
import useUtil from './useUtil';
import {
  getFromSessionStorage,
  setInSessionStorage,
} from '../../util/storage-utils';

export enum TableControlsActions {
  PAGINATION,
  SEARCH,
  SORT,
  FILTER,
}

export type TableControlsType = (
  action: TableControlsActions,
  values: string | number | JobStatus[] | GetJobsSortInput,
) => void;

const GET_JOBS_QUERY = gql`
  query GetJobs($input: GetJobsInput!) {
    getJobs(input: $input) {
      jobs {
        id
        applicationNo
        status
        isOpened
        quote {
          status
          version
          isInstallerToCollectPayment
        }
        serviceTime {
          start
          end
        }
        createdAt
        jobLocation {
          addressLine1
          firstName
          lastName
          city
          postalCode
        }
      }
      pagination {
        page
        totalPages
      }
    }
  }
`;
const INSTALLER_PORTAL_JOB_LIST_FILTER = 'installerportaljobsfilter';

export const useJobsList = () => {
  const { setErrorNotification } = useUtil();
  const [counter, setCounter] = useState<number>(2);
  const [searchValue, setSearchValue] = useState<string>('');
  const [input, setInput] = useState<GetJobsInput>(() => {
    const localstorageValue = getFromSessionStorage(
      INSTALLER_PORTAL_JOB_LIST_FILTER,
    );
    if (localstorageValue) {
      const savedValues: GetJobsInput = JSON.parse(localstorageValue);
      setSearchValue(savedValues?.searchText || '');
      return JSON.parse(localstorageValue);
    }

    return {
      pagination: {
        page: 1,
        perPage: 20,
      },
      status: [],
    };
  });

  const { loading, data } = useInstallerAuthQuery<{
    getJobs: GetJobsOutput;
    input: GetJobsInput;
  }>(GET_JOBS_QUERY, {
    variables: {
      input,
    },
    fetchPolicy: 'no-cache',
    onError: () => setErrorNotification(true),
  });

  useEffect(() => {
    setInSessionStorage(
      INSTALLER_PORTAL_JOB_LIST_FILTER,
      JSON.stringify(input),
    );
  }, [input]);

  const tableControls = (
    action: TableControlsActions,
    value: JobStatus[] | GetJobsSortInput | string | number,
  ) => {
    if (action === TableControlsActions.PAGINATION) {
      setInput({
        ...input,
        searchText: Boolean(searchValue) ? searchValue : undefined,
        pagination: {
          page: value as number,
          perPage: 20,
        },
      });
      setCounter(prev => prev + 1);
      return;
    }
    if (action === TableControlsActions.SEARCH) {
      setInput({
        ...input,
        searchText: value as string,
        pagination: {
          page: 1,
          perPage: 20,
        },
      });
    }
    if (action === TableControlsActions.SORT) {
      setInput({
        ...input,
        searchText: Boolean(searchValue) ? searchValue : undefined,
        sort: { ...(value as GetJobsSortInput) },
        pagination: {
          page: 1,
          perPage: 20,
        },
      });
    }
    if (action === TableControlsActions.FILTER) {
      setInput({
        ...input,
        searchText: Boolean(searchValue) ? searchValue : undefined,
        status: value as JobStatus[],
        pagination: {
          page: 1,
          perPage: 20,
        },
      });
    }
    setCounter(2);
  };

  return {
    loading,
    jobs: data?.getJobs?.jobs,
    pagination: data?.getJobs?.pagination,
    counter,
    tableControls,
    searchValue,
    setSearchValue,
    searchParams: input,
  };
};
