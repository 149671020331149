import gql from 'not-graphql-tag';
import useAuthQuery from './useAuthQuery';
import {
  IsPaperlessBillEnrolledRequest,
  IsPaperlessBillEnrolledResponse,
} from '../__generated__/pge-types';

const IS_PAPERLESS_BILL_ENROLLED = gql`
  query isPaperlessBillEnrolled($payload: IsPaperlessBillEnrolledRequest) {
    isPaperlessBillEnrolled(payload: $payload) {
      result
    }
  }
`;

interface Request {
  payload: IsPaperlessBillEnrolledRequest;
}

interface Response {
  isPaperlessBillEnrolled: IsPaperlessBillEnrolledResponse;
}

export default function useIsPaperlessBillEnrolled(
  encryptedPersonId: string,
  encryptedAccountNumber: string,
) {
  const { data, loading } = useAuthQuery<Response, Request>(
    IS_PAPERLESS_BILL_ENROLLED,
    {
      variables: {
        payload: {
          encryptedPersonId: encryptedPersonId || '',
          encryptedAccountNumber: encryptedAccountNumber || '',
        },
      },
      fetchPolicy: 'network-only',
      skip: !encryptedPersonId || !encryptedAccountNumber,
    },
  );

  const isEnrolled = data?.isPaperlessBillEnrolled?.result ?? false;

  return {
    isPaperlessBillEnrolled: isEnrolled,
    loading,
  };
}
