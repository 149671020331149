import React, { Fragment } from 'react';

import { STEP_STATUS } from '../welcomeBack.types';
import { WelcomeBackStep } from './Step';
import IncomingInstallation from './steps/IncomingInstallation';
import InstallationCompleted from './steps/InstallationCompleted';
import { PageTextContentType } from '../../../../hooks/useContentMgmt';
import {
  ApplicationEr,
  ApplicationEv,
  ApplicationStatus,
} from '../../../../__generated__/pgeplus-types';

interface StepProps {
  getStepStatus: (step: number) => STEP_STATUS;
  content: PageTextContentType;
  appllication: ApplicationEv | ApplicationEr;
  isClosed?: boolean;
}

export function WelcomeBackPayments({
  getStepStatus,
  content,
  appllication,
  isClosed,
}: StepProps) {
  return (
    <Fragment>
      <WelcomeBackStep step={4} status={getStepStatus(4)} isClosed={isClosed}>
        <IncomingInstallation
          status={getStepStatus(4)}
          content={content}
          job={appllication?.job!}
        />
      </WelcomeBackStep>
      <WelcomeBackStep
        step={5}
        status={
          appllication?.job?.charges?.isBillGenerated &&
          appllication.status === ApplicationStatus.Completed
            ? STEP_STATUS.COMPLETED
            : getStepStatus(5)
        }
        isClosed={isClosed}
      >
        <InstallationCompleted
          status={
            appllication?.job?.charges?.isBillGenerated &&
            appllication.status === ApplicationStatus.Completed
              ? STEP_STATUS.COMPLETED
              : getStepStatus(5)
          }
          content={content}
          job={appllication?.job!}
        />
      </WelcomeBackStep>
    </Fragment>
  );
}
