import React, { useEffect, useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import {
  GetApplicationOutput,
  MutationMakePaymentForJobArgs,
  PaymentConfirmationList,
  PaymentInputList,
  QueryGetApplicationArgs,
} from '../../__generated__/pgeplus-types';
import useFormState from '../useFormState';
import microcopyGroupIds from '../../components/pge-plus-common/microcopyGroupIds';
import useContentMgmt from '../useContentMgmt';
import gql from 'not-graphql-tag';
import { OneTimePayConfigData } from '../../components/payment-form/oneTimePayment.types';
import { navigate } from '@reach/router';
import { getPOPUrl } from '../../components/pge-plus/pay-out-of-pocket/utils/navigation.util';
import routes from '../../routes';
import { PayOutOfPocketState } from '../../components/pge-plus/pay-out-of-pocket/pop.types';
import useAccountCustomer from '../useAccountCustomer';
import useUtil from './useUtil';
import { useTranslation } from '../useTranslation';
import useWrapWithLoader from '../useWrapWithLoading';

export const getApplicationByApplicationNo = gql`
  query getApplication($input: GetApplicationInput!) {
    getApplication(input: $input) {
      application {
        accountId
        applicationNo
        applicationType
        createdAt
        pgePlusAccountId
        encryptedPgePlusAccountId
        id
        job {
          applicationId
          applicationNo
          createdAt
          id
          isOpened
          charges {
            amountDue
            billDate
            billId
            dueDate
            isBillGenerated
            remainingAmount
            totalAmount
          }
          jobLocation {
            addressLine1
            city
            email
            firstName
            lastName
            middleName
            phone
            postalCode
            state
          }
          status
        }
        financialStanding {
          hasActiveBankruptcy
          hasMissedPGEPlusPayment
          hasPGEPlusDebt
        }
      }
    }
  }
`;

export const submitPaymentMutation = gql`
  mutation makePaymentForJob($input: PaymentInputList!) {
    makePaymentForJob(input: $input) {
      paymentConfirmation {
        cardType
        confirmationId
        digitalWalletEmail
        errorCode
        isSuccess
        lastFourDigitsCardNumber
        paymentAmount
        paymentDate
        paymentMethod
        paymentStatus
      }
    }
  }
`;

const usePayOutOfPocket = (applicationId: string) => {
  const [payOutOfPocketState, setPayOutOfPocketState] = useState<
    PayOutOfPocketState
  >({
    preference: {},
    billingInfo: {
      emailAddress: '',
      name: '',
      phoneNumber: '',
      addressLine1: '',
      consent: '',
      goPaperLess: true,
    },
    paymentInfo: null,
    paymentConfirmation: undefined,
  });
  const { customer } = useAccountCustomer();
  const { data, loading, refetch } = useQuery<
    {
      getApplication: GetApplicationOutput;
    },
    QueryGetApplicationArgs
  >(getApplicationByApplicationNo, {
    variables: {
      input: {
        applicationId,
      },
    },
    skip: !applicationId,
    context: { pgePlus: true },
    fetchPolicy: 'no-cache',
  });
  const apolloClient = useApolloClient();

  const { setErrorNotification } = useUtil();
  const { richT } = useTranslation();
  const { wrapWithLoader } = useWrapWithLoader();

  const jobLocation = data?.getApplication?.application?.job?.jobLocation;

  useEffect(() => {
    if (!loading) {
      const primaryContact = customer?.contactDetails?.find(contact => {
        return contact?.contactType === 'PNP';
      });

      setPayOutOfPocketState(state => ({
        ...state,

        billingInfo: {
          emailAddress: customer?.email as string,
          name: customer?.personName as string,
          phoneNumber: primaryContact?.contactValue as string,
          addressLine1: jobLocation?.addressLine1 as string,
          goPaperLess: true,
          consent: '',
        },
      }));
    }
  }, [data, customer, loading]);

  const form = useFormState({
    paymentPreference: '',
  });

  const savePaymentInfo = (paymentInfo: OneTimePayConfigData) => {
    setPayOutOfPocketState(state => ({
      ...state,
      paymentInfo,
    }));

    void navigate(
      getPOPUrl(
        routes.PGE_PLUS_PAY_OUT_OF_POCKET_PAYMENT_VERIFY,
        applicationId,
      ),
    );
  };

  const submitPayment = wrapWithLoader(async () => {
    const payload: PaymentInputList = {
      applicationId,
      payments: [],
    };

    const { paymentInfo } = payOutOfPocketState;

    paymentInfo?.paymentSelector.selectedPaymentProfileList.map(p => {
      payload.payments.push({
        personId: customer?.encryptedPersonId,
        tokenId: p.profile.token,
        paymentAmount:
          paymentInfo?.paymentSelector.selectedPaymentProfileList.length === 2
            ? Number(Number(p.value).toFixed(2))
            : Number(Number(paymentInfo?.paymentAmount).toFixed(2)),
        paymentDate: paymentInfo.paymentDate,
      });
    });

    try {
      const response = await apolloClient.mutate<
        {
          makePaymentForJob: PaymentConfirmationList;
        },
        MutationMakePaymentForJobArgs
      >({
        mutation: submitPaymentMutation,
        variables: { input: payload },
        errorPolicy: 'all',
        context: { pgePlus: true },
      });

      if (response.data?.makePaymentForJob?.paymentConfirmation.length) {
        setPayOutOfPocketState(state => ({
          ...state,
          paymentConfirmation:
            response.data?.makePaymentForJob?.paymentConfirmation,
        }));

        void navigate(
          getPOPUrl(
            routes.PGE_PLUS_PAY_OUT_OF_POCKET_PAYMENT_CONFIRMATION,
            applicationId,
          ),
        );
      } else {
        setErrorNotification(true, richT('PAYMENT_ERROR_MESSAGE'));
      }
    } catch (_) {
      setErrorNotification(true, richT('PAYMENT_ERROR_MESSAGE'));
    }
  });

  return {
    form,
    application: data?.getApplication.application,
    loading,
    refetchApplication: refetch,
    payOutOfPocketState,
    savePaymentInfo,
    submitPayment,
  };
};

export default usePayOutOfPocket;
