/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, { Fragment, useState } from 'react';
import { Box, Button, Divider, Link, Typography } from '@material-ui/core';
import colors from '../../../../themes/main-colors';
import theme from '../../../../themes/theme';
import GetInTouchModal from './GetInTouchModal';
import GetSupportIcon from '../../../pge-plus-common/static/get-support.svg';
import microcopyGroupIds from '../../../pge-plus-common/microcopyGroupIds';
import useContentMgmt from '../../../../hooks/useContentMgmt';

type Props = {
  title: string;
  appNumber: React.ReactNode;
  appDate: React.ReactNode;
  applicationId: string;
};

const WelcomeBackHeader = ({
  title,
  appNumber,
  appDate,
  applicationId,
}: Props) => {
  const [getInTouchModalVisibility, setGetInTouchModalVisibility] = useState(
    false,
  );

  const { content } = useContentMgmt(microcopyGroupIds.PGE_PLUS_WELCOME_BACK);

  return (
    <Fragment>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        padding={'10px 20px'}
        css={{
          backgroundColor: colors.navBarHoverBackground,
        }}
      >
        <Typography
          variant="h4"
          css={{
            color: colors.noirBlur,
            fontWeight: theme.typography.fontWeightBold,
            [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
              textAlign: 'start',
              fontSize: '12px',
            },
          }}
        >
          {title}
        </Typography>

        <Box
          display={'flex'}
          alignItems={'center'}
          css={{ cursor: 'pointer' }}
          onClick={() => setGetInTouchModalVisibility(true)}
        >
          <Typography
            variant="h4"
            css={{
              color: colors.sparkBlue,
              fontWeight: theme.typography.fontWeightBold,
              fontSize: '14px',
              [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
                textAlign: 'start',
                fontSize: '12px',
              },
            }}
          >
            {content.get('GET_SUPPORT')}
          </Typography>
          <GetSupportIcon css={{ marginLeft: '8px' }} width={18} height={18} />
        </Box>
      </Box>
      <Box
        css={{
          display: 'flex',
          padding: '12px',
          justifyContent: 'space-between',
          backgroundColor: colors.navBarBackground,
        }}
      >
        {appNumber}
        {appDate}
      </Box>
      <Divider css={{ background: colors.lightGray1 }} />

      <GetInTouchModal
        open={getInTouchModalVisibility}
        onClose={() => setGetInTouchModalVisibility(false)}
        applicationId={applicationId}
      />
    </Fragment>
  );
};

export default WelcomeBackHeader;
