import React, { useState, Fragment } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Box, Typography, useTheme, Grid } from '@material-ui/core';
import useContentMgmt from '../../../hooks/useContentMgmt';
import microcopyGroupIds from '../../pge-plus-common/microcopyGroupIds';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import colors from '../../../themes/main-colors';
import PGEButton from '../../buttons';
import { Link, navigate } from 'gatsby';
import routes from '../../../routes';
import { DownloadAndPrint } from '../../download-print/DownloadAndPrint';
import {
  Application,
  ApplicationType,
  PaymentCardType,
  PaymentConfirmation,
} from '../../../__generated__/pgeplus-types';
import PaymentSuccess from './payment-confirmation-messages/PaymentSuccess';
import PaymentFailed from './payment-confirmation-messages/PaymentFailed';
import PaymentPartialSuccess from './payment-confirmation-messages/PaymentPartialSuccess';
import { OneTimePayConfigData } from '../../payment-form/oneTimePayment.types';
import { getPaymentMethodLabel } from '../../../hooks/usePaymentus';
import {
  PaymentCategory,
  PaymentProfile,
} from '../../../__generated__/pge-types';
import { truncateWithEllipses } from '../../../util/format';
import { useTranslation } from '../../../hooks/useTranslation';
import { getPOPUrl } from './utils/navigation.util';

const PRINT_CONTAINER_ID = 'pge-plus-payment-receipt';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accept: {
      color: colors.white,
      minWidth: '160px',
      fontSize: '18px',
      border: '2px solid',
      borderColor: colors.sparkBlue,
      background: colors.sparkBlue,
      borderRadius: '30px',
      '&:hover': {
        backgroundColor: colors.buttonHoverBackground,
        borderColor: colors.buttonHoverBackground,
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    tryAgain: {
      color: colors.errorRed,
      minWidth: '160px',
      fontSize: '18px',
      border: '2px solid',
      borderColor: colors.errorRed,
      background: 'transparent',
      borderRadius: '30px',
      '&:hover': {
        backgroundColor: 'transparent',
        borderColor: colors.errorRed,
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
  }),
);

type Props = RouteComponentProps & {
  application: Application;
  paymentConfirmation: PaymentConfirmation[];
  paymentInfo: OneTimePayConfigData;
};

const PaymentConfirmationPage: React.FC<Props> = ({
  paymentConfirmation,
  paymentInfo,
  application,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const paymentMethodCapLimit = 2;
  const { t } = useTranslation();
  const { content } = useContentMgmt(microcopyGroupIds.PGE_PLUS_POP_SUCCESS);

  const programName =
    application?.applicationType === ApplicationType.Ev
      ? content.get('PGE_PLUS_PROGRAM_EV')
      : '';

  const getMethodDisplayLabel = (
    paymentConfirmationData: PaymentConfirmation,
  ) =>
    getPaymentMethodLabel(
      paymentConfirmationData.cardType !== PaymentCardType.Unknown
        ? paymentConfirmationData.paymentMethod === 'DC'
          ? paymentConfirmationData.cardType + '_DEBIT'
          : paymentConfirmationData.cardType
        : paymentConfirmationData.paymentMethod || '',
      true,
    );

  const getMaskedAccountDisplayLabel = (
    paymentConfirmationData: PaymentConfirmation,
    paymentProfile: PaymentProfile,
  ) => {
    return [
      PaymentCategory.PaypalAccount,
      PaymentCategory.PaypalCredit,
      PaymentCategory.AmazonPay,
    ]
      .map(p => p.toString())
      .includes(paymentConfirmationData.cardType)
      ? paymentProfile.cardHolderName
        ? truncateWithEllipses(paymentConfirmationData.digitalWalletEmail, 7)
        : t('GUEST_PAY_AUTHORIZED')
      : '******' + paymentConfirmationData.lastFourDigitsCardNumber;
  };

  const hasAllFailure = paymentConfirmation?.every(p => p.isSuccess === false);

  const hasAllSuccess = paymentConfirmation?.every(p => p.isSuccess === true);

  const isPartialSuccess =
    !!paymentConfirmation?.find(p => p.isSuccess) &&
    !!paymentConfirmation?.find(p => !p.isSuccess);

  const isInitialPayment = !application?.job?.charges?.isBillGenerated;

  return (
    <Fragment>
      <form id={PRINT_CONTAINER_ID} noValidate>
        <Box>
          <Grid
            container
            spacing={2}
            justify="center"
            alignItems="center"
            direction="column"
            css={{
              [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
                display: 'block',
              },
            }}
          >
            <Typography css={{ margin: '1rem' }} variant="h1">
              {content.get('POP_PAYMENT_SUCCESS_TITLE')}
            </Typography>
          </Grid>
        </Box>

        {hasAllSuccess && (
          <PaymentSuccess
            content={content}
            programName={programName!}
            paymentConfirmation={paymentConfirmation}
            paymentInfo={paymentInfo}
            paymentMethodCapLimit={paymentMethodCapLimit}
            getMethodDisplayLabel={getMethodDisplayLabel}
            getMaskedAccountDisplayLabel={getMaskedAccountDisplayLabel}
            isInitialPayment={isInitialPayment}
          />
        )}

        {hasAllFailure && (
          <PaymentFailed
            paymentConfirmation={paymentConfirmation}
            paymentInfo={paymentInfo}
            paymentMethodCapLimit={paymentMethodCapLimit}
            getMethodDisplayLabel={getMethodDisplayLabel}
            getMaskedAccountDisplayLabel={getMaskedAccountDisplayLabel}
          />
        )}

        {isPartialSuccess && (
          <PaymentPartialSuccess
            content={content}
            programName={programName!}
            paymentConfirmation={paymentConfirmation}
            paymentInfo={paymentInfo}
            paymentMethodCapLimit={paymentMethodCapLimit}
            getMethodDisplayLabel={getMethodDisplayLabel}
            getMaskedAccountDisplayLabel={getMaskedAccountDisplayLabel}
          />
        )}

        <Grid container>
          <Grid
            item
            css={{
              marginTop: 8,
              '& .MuiTypography-root': { fontSize: 20, fontWeight: 600 },
            }}
          >
            <DownloadAndPrint
              containerId={PRINT_CONTAINER_ID}
              excludeSelectors={['[data-noprint]']}
            ></DownloadAndPrint>
          </Grid>
        </Grid>

        <Fragment>
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'row'}
            mt="50px"
            css={{ gap: 32 }}
            data-noprint
          >
            {(hasAllFailure || isPartialSuccess) && (
              <Box>
                <Link
                  to={getPOPUrl(
                    routes.PGE_PLUS_PAY_OUT_OF_POCKET_PAYMENT_INFO,
                    application.id,
                  )}
                >
                  <PGEButton
                    fullWidth
                    type={'button'}
                    variant={'outlined'}
                    color={'secondary'}
                    className={classes.tryAgain}
                  >
                    {content.get('TRY_AGAIN')}
                  </PGEButton>
                </Link>
              </Box>
            )}
            <Box>
              <Link to={routes.PGE_PLUS_EV_CHARGERS_WELCOME_BACK}>
                <PGEButton
                  fullWidth
                  type={'button'}
                  variant={'outlined'}
                  color={'secondary'}
                  className={classes.accept}
                >
                  {content.get('DONE')}
                </PGEButton>
              </Link>
            </Box>
          </Box>
        </Fragment>
      </form>
    </Fragment>
  );
};

export default PaymentConfirmationPage;
