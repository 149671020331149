/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import { STEP_STATUS } from '../../welcomeBack.types';
import React, { Fragment, useState } from 'react';
import { WelcomeBackStepHeader } from '../StepHeader';
import { WelcomeBackStepDetails } from '../StepDetails';
import { WelcomeBackSubHeading } from '../../EVApplication';
import { Box, Typography } from '@material-ui/core';
import { PageTextContentType } from '../../../../../hooks/useContentMgmt';
import { useIsMobile } from '../../../../../util/style-utils';
import PGEPlusButtons from '../../../../pge-plus-common/PGEPlusButton';
import GetInTouchModal from '../GetInTouchModal';
import {
  JobStatus,
  ServiceProviderInfo,
} from '../../../../../__generated__/pgeplus-types';

type Props = {
  status: STEP_STATUS;
  content: PageTextContentType;
  installerInfo: ServiceProviderInfo | undefined;
  jobStatus: JobStatus | undefined;
  isQuoteCreated: boolean;
  applicationId: string;
  acceptedDate: Date;
};

const InstallerInformation = ({
  status,
  content,
  installerInfo,
  jobStatus,
  isQuoteCreated,
  applicationId,
  acceptedDate,
}: Props) => {
  const isMobile = useIsMobile();
  const [getInTouchModalVisibility, setGetInTouchModalVisibility] = useState(
    false,
  );

  const isFourWeekdaysPassed = (dateval: Date): boolean => {
    const currentDate = new Date();
    let weekdaysCount = 0;

    if (dateval > currentDate) {
      return false;
    }
    const current = new Date(dateval);
    while (current < currentDate) {
      current.setDate(current.getDate() + 1);
      // current day is a weekday (Mon-Fri)
      if (current.getDay() !== 0 && current.getDay() !== 6) {
        weekdaysCount++;
      }
      if (weekdaysCount >= 4) {
        return true;
      }
    }
    return false;
  };
  const showNotContactedButton = isFourWeekdaysPassed(acceptedDate);
  return (
    <Fragment>
      <WelcomeBackStepHeader
        title={
          isQuoteCreated
            ? content.get(
                'INSTALLATION_CUSTOMER_WELCOME_BACK_STEP_2_INSTALLER_CONTACT_AND_DETAILS',
              ) || ''
            : content.get('INSTALLER_WILL_CONTACT_YOU_SOON') || ''
        }
      />

      {(status === STEP_STATUS.IN_PROGRESS ||
        status === STEP_STATUS.COMPLETED) && (
        <WelcomeBackStepDetails>
          {status === STEP_STATUS.IN_PROGRESS && (
            <WelcomeBackSubHeading
              subHeading={content.get(
                'INSTALLER_INFORMATION_WELCOME_BACK_STEP_TEXT',
              )}
            />
          )}

          <Box
            display={'flex'}
            justifyContent={isMobile ? 'center' : 'space-between'}
            marginTop={'20px'}
            alignItems={isMobile ? 'normal' : 'center'}
            flexDirection={isMobile ? 'column' : 'row'}
          >
            {jobStatus !== JobStatus.Created && installerInfo && (
              <Box marginBottom={isMobile ? '1.5rem' : '.5rem'}>
                {status !== STEP_STATUS.COMPLETED && (
                  <Typography>
                    {content.get(
                      'INSTALLATION_CUSTOMER_WELCOME_BACK_STEP_2_INSTALLER_CONTACT',
                    )}
                  </Typography>
                )}
                <Typography css={{ fontWeight: 'bold' }}>
                  {installerInfo?.firstName} {installerInfo?.lastName}
                </Typography>
                <Typography css={{ fontWeight: 'bold' }}>
                  {installerInfo?.phone}
                </Typography>
                <Typography css={{ fontWeight: 'bold' }}>
                  {installerInfo?.email}
                </Typography>
              </Box>
            )}
            {isQuoteCreated ? (
              <Fragment></Fragment>
            ) : (
              acceptedDate &&
              showNotContactedButton && (
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography css={{ marginBottom: 10 }}>
                    {content.get('NOT_HEARD_FROM_INSTALLER')}
                  </Typography>
                  <PGEPlusButtons
                    variant="outlined"
                    onClick={() => setGetInTouchModalVisibility(true)}
                  >
                    {content.get('REACH_OUT')}
                  </PGEPlusButtons>
                </Box>
              )
            )}
          </Box>
        </WelcomeBackStepDetails>
      )}
      <GetInTouchModal
        open={getInTouchModalVisibility}
        onClose={() => setGetInTouchModalVisibility(false)}
        applicationId={applicationId}
        reasonSelected={true}
      />
    </Fragment>
  );
};

export default InstallerInformation;
