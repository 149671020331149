/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React from 'react';
import { RouteComponentProps } from '@reach/router';
import {
  Box,
  createStyles,
  Grid,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core';
import PGEButton from '../../buttons/PGE-Button';
import theme from '../../../themes/theme';
import mainColors from '../../../themes/main-colors';
import useContentMgmt from '../../../hooks/useContentMgmt';
import microcopyGroupIds from '../../pge-plus-common/microcopyGroupIds';

const useStyles = makeStyles(() =>
  createStyles({
    submit: {
      backgroundColor: mainColors.orange,
      borderColor: mainColors.orange,
      borderRadius: '30px',
      minWidth: '130px',
      '&:hover': {
        backgroundColor: mainColors.orange,
        borderColor: mainColors.orange,
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    link: {
      fontSize: '18px',
      fontWeight: 700,
      cursor: 'pointer',
      [theme.breakpoints.down('xs')]: {
        fontSize: 16,
      },
    },
    container: {
      marginTop: 24,
      marginBottom: 24,
      justifyContent: 'center',

      [theme.breakpoints.down('md')]: {
        flexDirection: 'column-reverse',
      },
    },
    gridItem: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 32,
    },
  }),
);

const Ineligible: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const { content, richText } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_POP_INELIGIBLE,
  );

  return (
    <Box
      css={{
        marginTop: theme.spacing(3),
      }}
    >
      <Typography
        css={{
          textAlign: 'center',
          lineHeight: `${theme.spacing(4.5)}px !important`,
        }}
        variant={'h1'}
      >
        {content.get('POP_INELIGIBLE_TITLE')}
      </Typography>

      <Box
        css={css`
          margin-top: 32px;
          & a {
            text-decoration: none;
          }
        `}
      >
        <Typography
          variant="body1"
          component={'div'}
          css={{
            fontSize: 20,
            marginTop: theme.spacing(2.5),
            [theme.breakpoints.down('xs')]: {
              fontSize: 16,
            },
          }}
        >
          {richText(content.get('OUTSTANDING_POP_BALANCE'))}
        </Typography>
      </Box>

      <Grid container spacing={2} className={classes.container}>
        <Grid
          item
          className={classes.gridItem}
          css={{
            marginRight: 16,
          }}
        >
          <Link variant="body1" className={classes.link}>
            {content.get('BACK_TO_MY_ACCOUNT')}
          </Link>
        </Grid>
        <Grid item className={classes.gridItem}>
          <PGEButton
            fullWidth
            type={'button'}
            variant={'contained'}
            className={classes.submit}
          >
            {content.get('PAY_BALANCE')}
          </PGEButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Ineligible;
