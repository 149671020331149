import {
  validatePhoneNumber,
  validateEmail,
  validateExistsAndAlphabetCharacters,
  isValidEmail,
} from '../../../../util/form-validation';
import { useTranslation } from '../../../../hooks/useTranslation';
import { convertValidationRules } from '../../../../hooks/useFormState';

export type Model = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailAddress: string;
  alternateEmail?: string;
  reason: { value: string; label: string } | string | undefined;
  comments?: string;
};
type AdditionalContext = {
  content: Map<string, string>;
};
const { t } = useTranslation();

const validateAltEmail = (email = ''): string | null => {
  if (!isValidEmail(email)) {
    return t('CONTACT_INFO_EMAIL_ADDRESS_INVALID_ERROR');
  }
  return null;
};
export default convertValidationRules<Model, AdditionalContext>(context => {
  const { values, content } = context;

  return {
    firstName: validateExistsAndAlphabetCharacters.bind(null, t('NAME')),
    phoneNumber: validatePhoneNumber,
    lastName: validateExistsAndAlphabetCharacters.bind(null, t('LAST_NAME')),
    emailAddress: validateEmail,
    alternateEmail: validateAltEmail,
    reason: value => {
      if (!(value as string)?.length) {
        return content.get('SELECT_A_VALID_REASON');
      }
      return null;
    },
    comments: value => {
      if (values.reason === content.get('SUPPORT_OPTION_OTHER')) {
        if (!value?.length) {
          return content.get('FIELD_IS_REQUIRED');
        }
      }

      return null;
    },
  };
});
