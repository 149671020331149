import React from 'react';
import Buttons from '../buttons';
import { PGEButtonProps } from '../buttons/PGE-Button';
import mainColors from '../../themes/main-colors';
import { useIsMobile } from '../../util/style-utils';

export interface PGEPlusButton extends PGEButtonProps {
  buttonMode?: 'Primary' | 'Emphasis';
  onclick?: any;
}

const PGEPlusButtons = ({
  buttonMode = 'Primary',
  variant = 'contained',
  children,
  onclick,
  ...rest
}: PGEPlusButton) => {
  const isMobile = useIsMobile();
  const backgroundColor =
    variant === 'outlined' && buttonMode === 'Primary'
      ? mainColors.white
      : buttonMode === 'Emphasis'
      ? mainColors.orange
      : mainColors.sparkBlue;

  const color =
    variant === 'outlined' ? mainColors.sparkBlue : mainColors.white;

  return (
    <Buttons
      type="submit"
      variant={variant}
      onClick={onclick}
      css={{
        padding: `${isMobile ? '0 5px' : '0 28px'}`,
        borderRadius: '30px',
        fontSize: '1rem',
        height: '3rem',
        width: `${isMobile ? '100%' : 'inherit'}`,
        textAlign: 'center',
        color,
        backgroundColor,
        minWidth: isMobile ? 'auto' : '20rem',
        borderWidth: variant === 'outlined' ? 'medium' : 'none',
        borderColor: variant === 'outlined' ? color : backgroundColor,
        '&:hover': {
          backgroundColor,
          color,
          borderWidth: variant === 'outlined' ? 'medium' : 'none',
          borderColor: variant === 'outlined' ? color : backgroundColor,
        },
        '.MuiButton-label': {
          fontWeight: variant === 'outlined' ? 600 : 500,
        },
      }}
      {...rest}
    >
      {children}
    </Buttons>
  );
};

export default PGEPlusButtons;
