import { convertValidationRules } from '../../../hooks/useFormState';
export type JobQuoteModal = {
  preferedDay?: string;
  preferedTime?: string;
  jobType: string;
  chargerModel: string;
  chargerCost: string;
  permitCost: string;
  travelCost?: string;
  feesCost: string;
  miscellaneousWorkCost: string;
  totalInstallationMaterialCost: number;
  additionalComment: string;
  additionalNotesToCustomer?: string;
  modalIdNumber?: string;
  meterBase: string;
  weatherHead: string;
  electricBreaker: string;
  labor: string;
  miscMaterial: string;
  material: string;
  panelLocation?: string;
  distance?: string;
  outsidePanelLocation?: string;
  cardinalDirections?: string;
  isDistance?: string;
  isReplace?: string;
  amperage?: string;
  customerInvoicePhoto?: string;
  other?: string;
  chargerPhoto?: string;
  installedChargerPhoto?: string;
  customerInvoice?: string;
  rebateEligibility?: string;
  rebateType?: string;
  panelUpgrade?: string;
  isCreateQuote?: boolean;
  adminFee?: number;
};

type AdditionalContext = {
  content: Map<string, string>;
};

export default convertValidationRules<JobQuoteModal, AdditionalContext>(
  context => {
    const { values, content } = context;

    return {
      jobType: () =>
        values.jobType === ''
          ? `${content.get('ENTER_THE')} ${content.get('JOB_TYPE')}`
          : null,
      permitCost: () =>
        !values.permitCost
          ? `${content.get('ENTER_THE')} ${content.get('REUSABLE_PERMITS')}`
          : null,
      // TODO: below three validation is for optional field
      // travelCost: () =>
      //   !values.travelCost
      //     ? `${content.get('ENTER_THE')} ${content.get('REUSABLE_TRAVEL_TIME')}`
      //     : null,
      // feesCost: () =>
      //   !values.feesCost
      //     ? `${content.get('ENTER_THE')} ${content.get('REUSABLE_FEES')}`
      //     : null,
      // miscellaneousWorkCost: () =>
      //   !values.miscellaneousWorkCost
      //     ? `${content.get('ENTER_THE')} ${content.get('REUSABLE_MISC_WORK')}`
      //     : null,
      additionalComment: () =>
        values.miscellaneousWorkCost &&
        Number(values.miscellaneousWorkCost) > 0 &&
        !values.additionalComment
          ? `${content.get('ENTER_THE')} ${content.get('ADDITIONAL_COMMENTS')}`
          : null,
      modalIdNumber: () =>
        values.chargerPhoto && !values.modalIdNumber
          ? `${content.get('ENTER_THE_MODEL_NUMBER')}`
          : null,
      chargerPhoto: () =>
        !values.isCreateQuote && !values.chargerPhoto
          ? `${content.get('UPLOAD_CHARGER_PHOTO')}`
          : null,
      installedChargerPhoto: () =>
        !values.isCreateQuote && !values.installedChargerPhoto
          ? `${content.get('UPLOAD_INSTALLED_CHARGER_PHOTO')}`
          : null,
      customerInvoicePhoto: () =>
        !values.isCreateQuote && !values.customerInvoicePhoto
          ? `${content.get('UPLOAD_INVOICE_PHOTO')}`
          : null,
      other: () =>
        values.panelLocation === 'OTHER' && !values.other
          ? `${content.get('ENTER_THE')} ${content.get(
              'ADDITIONAL_COMMENTS',
            )} ${content.get('FOR_PANEL_LOCATION')}`
          : null,
    };
  },
);
