import React from 'react';

import { Box, Card, CardContent } from '@material-ui/core';
import colors from '../../../../themes/main-colors';
import { StepIndicator } from './StepIndicator';
import { STEP_STATUS } from '../welcomeBack.types';

interface StepProps {
  step: number;
  status: STEP_STATUS;
  children: React.ReactNode;
  isClosed?: boolean;
}

export function WelcomeBackStep({
  step,
  status,
  children,
  isClosed,
}: StepProps) {
  return (
    <Card
      raised={false}
      component="div"
      css={{
        padding: '20px',
        borderRadius: 0,
        backgroundColor:
          status === STEP_STATUS.COMPLETED || status === STEP_STATUS.IN_PROGRESS
            ? colors.white
            : colors.grey2,
        boxShadow: 'none',
        borderBottom: '1px solid #D8D8D8',
        '& .incomplete-step .title': {
          fontWeight: 500,
        },
      }}
    >
      <CardContent
        css={{
          display: 'flex',
          padding: 0,
          paddingBottom: '0 !important',
        }}
        className={status !== STEP_STATUS.COMPLETED ? 'incomplete-step' : ''}
      >
        <StepIndicator step={step} status={status} isClosed={isClosed} />
        <Box css={{ marginLeft: '20px', width: '100%' }}>{children}</Box>
      </CardContent>
    </Card>
  );
}
