import React, { Fragment } from 'react';
import mainColors from '../../themes/main-colors';
import { useIsMobile } from '../../util/style-utils';
import { Box, Link } from '@material-ui/core';
import theme from '../../themes/theme';

type LinkProps = {
  content: string | undefined;
  onClick?: any;
};

const PGEPlusLink = ({ content, onClick }: LinkProps) => {
  const isMobile = useIsMobile();
  return (
    <Fragment>
      <Box justifyContent={'center'} margin={isMobile ? '1rem 0rem' : 0}>
        <Link
          component="h2"
          variant="h2"
          onClick={onClick}
          css={{
            fontFamily: 'Untitled-Sans',
            display: 'flex',
            alignItems: isMobile ? 'normal' : 'center',
            color: mainColors.sparkBlue,
            letterSpacing: 0,
            fontWeight: 'bold',
            justifyContent: 'center',
            fontSize: '1.25rem',
            lineHeight: '25px',
            cursor: 'pointer',
            [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
              fontSize: '1rem',
            },
          }}
        >
          {content}
        </Link>
      </Box>
    </Fragment>
  );
};

export default PGEPlusLink;
