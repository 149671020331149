/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, { Fragment, ReactNode } from 'react';
import { PageTextContentType } from '../../../hooks/useContentMgmt';
import { ApplicationEv, JobStatus } from '../../../__generated__/pgeplus-types';
import { Box, Button, Hidden, Link, Typography } from '@material-ui/core';
import colors from '../../../themes/main-colors';

import WelcomeBackHeader from './common/Header';
import { useIsMobile } from '../../../util/style-utils';
import { STEP_STATUS } from './welcomeBack.types';
import { WelcomeBackStep } from './common/Step';

import ApplicationInprogress from './common/steps/ApplicationInprogress';
import InstallerInformation from './common/steps/InstallerInformation';
import QuoteInformation from './common/steps/QuoteInformation';
import { WelcomeBackPayments } from './common/WelcomeBackPayments';
import { WelcomeBackStepHeader } from './common/StepHeader';
import { formatDate } from '../../../util/format';
import theme from '../../../themes/theme';
import { WelcomeBackAccordion } from './common/Accordion';
type Props = {
  application: ApplicationEv;
  content: PageTextContentType;
  activeStep: number;
  handleRestartApplication: () => void;
  handleClickGoToQuote: () => void;
  handleResumeApplication: () => Promise<void>;
  handleViewApplication: () => void;
  handleViewAcceptedQuote?: () => void;
  isClosed?: boolean;
};

const WelcomeBackEVApplication = ({
  application,
  content,
  activeStep,
  handleRestartApplication,
  handleResumeApplication,
  handleClickGoToQuote,
  handleViewApplication,
  handleViewAcceptedQuote,
  isClosed,
}: Props) => {
  const isMobile = useIsMobile();

  const getStepStatus = (step: number) => {
    if (activeStep === step) {
      return STEP_STATUS.IN_PROGRESS;
    }
    if (step > activeStep) {
      return STEP_STATUS.PENDING;
    }

    return STEP_STATUS.COMPLETED;
  };

  const isInstallerToCollectPayment =
    application?.job?.quote?.isInstallerToCollectPayment;

  const AppMetadata = (
    <Box
      css={{
        display: 'flex',
        padding: '12px',
        justifyContent: 'space-between',
        backgroundColor: colors.navBarBackground,
      }}
    >
      <Box
        gridGap={2}
        display={'flex'}
        flexDirection={isMobile ? 'column' : 'row'}
      >
        <Typography component="span">
          {content.get('APPLICATION_NO')}
        </Typography>{' '}
        <Typography component="span" style={{ fontWeight: 700 }}>
          {application?.applicationNo}
        </Typography>
      </Box>
      <Box
        display={'flex'}
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems={'flex-end'}
      >
        <Typography component="span">
          {content.get('APPLICATION_START_DATE')}:
        </Typography>{' '}
        <Typography component="span" style={{ fontWeight: 700 }}>
          {formatDate(new Date(application?.createdAt))}
        </Typography>
      </Box>
    </Box>
  );

  const AppDetails = (
    <React.Fragment>
      <WelcomeBackStep step={1} status={getStepStatus(1)} isClosed={isClosed}>
        <ApplicationInprogress
          status={getStepStatus(1)}
          content={content}
          handleRestartApplication={handleRestartApplication}
          handleResumeApplication={handleResumeApplication}
          handleViewApplication={handleViewApplication}
          appType={application.applicationType}
        />
      </WelcomeBackStep>

      <WelcomeBackStep step={2} status={getStepStatus(2)} isClosed={isClosed}>
        <InstallerInformation
          status={getStepStatus(2)}
          isQuoteCreated={Boolean(application?.job?.quote)}
          content={content}
          jobStatus={application?.job?.status}
          installerInfo={application?.job?.serviceProviderInfo}
          applicationId={application?.id}
          acceptedDate={application?.job?.acceptedAt}
        />
      </WelcomeBackStep>

      <WelcomeBackStep step={3} status={getStepStatus(3)} isClosed={isClosed}>
        <QuoteInformation
          status={getStepStatus(3)}
          content={content}
          handleClickGoToQuote={handleClickGoToQuote}
          handleViewAcceptedQuote={handleViewAcceptedQuote}
          isInstallerToCollectPayment={isInstallerToCollectPayment}
          installationDateTime={application?.job?.serviceTime}
        />
      </WelcomeBackStep>
      {!isInstallerToCollectPayment && (
        <WelcomeBackPayments
          getStepStatus={getStepStatus}
          content={content}
          appllication={application}
          isClosed={isClosed}
        />
      )}
      {isInstallerToCollectPayment && (
        <WelcomeBackStep step={4} status={getStepStatus(4)} isClosed={isClosed}>
          <WelcomeBackStepHeader
            title={content.get('INSTALLATION_INCOMING') || ''}
          />
        </WelcomeBackStep>
      )}
    </React.Fragment>
  );

  return (
    <Fragment>
      {isClosed ? (
        <WelcomeBackAccordion
          title={content.get('EV_CHARGING') || ''}
          category={content.get('COMPLETED_JOB') || ''}
          secondaryBar={AppMetadata}
          cardContent={AppDetails}
        />
      ) : (
        <Box css={{ marginBottom: theme.spacing(4) }}>
          <WelcomeBackHeader
            title={content.get('EV_CHARGING') || ''}
            applicationId={application?.id}
            appNumber={
              <Box
                gridGap={2}
                display={'flex'}
                flexDirection={isMobile ? 'column' : 'row'}
              >
                <Typography component="span">
                  {content.get('APPLICATION_NO')}
                </Typography>
                <Hidden smDown>
                  <Typography component="span">&nbsp;</Typography>
                </Hidden>
                <Typography component="span" style={{ fontWeight: 700 }}>
                  {application?.applicationNo}
                </Typography>
              </Box>
            }
            appDate={
              <Box
                display={'flex'}
                flexDirection={isMobile ? 'column' : 'row'}
                alignItems={'flex-end'}
              >
                <Typography component="span">
                  {content.get('APPLICATION_START_DATE')}:
                </Typography>
                <Hidden smDown>
                  <Typography component="span">&nbsp;</Typography>
                </Hidden>
                <Typography component="span" style={{ fontWeight: 700 }}>
                  {formatDate(new Date(application?.createdAt))}
                </Typography>
              </Box>
            }
          />
          {AppDetails}
        </Box>
      )}
    </Fragment>
  );
};

type SubHeadingProps = {
  subHeading: ReactNode;
};

export const WelcomeBackSubHeading = ({ subHeading }: SubHeadingProps) => {
  return (
    <Fragment>
      <Box>
        <Typography
          css={{
            fontFamily: 'Untitled-Sans',
            color: colors.noirBlur,
            letterSpacing: 0,
            fontWeight: 'normal',
            fontSize: '1.1rem',
            marginTop: '10px',
          }}
        >
          {subHeading}
        </Typography>
      </Box>
    </Fragment>
  );
};

export default WelcomeBackEVApplication;
