import {
  ContentfulTranslationField,
  ContentfulTranslationGroup,
} from '../__generated__/gatsby-types';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import template from 'lodash/template';
import { getLanguage } from '../providers/LanguageProvider';

export interface UseTranslationHook {
  t: (key: string, variables?: Variables) => string;
  richT: (
    key: string,
    variables?: Variables,
    linkTarget?: string,
  ) => React.ReactNode;
  inlineRichT: (key: string, variables?: Variables) => React.ReactNode;
}

export interface TranslationsKeyValues {
  [key: string]: string;
}

export interface Variables {
  [key: string]: string | undefined;
}

export interface Translations<Values = TranslationsKeyValues> {
  [locale: string]: Values;
}

export type UnpackContentfulNodes<N> = N extends ReadonlyArray<infer E> ? E : N;

interface ContentfulLocaleLike {
  node_locale?: string;
}

// Utility function to transform Contentful nodes into [locale][key] shape.
export const getTranslationsByLocale = <T>(
  nodes: ReadonlyArray<T & ContentfulLocaleLike>,
) => {
  const translations: Translations<T> = {};
  for (const node of nodes) {
    const [loc] = node['node_locale']?.split('-') || ['undefined'];
    translations[loc] = node as T;
  }
  return translations;
};

// Util for transforming contentful translation groups
export const getTranslationsFromContentfulGroups = (
  nodes: ContentfulTranslationGroup[],
) => {
  const translations: Translations = {};
  for (const node of nodes) {
    const [loc] = node.node_locale?.split('-') || ['undefined'];
    translations[loc] = {};
    for (const field of node.translations || []) {
      translations[loc][field?.slug || 'SLUG_UNDEFINED'] =
        field?.text?.text || '';
    }
  }
  return translations;
};

export const getTranslationsFromFieldsQuery = (
  nodes: ContentfulTranslationField[],
): Translations => {
  const translations: Translations = {};
  for (const node of nodes) {
    const [loc] = node.node_locale?.split('-') || ['undefined'];
    if (!translations[loc]) {
      translations[loc] = {};
    }
    translations[loc][node?.slug || 'SLUG_UNDEFINED'] = node?.text?.text || '';
  }
  return translations;
};

const interpolateTemplate = /{{([\s\S]+?)}}/g;

const replaceVariables = (
  value: string,
  variables: Record<string, string | undefined>,
): string => {
  return template(value, {
    interpolate: interpolateTemplate,
  })(variables);
};

// This is very much re-asssigned/mutated. See below.

// eslint-disable-next-line prefer-const
let translationsCache: Translations = {};

export const useTranslation = (
  newTranslations?: Translations | any,
): UseTranslationHook => {
  if (newTranslations) {
    Object.keys(newTranslations).map((key: string) => {
      translationsCache[key] = {
        ...translationsCache[key],
        ...newTranslations[key],
      };
    });
  }

  const t = (key: string, variables?: Variables) => {
    const language = getLanguage();
    if (
      translationsCache &&
      translationsCache[language] &&
      translationsCache[language][key]
    ) {
      const baseText = translationsCache[language][key];
      return variables ? replaceVariables(baseText, variables) : baseText;
    }
    // finally, if translation isnt found in lookups, warn, return key
    if (process.env.NODE_ENV === 'development' && translationsCache) {
      // this is super noisy  - shouldnt be anymore
      // console.log(`Missing translation key ${key} for locale ${locale}`)
    }
    return key;
  };

  const richT = (key: string, variables?: Variables, linkTarget = '_self') => {
    return React.createElement(
      ReactMarkdown,
      { linkTarget },
      t(key, variables),
    );
  };

  // A quick & dirty implmentation to suppress the default surrounding <p>.
  // It's probably something we can use for the default implentation since we'll always (almost?) be wrapping the output in an element.
  // Currently, surrounding the richT output in a default Typography control causes a 'no <p> as a child of <p>' erorr. This can be
  // gotten around by setting the container attribute of the Typography control to 'span' or 'div', but it's irritating...
  const inlineRichT = (key: string, variables?: Variables) => {
    return React.createElement(
      ReactMarkdown,
      { disallowedTypes: ['paragraph'], unwrapDisallowed: true },
      t(key, variables),
    );
  };
  return { t, richT, inlineRichT };
};
