import React, { useState, useRef, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import PgeButton from '../../pge-button/_PgeButton';
import RichText, { richTextStyles } from '../../rich-text';
import useAttrs from '../../../hooks/useAttrs';
import { CtaCardProps as Props, Omits } from './types.d';
import useStyles from './CtaCard.styles';

function CtaCard({
  heading,
  button,
  buttonLink,
  image,
  mobileImage,
  shortDescription,
  imageBorderColor,
  headingType,
  ...props
}: Props | any): ElementNode {
  // Default props
  let _border: 'DarkBlue' | 'Yellow' = 'DarkBlue';

  switch (imageBorderColor) {
    case 'DarkBlue':
      _border = 'DarkBlue';
      break;
    case 'Yellow':
      _border = 'Yellow';
      break;
    default:
      _border = 'DarkBlue';
  }
  // Vars
  const mobileFile = mobileImage?.file;
  const imageHeight = mobileFile?.details?.image?.height ?? 1200;
  const imageWidth = mobileFile?.details?.image?.width ?? 360;
  const imageRatio = Math.floor((imageHeight / imageWidth) * 100);
  const richText = richTextStyles();
  const { className = '', ...attrs } = useAttrs<Props>(props, Omits);
  const $img = useRef<HTMLDivElement>(null);
  const $btn = useRef<HTMLButtonElement>(null);
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles(imageRatio, _border, button?.buttonType)(props);

  useEffect(() => {
    if (expanded) {
      $img.current?.classList.add('isExpanded');
      $btn.current?.setAttribute('disabled', '');
    }
  }, [expanded]);

  let headingElementType: 'h1' | 'h2' | 'h3' | 'h4' = 'h2';

  switch (headingType) {
    case 'h1':
      headingElementType = 'h1';
      break;
    case 'h2':
      headingElementType = 'h2';
      break;
    case 'h3':
      headingElementType = 'h3';
      break;
    case 'h4':
      headingElementType = 'h4';
      break;
    default:
      headingElementType = 'h2';
  }

  return (
    <aside
      className={`CtaCard bkg-${_border} ${classes.root} ${className}`}
      aria-label={heading || undefined}
      {...attrs}
    >
      {heading && heading.trim() && (
        <Typography variant={headingElementType} className={classes.heading}>
          {heading}
        </Typography>
      )}
      {image?.file?.url && (
        <div className={`${classes.image} ${classes.expand}`} ref={$img}>
          <picture>
            {mobileFile && (
              <>
                <source media="(max-width:767px)" srcSet={mobileFile.url} />
                <source media="(min-width:768px)" srcSet={image.file.url} />
              </>
            )}
            <img src={image.file.url} alt="" height="450" width="1200" />
          </picture>
          <PgeButton
            className={classes.showMore}
            theme="Pill"
            startIcon="Plus"
            onClick={() => setExpanded(true)}
            ref={$btn}
          >
            Show more
          </PgeButton>
        </div>
      )}
      <RichText className={`RichText ${richText.styles}`}>
        {shortDescription}
      </RichText>
      <PgeButton
        className={classes.cta}
        link={buttonLink}
        theme={button?.buttonType || 'Outline'}
      >
        {button?.buttonText}
      </PgeButton>
    </aside>
  );
}

export default CtaCard;
