import { useContext } from 'react';
import { featureFlagsContext } from '../../../providers/FeatureFlagsProvider';

export function useConsultation() {
  const { isPGEPlusConsultationEnabled, loading } = useContext(
    featureFlagsContext,
  );
  const isBrowser = !(typeof window === 'undefined');
  return {
    isPGEPlusConsultationEnabled,
    isBrowser,
    featureFlagLoading: loading,
  };
}
