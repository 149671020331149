/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React from 'react';
import {
  Paper,
  Typography,
  Grid,
  Card,
  CardContent,
  Divider,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import colors from '../../../../themes/main-colors';
import {
  PaymentCardType,
  PaymentConfirmation,
} from '../../../../__generated__/pgeplus-types';
import { useTranslation } from '../../../../hooks/useTranslation';
import { OneTimePayConfigData } from '../../../payment-form/oneTimePayment.types';
import {
  toCurrencyString,
  toDateStringFullMonthName,
} from '../../../../util/format';
import { parse } from 'date-fns';
import theme from '../../../../themes/theme';
import PaymentProfileIcon from '../../../paymentus/payment-profile-icon/PaymentProfileIcon';
import {
  PaymentCategory,
  PaymentProfile,
} from '../../../../__generated__/pge-types';

const useStyles = makeStyles(() =>
  createStyles({
    paymentOption: {
      color: colors.noirBlur,
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '28px',
      lineHeight: '20px',
      paddingTop: 20,
      paddingBottom: 20,
      fontFamily: 'Untitled-Sans,Roboto',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
      },
    },
    optionDetailsCard: {
      position: 'relative',
      borderRadius: '5px',
      border: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: colors.white,
      boxShadow: '0px 1px 1px #00000029',
      padding: '0 32px',

      '& .MuiFormControlLabel-root': {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        '& .MuiIconButton-label': {
          marginTop: 20,
        },
      },
    },
    errorIcon: {
      color: colors.roseRed,
      fontSize: '4rem',
      paddingBottom: 10,
      [theme.breakpoints.down('sm')]: {
        fontSize: '3rem',
      },
    },
    noWrap: {
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('sm')]: {
        whiteSpace: 'normal',
      },
    },
  }),
);

type Props = {
  paymentConfirmation: PaymentConfirmation[];
  paymentInfo: OneTimePayConfigData;
  paymentMethodCapLimit: number;
  getMethodDisplayLabel: (paymentConfirmation: PaymentConfirmation) => string;
  getMaskedAccountDisplayLabel: (
    paymentConfirmation: PaymentConfirmation,
    paymentProfile: PaymentProfile,
  ) => React.ReactNode;
};

const PaymentFailed: React.FC<Props> = ({
  paymentConfirmation,
  paymentInfo,
  paymentMethodCapLimit,
  getMethodDisplayLabel,
  getMaskedAccountDisplayLabel,
}) => {
  const classes = useStyles();
  const { t, inlineRichT } = useTranslation();

  const selectedProfileList =
    paymentInfo.paymentSelector.selectedPaymentProfileList;

  return (
    <Paper
      component={'main'}
      style={{
        justifyContent: 'center',
        marginTop: '1.5em',
        padding: '2rem',
        backgroundColor: colors.riverBlue,
      }}
    >
      <Grid item xs={12} md={12} css={{ backgroundColor: colors.white }}>
        <Card
          raised={false}
          component="div"
          className={classes.optionDetailsCard}
        >
          <CardContent
            css={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Grid item>
              <Typography>
                <ErrorIcon className={classes.errorIcon} />
              </Typography>
            </Grid>
            <Typography className={classes.paymentOption}>
              {inlineRichT('PAYMENT_FAILURE_MESSAGE')}
            </Typography>
            <Typography variant="body1" css={{ fontSize: 20 }}>
              {inlineRichT('ACCOUNT_FAILED_FOR_MULTIPLE_PAYMENT', {
                ACCOUNT_PAYMENT: `$${toCurrencyString(
                  parseFloat(paymentInfo.paymentAmount),
                  false,
                )}`,
                PAYMENT_DATE: paymentInfo.paymentDate
                  ? toDateStringFullMonthName(
                      parse(paymentInfo.paymentDate, 'MM/dd/yyyy', new Date()),
                    )
                  : '',
              })}
            </Typography>

            <Divider css={{ margin: theme.spacing(2, 0), width: '100%' }} />

            {paymentConfirmation.map((paymentProfile, index) => (
              <Grid
                key={index}
                css={{
                  marginTop: theme.spacing(2),
                  marginBottom: theme.spacing(2),
                }}
              >
                <Grid
                  item
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant={'body1'}
                    className={`${classes.noWrap}`}
                    component="span"
                  >
                    {selectedProfileList.length === paymentMethodCapLimit &&
                      inlineRichT('ONETIMEPAY_PAYMENT_AMOUNT_USING', {
                        PAYMENT_AMOUNT: paymentProfile.paymentAmount.toFixed(2),
                      })}
                    {selectedProfileList.length !== paymentMethodCapLimit &&
                      t('ONETIMEPAY_PAID_WITH')}{' '}
                    {getMethodDisplayLabel(paymentProfile)}
                  </Typography>

                  <Typography
                    variant={'body1'}
                    className={`${classes.noWrap}`}
                    component="span"
                    css={{
                      paddingLeft: '4px',
                      '& img': {
                        maxHeight: '1em !important',
                      },
                    }}
                  >
                    <PaymentProfileIcon
                      type={
                        paymentProfile.cardType !== PaymentCardType.Unknown
                          ? paymentProfile.cardType === 'GOOGLE_PAY'
                            ? PaymentCategory.Gp
                            : paymentProfile.cardType === 'APPLE_PAY'
                            ? PaymentCategory.Ap
                            : paymentProfile.cardType
                          : paymentProfile.paymentMethod
                      }
                    ></PaymentProfileIcon>
                    <Typography
                      variant={'h2'}
                      component="span"
                      style={{
                        verticalAlign: 'middle',
                        fontSize: '1.2rem',
                      }}
                    >
                      {getMaskedAccountDisplayLabel(
                        paymentProfile,
                        paymentInfo.paymentSelector.selectedPaymentProfileList[
                          index
                        ].profile,
                      )}
                    </Typography>
                  </Typography>
                </Grid>
                {paymentProfile?.errorCode && (
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography variant="subtitle1" css={{ fontSize: 16 }}>
                      {t(paymentProfile?.errorCode)}
                    </Typography>
                  </Grid>
                )}
                {paymentConfirmation.length - 1 < index ? (
                  <Divider css={{ paddingTop: '1em', paddingBottom: '1em' }} />
                ) : null}
              </Grid>
            ))}
          </CardContent>
        </Card>
      </Grid>
    </Paper>
  );
};

export default PaymentFailed;
