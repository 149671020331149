import { convertValidationRules } from '../../../hooks/useFormState';
import { BillingInfo } from './pop.types';

type AdditionalContext = {
  content: Map<string, string>;
};

export default convertValidationRules<BillingInfo, AdditionalContext>(
  context => {
    const { values, content } = context;

    return {};
  },
);
