import { Box } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import { Close } from '@material-ui/icons';
import { navigate } from 'gatsby';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import ROUTES from '../../../routes';
import { isMobile } from '../../../util/style-utils';
import { TimeOfDayInfo } from '../../../__generated__/pge-types';
import PGEButton from '../../buttons';
import TextLink from '../../text-link';
import PaperBox from '../../utility/paper-box';
import useStyles from '../styles';
import TimeOfDayNoEnoughData from './TimeOfDayNoEnoughData';
import TimeOfDaySavings from './TimeOfDaySavings';
import TimeOfDaySideBySideComparision from './TimeOfDaySideBySideComparision';
import {
  LanguageContext,
  SupportedLanguages,
} from '../../../providers/LanguageProvider';

interface Props {
  path?: String;
  handleEnrollmentSubmit: () => void;
  timeOfDayInfo?: TimeOfDayInfo | null;
}
export const TimeOfDayEnroll: FC<Props> = props => {
  const { t, richT } = useTranslation();
  const classes = useStyles();
  const [showTnC, setShowTnC] = useState(false);
  const [isCheckedTnC, setIsCheckedTnC] = useState(false);
  const { handleEnrollmentSubmit, timeOfDayInfo } = props;
  const [savingsHeader, setSavingsHeader] = useState<string>(
    t('YOUR_ESTIMATED_SAVINGS'),
  );
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    if ((timeOfDayInfo?.timeOfDaySummaryInfo?.deltaBasicToTodAmount || 0) < 0) {
      setSavingsHeader(t('YOUR_ESTIMATED_SAVINGS_ON_BASIC_SERVICE'));
    } else {
      setSavingsHeader(t('YOUR_ESTIMATED_SAVINGS'));
    }
  }, [timeOfDayInfo]);

  const onShowTnC = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setShowTnC(true);
  };

  return (
    <>
      <Grid
        className={classes.root}
        container
        spacing={3}
        style={{ marginTop: '1em' }}
      >
        <Grid item>
          <PaperBox>
            <Grid item xs={12} style={{ marginBottom: '1.5em' }}>
              <Typography className={classes.todHeader} variant={'h4'}>
                {t('TAKE_CONTROL_WITH_TIME_OF_DAY_PRICING')}
              </Typography>
            </Grid>
            {/*
            ***
            CUP-13705  As requested by Sreedhar, this part will be hidden temporarily in PROD
            ***
            >>>>>>  code commented start here
            <Grid item xs={12} style={{ marginBottom: '1.5em' }}>
              <Typography className={classes.todSubHeader} variant={'h6'}>
                {t('CHECK_OUT_YOUR_PERSONALIZED_COST')}
              </Typography>
            </Grid>
            <<<<<<<  code commented ends here
            */}
            <Grid item xs={12} style={{ marginBottom: '1em' }}>
              <Typography className={classes.colorTextPrimary} variant={'h6'}>
                {t('REGARDLESS_OF_HOW_MUCH_YOU_CAN_SAVE')}
              </Typography>
              <br></br>
              <Typography className={classes.colorTextPrimary} variant={'h6'}>
                {t('REGARDLESS_OF_HOW_MUCH_YOU_CAN_SAVE2')}
              </Typography>
            </Grid>

            {/*
            ***
            CUP-13705  As requested by Sreedhar, this part will be hidden temporarily in PROD
            ***

            >>>>>>  code commented start here
            {timeOfDayInfo?.timeOfDaySummaryInfo &&
            timeOfDayInfo?.timeOfDaySummaryInfo?.numberOfCalculatedBills &&
            timeOfDayInfo?.timeOfDaySummaryInfo?.numberOfCalculatedBills >=
              10 ? (
              <Grid item>
                <TimeOfDaySideBySideComparision
                  timeOfDayInfo={timeOfDayInfo}
                  sideBySideHeader={richT(
                    'TOD_ESTIMATES_ARE_BASED_ON_YOU_ENERGY_USAGE',
                    {
                      CALCULATED_BILLS: timeOfDayInfo.timeOfDaySummaryInfo.numberOfCalculatedBills.toString(),
                    },
                  )}
                  footer={t('PRICES_SHOWN_ARE_YOUR_ESTIMATED_ANNUAL_COST')}
                />
                <TimeOfDaySavings
                  timeOfDayInfo={timeOfDayInfo}
                  savingHeader={savingsHeader}
                  footer={t('PRICES_SHOWN_ARE_YOUR_ESTIMATED_ANNUAL_COST')}
                />
              </Grid>
            ) : (
              <TimeOfDayNoEnoughData
                notEnoughDataHeader={t('YOUR_PERSONALIZED_SAVING_COMPARISON')}
                notEnoughDataSubHeader={t('UNFORTUNATELY_WE_ARE_NOT_ABLE')}
                firstDescription={t('FOR_EXAMPLE_IF_YOU_DONT_HAVE_FULL_YEAR')}
                secondDescription={t(
                  'HOWEVER_IF_YOU_CAN_SHIFT_USING_HOT_WATER',
                )}
                csaInfo={richT('PLEASE_CALL_US_AT_IF_YOU_WOULD_LIKE', {
                  CUSTOMER_SERVICE_NUMBER: t('CALL_FOR_ASSISTANCE_NUMBER_TOD'),
                })}
              />
            )}
            <<<<<<<  code commented ends here

            */}
            <Grid item xs={12} style={{ marginBottom: '1.5em' }}>
              <Typography className={classes.todHeader} variant={'h4'}>
                {t('TIME_OF_DAY_AND_BASIC_SERVICE_PLAN')}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: '1.5em' }}>
              <Typography className={classes.colorTextPrimary} variant={'h6'}>
                {t('UNLIKE_OUR_BASIC_SERVICE_PRICING')}
              </Typography>
              <PaperBox>
                {language === SupportedLanguages.English ? (
                  <img
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                    }}
                    src={require('../../../images/todCostofService.png')}
                  />
                ) : (
                  <img
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                    }}
                    src={require('../../../images/todCostofServiceSpanish.png')}
                  />
                )}
                <Typography className={classes.basicCostInfo}>
                  {t('WEEKENDS_AND_HOLIDAYS_ARE_OFF_PEAK')}
                </Typography>
                <Typography className={classes.basicCost}>
                  {' '}
                  {t('PRICES_SHOWN_REFLECT_ENERGY')}
                </Typography>
              </PaperBox>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: '1.5em' }}>
              <Typography className={classes.todHeader} variant={'h4'}>
                {t('CHOOSE_THE_PRICING_PLAN_THATS_BEST_FOR_YOU')}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: '1.5em' }}>
              <div style={{ display: 'flex' }}>
                <Typography className={classes.colorTextPrimary} variant={'h6'}>
                  {t('VIEW_YOUR_PRICING_PLAN1')}
                </Typography>
                &nbsp;
                <a href={ROUTES.MY_PRICING_PLAN}>
                  <Typography
                    className={classes.colorTextPrimary}
                    variant={'h6'}
                  >
                    {t('PRICING_PLAN_COMPARISION_PAGE')}
                  </Typography>
                </a>
                &nbsp;
                <Typography className={classes.colorTextPrimary} variant={'h6'}>
                  {t('VIEW_YOUR_PRICING_PLAN2')}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: '1.5em' }}>
              <Typography className={classes.todHeader} variant={'h4'}>
                {t('FIRST_YEAR_GUARANTEE')}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: '1.5em' }}>
              <Typography className={classes.colorTextPrimary} variant={'h6'}>
                {t('WE_HAVE_GOT_YOU_COVERED')}
              </Typography>
            </Grid>

            <Grid item xs={12} style={{ marginTop: '1.5em' }}>
              <Typography>
                <strong>{t('READY_TO_ENROLL_IN_TIME_OF_DAY')} </strong>
                {t('JUST_AGREE_TO_THE_TERMS_AND_CONDITIONS')}
              </Typography>
            </Grid>
            <Grid container direction="row">
              <FormControlLabel
                control={
                  <Checkbox
                    name={'termsandcondition'}
                    color={'primary'}
                    onChange={e => setIsCheckedTnC(e.target.checked)}
                  />
                }
                label={
                  <Typography variant={'h6'} component={'div'}>
                    {t('TERM_AND_CONDITIONS_TOD_BODY')}
                    {
                      <TextLink
                        onClick={onShowTnC}
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {t('TERM_AND_CONDITIONS_TOD_BODY_LINK')}
                      </TextLink>
                    }
                  </Typography>
                }
              />
            </Grid>
            <Grid
              container
              direction={isMobile() ? 'column-reverse' : 'row'}
              justify={'flex-end'}
              className={classes.actionArea}
              spacing={1}
            >
              <Grid item md="auto">
                <Box className={classes.cancelLink}>
                  <TextLink
                    style={{ textDecoration: 'none' }}
                    onClick={(e: any) => {
                      e.preventDefault();
                      return navigate(ROUTES.ACCOUNT);
                    }}
                  >
                    {t('CANCEL')}
                  </TextLink>
                </Box>
              </Grid>
              <Grid item md="auto" className={classes.enrollButton}>
                <Box>
                  <PGEButton
                    onClick={handleEnrollmentSubmit}
                    variant="contained"
                    data-testid="change-password-button"
                    disabled={!isCheckedTnC}
                    size="large"
                  >
                    {t('ENROLL_TODAY')}
                  </PGEButton>
                </Box>
              </Grid>
            </Grid>
          </PaperBox>
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={showTnC}
            onClose={() => setShowTnC(false)}
          >
            <div className={classes.callModal}>
              <div className={'closeButton'}>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  onClick={() => setShowTnC(false)}
                >
                  <Close />
                </IconButton>
              </div>
              <Box
                mt={1}
                display={'flex'}
                flexDirection={'row'}
                alignItems={'baseline'}
              >
                <Typography variant={'h4'} style={{ marginRight: '.75rem' }}>
                  {richT('TOD_TERMANDCONDITION_90_DAYS_CONTENT')}
                </Typography>
              </Box>
            </div>
          </Modal>
        </Grid>
      </Grid>
    </>
  );
};

export default TimeOfDayEnroll;
