import React, { Fragment, useState } from 'react';
import { RouteComponentProps, useLocation } from '@reach/router';

import {
  Box,
  Paper,
  Typography,
  useTheme,
  Grid,
  Card,
  CardContent,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import useContentMgmt from '../../../hooks/useContentMgmt';
import microcopyGroupIds from '../../pge-plus-common/microcopyGroupIds';
import { navigate } from 'gatsby';
import routes from '../../../routes';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import colors from '../../../themes/main-colors';
import PGEButton from '../../buttons';
import { SaveAndComeBackLink } from '../../pge-plus-common/SaveAndComeBackLink';
import PopoverToolTip from '../../pge-plus-common/PopoverToolTip';
import usePayOutOfPocket from '../../../hooks/pge-plus/usePayOutOfPocket';
import PaymentMethodTypeIconList from '../../payment-method-type/PaymentMethodTypeIconList';
import Modal from '../../pge-plus-common/Modal';
import { ProceedOrCancelButton } from '../../pge-plus-common/StepperForm';
import { Application } from '../../../__generated__/pgeplus-types';
import { getPOPUrl } from './utils/navigation.util';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    details: {
      fontSize: '20px',
      margin: '1.5rem 0px',
      [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
        fontSize: '16px',
      },
    },
    accept: {
      color: colors.white,
      minWidth: '130px',
      border: '2px solid',
      borderColor: colors.sparkBlue,
      background: colors.sparkBlue,
      borderRadius: '30px',
      fontSize: '18px',
      fontWeight: 600,
      '&:hover': {
        backgroundColor: colors.buttonHoverBackground,
        borderColor: colors.buttonHoverBackground,
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    paymentOption: {
      color: colors.noirBlur,
      textAlign: 'left',
      fontWeight: 'bold',
      height: '100%',
      fontSize: '24px',
      lineHeight: '20px',
      fontFamily: 'Untitled-Sans,Roboto',
      paddingLeft: 8,
      [theme.breakpoints.down('md')]: {
        fontSize: '20px',
      },
    },
    paymentOptionText: {
      color: colors.noirBlur,
      textAlign: 'left',
      height: '100%',
      fontSize: '20px',
      lineHeight: '20px',
      paddingTop: 10,
      paddingBottom: 5,
      fontFamily: 'Untitled-Sans,Roboto',
      paddingLeft: 8,
      [theme.breakpoints.down('md')]: {
        fontSize: '16px',
      },
    },
    optionDetailsCard: {
      cursor: 'pointer',
      padding: '1.2rem 0rem 1rem 2rem',
      position: 'relative',
      height: '100%',
      borderRadius: '5px',
      border: 'none',
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'flex-start',
      background: colors.cloudGrey,
      boxShadow: '0px 1px 1px #00000029',
      '& .MuiFormControlLabel-root': {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
      },
      [theme.breakpoints.down('md')]: {
        padding: '1rem',
      },
    },
    learnMore: {
      fontFamily: 'Untitled-Sans',
      color: colors.noirBlur,
      letterSpacing: 0,
      fontSize: '20px',
    },
    cardContent: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      paddingTop: '.5rem',
      [theme.breakpoints.down('md')]: {
        paddingTop: 10,
      },
    },
    paper: {
      justifyContent: 'center',
      marginTop: '1.5em',
      padding: '2rem 1rem',
    },
  }),
);

type Props = RouteComponentProps & {
  application: Application;
  refetchApplication: () => void;
};

type locationState = { forceFullPayment?: boolean };
const PaymentPreference: React.FC<Props> = ({ application }) => {
  const { state } = useLocation();

  const forceFullPayment = (state as locationState)?.forceFullPayment === true;

  const [showModal, setShowModal] = useState(forceFullPayment);

  const handleSaveAndExit = () => {
    void navigate(
      routes.PGE_PLUS_PAY_OUT_OF_POCKET_CONFIRM_BILLING_ADDRESS.replace(
        '',
        application.id,
      ),
    );
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const classes = useStyles();
  const theme = useTheme();

  const { content } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_POP_PAYMENT_PREFERENCE,
  );
  const selectedBox = {
    backgroundColor: colors.riverBlue,
  };

  const onSubmit = (data: any) => {
    void navigate(
      getPOPUrl(
        routes.PGE_PLUS_PAY_OUT_OF_POCKET_CONFIRM_BILLING_ADDRESS,
        application.id,
      ),
    );
  };

  const { form } = usePayOutOfPocket(application.id);

  return (
    <Fragment>
      <form
        id={'POP'}
        noValidate
        onSubmit={form.submit(values => onSubmit(values))}
      >
        <Box>
          <Grid
            container
            spacing={2}
            justify="center"
            alignItems="center"
            direction="column"
            css={{
              [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
                display: 'block',
              },
            }}
          >
            <Typography css={{ margin: '1rem 0rem' }} variant="h1">
              {content.get('PAYMENT_PREFERENCE_TITLE')}
            </Typography>
          </Grid>
          <Typography className={classes.details}>
            {content.get('PAYMENT_PREFERENCE_TEXT')}
          </Typography>
        </Box>
        <Paper component={'main'} className={classes.paper}>
          <Grid item xs={12} md={12}>
            <RadioGroup
              name={'paymentPreference'}
              value={form.props('paymentPreference').value}
              onChange={form.props('paymentPreference').onChange}
              onBlur={form.props('paymentPreference').onBlur}
            >
              <Card
                raised={false}
                // onClick={handleOnClick}
                component="div"
                className={classes.optionDetailsCard}
                css={{
                  marginBottom: '2rem',
                  ...(form.values.paymentPreference ===
                  `${content.get('PAY_OUT_OF_POCKET')}`
                    ? selectedBox
                    : {}),
                }}
              >
                <FormControlLabel
                  value={content.get('PAY_OUT_OF_POCKET')}
                  control={<Radio color={'primary'} />}
                  label={
                    <CardContent className={classes.cardContent}>
                      <Typography
                        className={classes.paymentOption}
                        css={{ paddingLeft: 8 }}
                      >
                        {content.get('PAY_OUT_OF_POCKET')}
                      </Typography>
                      <Typography
                        className={classes.paymentOptionText}
                        css={{ paddingLeft: 8 }}
                      >
                        {content.get('PAY_OUT_OF_POCKET_INFO')}
                      </Typography>
                      <Box mb={2}>
                        <PaymentMethodTypeIconList />
                      </Box>
                      <Box display={'flex'}>
                        <Typography
                          className={classes.learnMore}
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        >
                          <PopoverToolTip
                            popoverContent={content.get(
                              'PAY_OUT_OF_POCKET_TOOLTIP',
                            )}
                          />
                        </Typography>
                      </Box>
                    </CardContent>
                  }
                />
              </Card>
              <Card
                raised={false}
                // onClick={handleOnClick}
                component="div"
                className={classes.optionDetailsCard}
                css={{
                  ...(form.values.paymentPreference ===
                  'Finance with a Local Credit Union'
                    ? selectedBox
                    : {}),
                }}
              >
                <FormControlLabel
                  value={content.get('FINANCE_WITH_CREDIT_UNION')}
                  control={<Radio color={'primary'} />}
                  label={
                    <CardContent className={classes.cardContent}>
                      <Typography className={classes.paymentOption}>
                        {content.get('FINANCE_WITH_CREDIT_UNION')}
                      </Typography>
                      <Typography className={classes.paymentOptionText}>
                        {content.get('FINANCE_WITH_CREDIT_UNION_INFO')}
                      </Typography>
                      <Box display={'flex'}>
                        <Typography
                          className={classes.learnMore}
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        >
                          <PopoverToolTip
                            popoverContent={content.get(
                              'FINANCE_WITH_CREDIT_UNION_TOOLTIP',
                            )}
                          />
                        </Typography>
                      </Box>
                    </CardContent>
                  }
                />
              </Card>
            </RadioGroup>
          </Grid>
        </Paper>

        <Box mt={6}>
          <ProceedOrCancelButton
            back={false}
            isCancel={true}
            disable={!form.values.paymentPreference ? true : false}
          />
        </Box>
        <Box mt={6}>
          <SaveAndComeBackLink
            content={content}
            onSaveAndExit={handleSaveAndExit}
          />
        </Box>
      </form>
      <Fragment>
        <Modal
          open={showModal}
          onClose={() => setShowModal(false)}
          title={content.get('ACTIVE_BANKRUPTCY_MODAL_TITILE')}
          showCloseIcon={true}
          modalStyles={{ outline: 'none', maxWidth: 560 }}
        >
          <Grid
            css={{
              display: 'flex',
              alignItems: 'flex-end',
              flexDirection: 'column',
            }}
          >
            <Typography
              variant="body1"
              css={{ padding: '1.5rem 0rem', fontSize: 20 }}
            >
              {content.get('ACTIVE_BANKRUPTCY_MODAL_CONTENT')}
            </Typography>
            <Box>
              <PGEButton
                fullWidth
                type={'button'}
                variant={'outlined'}
                color={'secondary'}
                className={classes.accept}
                onClick={() => setShowModal(false)}
              >
                {content.get('CONTINUE')}
              </PGEButton>
            </Box>
          </Grid>
        </Modal>
      </Fragment>
    </Fragment>
  );
};

export default PaymentPreference;
