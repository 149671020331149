import { convertValidationRules } from '../../../hooks/useFormState';
import { validateEmail, validatePhone } from '../../../util/form-validation';
import { UpdateBillingInfo } from './pop.types';

type AdditionalContext = {
  content: Map<string, string>;
};

export default convertValidationRules<UpdateBillingInfo, AdditionalContext>(
  context => {
    const { values, content } = context;

    return {
      phoneNumber: validatePhone,
      //emailAddress: () => validateEmail(),
    };
  },
);
