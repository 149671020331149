import { convertValidationRules } from '../../../hooks/useFormState';
import {
  validateEmail,
  validateExistsAndAlphabetCharacters,
  validatePhoneNumber,
} from '../../../util/form-validation';
import { ConsultationFormModel, ConsultationLocationState } from './types';

type AdditionalContext = {
  content: Map<string, string>;
};

export const createSelectAddressValidateFunction = () =>
  convertValidationRules<ConsultationLocationState, AdditionalContext>(
    context => {
      const { values, content } = context;
      return {
        inputAddress: () =>
          values?.inputAddress?.trim().length
            ? null
            : content.get('FIELD_IS_REQUIRED'),
      };
    },
  );

export const createConsultationDetailsValidateFunction = () =>
  convertValidationRules<
    ConsultationFormModel,
    { content: Map<string, string> }
  >(context => {
    const { values, content } = context;
    const isMainContact =
      values.isMainContact === undefined || values.isMainContact === false;

    return {
      firstName: isMainContact
        ? validateExistsAndAlphabetCharacters.bind(
            null,
            content.get('FIRST_NAME') || '',
          )
        : null,
      lastName: isMainContact
        ? validateExistsAndAlphabetCharacters.bind(
            null,
            content.get('LAST_NAME') || '',
          )
        : null,
      emailAddress: isMainContact ? validateEmail : null,
      phoneNumber: isMainContact ? validatePhoneNumber : null,
    };
  });
