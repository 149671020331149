import gql from 'graphql-tag';
import { applicationFragment } from './fragments';

const accountInfo = gql`
  fragment AccountInfoEVCharger on AccountDetail {
    isDefault
    accountNumber
    mainCustomerName
    accountType
    encryptedPersonId
    premiseInfo {
      encryptedPremiseId
      addressLine1
      addressLine2
      city
      state
      postal
      saDetails {
        saStatus
      }
    }
  }
`;

export const getAccounts = gql`
  query getAccountDetailListEVCharger($params: AccountDetailListParams!) {
    getAccountDetailList(params: $params) {
      totalCount
      accounts {
        ...AccountInfoEVCharger
      }
    }
  }
  ${accountInfo}
`;

export const getAccountDetails = gql`
  query getAccountDetails($params: AccountDetailParams!) {
    getAccountDetails(params: $params) {
      accountNumber
      relationType
      premiseInfo {
        addressLine1
        city
        postal
        encryptedPremiseId
      }
    }
  }
`;

export const getApplicationsByAccountQueryWithAddress = gql`
  query GetApplications($input: GetApplicationsInput!) {
    getApplications(input: $input) {
      applications {
        id
        accountId
        premiseId
        status
        statusReason
        applicationNo
        ...applicationFragment
        ...applicationEVFragment
      }
    }
  }

  ${applicationFragment}
  fragment applicationEVFragment on ApplicationEV {
    details {
      ...sectionEVFragment
    }
  }

  fragment sectionEVFragment on SectionEV {
    sectionType
    ...sectionEVAccountInfo
  }

  fragment sectionEVAccountInfo on SectionEVAccountInfo {
    data {
      address {
        addressLine1
        city
        state
        postal
      }
    }
  }
`;
