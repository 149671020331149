/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import { useState, useContext, Fragment } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  IconButton,
  Typography,
  FormHelperText,
  Box,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import PhoneTextField from '../../../phone-text-field';
import Close from '@material-ui/icons/Close';
import Buttons from '../../../buttons';
import useFormState from '../../../../hooks/useFormState';
import Backdrop from '../../../backdrop';
import { useTranslation } from '../../../../hooks/useTranslation';
import { NotificationsContext } from '../../../../providers/NotificationsProvider';
import useAccountCustomer from '../../../../hooks/useAccountCustomer';
import colors from '../../../../themes/main-colors';
import { CheckBoxes } from '../../../pge-plus-common/CheckBoxes';
import theme from '../../../../themes/theme';
import { PageTextContentType } from '../../../../hooks/useContentMgmt';
import EmailTextField from '../../../email-text-field';
import { UpdateBillingInfo } from '../pop.types';
import UpdateBillingInfoValidationRules from '../UpdateBillingInfoForm.rules';
import { useIsMobile } from '../../../../util/style-utils';
import {
  displayPhoneNumberFormat,
  parsePhoneNumber,
} from '../../../../util/format';
import {
  PhoneType,
  UpdateProfilePhoneInputDtl,
} from '../../../../__generated__/pge-types';
import apolloClient from '../../../../lib/apolloClient';
import gql from 'not-graphql-tag';

const updateTextPhoneMutation = gql`
  mutation UpdateUserProfilePhone($payload: updateProfilePhoneInputDtl!) {
    updateUserProfilePhone(payload: $payload)
  }
`;

interface Props {
  phoneNumber: string;
  emailAddress: string;
  open: boolean;
  content: PageTextContentType;
  consent: string;
  onClose: () => void;
  onUpdate: (values: UpdateBillingInfo) => void;
}

export default ({
  phoneNumber,
  emailAddress,
  open,
  content,
  consent,
  onClose,
  onUpdate,
}: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const notificationContext = useContext(NotificationsContext);
  const isMobile = useIsMobile();

  const { customer } = useAccountCustomer();

  const handleSave = async (values: UpdateBillingInfo) => {
    setLoading(true);
    const updatePhonePayload: UpdateProfilePhoneInputDtl = {
      encryptedPersonId: customer?.encryptedPersonId || '', // customerInfoData?.encryptedPersonId!,
      phoneNumber: '+1' + parsePhoneNumber(values.phoneNumber),
      phoneType: PhoneType.PrimaryNotificationPhone,
      isPrimary: true,
    };
    try {
      const { data: updatePhoneData, errors } = await apolloClient.mutate<{
        updateTextPhone: boolean;
      }>({
        mutation: updateTextPhoneMutation,
        variables: {
          payload: updatePhonePayload,
        },
        refetchQueries: ['getAccountInfo'],
      });
      if (updatePhoneData) {
        setNotificationMessage(content.get('UPDATE_PHONE_NUMBER_SUCCESS'));
        //onUpdate(displayPhoneNumberFormat(values.phoneNumber));
        onUpdate(values);
      } else {
        setNotificationMessage(
          t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'),
          'error',
        );
        return false;
      }
      setLoading(false);
      onClose();
    } catch (e) {
      console.log(e);
      setNotificationMessage(
        t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'),
        'error',
      );
      setLoading(false);
      onClose();
      return false;
    }
  };

  const setNotificationMessage = (
    message: string | React.ReactNode = '',
    severity: 'success' | 'error' = 'success',
  ) => {
    notificationContext.setState({
      isOpen: true,
      message,
      severity,
    });
    window.scrollTo(0, 0);
  };

  const updateBillingInfo: UpdateBillingInfo = {
    emailAddress,
    phoneNumber,
    consent,
  };

  const updateBillingInfoForm = useFormState(updateBillingInfo, {
    validate: UpdateBillingInfoValidationRules,
    validationContext: {
      content,
    },
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      scroll="body"
      maxWidth="xs"
      disableRestoreFocus={true}
    >
      <DialogTitle>
        <Grid container>
          <Grid item>
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={onClose}
              css={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent css={{ paddingBottom: theme.spacing(3) }}>
        {loading ? <Backdrop forceOpen={true} message={t('LOADING')} /> : null}
        <Grid container>
          <Grid item>
            <Typography variant={'h2'} css={{ marginBottom: 2 }}>
              {content.get('UPDATE_BILLING_CONTACT_INFO_TITLE')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant={'body1'} css={{ marginTop: theme.spacing(2) }}>
              {content.get('UPDATE_BILLING_CONTACT_INFO_DESC')}
            </Typography>

            <PhoneTextField
              label={'Phone Number'}
              name="phoneNumber"
              {...updateBillingInfoForm.props('phoneNumber')}
              error={Boolean(updateBillingInfoForm.errors.phoneNumber)}
              helperText={updateBillingInfoForm.errors.phoneNumber}
              css={{ marginTop: theme.spacing(3) }}
            />

            {/* <EmailTextField
              label={content.get('REUSABLE_EMAIL')}
              name="emailAddress"
              inputProps={{ minLength: 1, maxLength: 60 }}
              {...updateBillingInfoForm.props('emailAddress')}
              css={{ marginTop: theme.spacing(2) }}
            /> */}

            <Typography
              variant="body2"
              css={{
                marginTop: theme.spacing(2),
                '& .MuiTypography-body1': {
                  fontSize: '15px',
                  fontWeight: 'normal',
                },
                '& .MuiButtonBase-root': {
                  padding: '0 9px',
                },
              }}
            >
              <CheckBoxes
                options={[
                  {
                    value: 'agree',
                    label:
                      content.get('UPDATE_BILLING_CONTACT_INFO_CONCENT') || '',
                  },
                ]}
                state={
                  updateBillingInfoForm.values.consent
                    ? [updateBillingInfoForm.values.consent]
                    : []
                }
                setState={e => {
                  void updateBillingInfoForm.setValue(
                    'consent',
                    e.length > 0 ? e[0] : '',
                  );
                }}
              />
            </Typography>
            {updateBillingInfoForm.errors.consent && (
              <FormHelperText error>
                {updateBillingInfoForm.errors.consent}
              </FormHelperText>
            )}
            {/* <Fragment>
              <Box
                css={{
                  '& p': {
                    fontSize: 16,
                  },
                }}
              >
                <FormControlLabel
                  data-testid="marketing-communication-label"
                  control={
                    <Checkbox
                      style={{ alignSelf: 'flex-start', paddingTop: 0 }}
                      data-testid={'signUpForOffers'}
                      color="primary"
                      {...updateBillingInfoForm.props('signUpForOffers')}
                    />
                  }
                  label={
                    <Box style={{ margin: '0px 15px' }}>
                      <Typography css={{ fontSize: isMobile ? 16 : 20 }}>
                        {content.get('CONTACT_INFORMATION_DISCLAIMER')}{' '}
                      </Typography>
                    </Box>
                  }
                />
              </Box>
            </Fragment> */}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        css={{
          padding: '16px 32px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Buttons
          onClick={onClose}
          type={'button'}
          variant={'outlined'}
          color={'secondary'}
          css={{
            border: '2px solid ' + colors.sparkBlue,
            borderRadius: '30px',
            minWidth: '130px',
            '&:hover': {
              border: '2px solid ' + colors.sparkBlue,
            },
          }}
        >
          {content.get('CANCEL')}
        </Buttons>

        <Buttons
          variant={'contained'}
          color={'primary'}
          onClick={updateBillingInfoForm.submit(
            async values => await handleSave(updateBillingInfoForm.values),
          )}
          disabled={
            !updateBillingInfoForm.values.phoneNumber ||
            !updateBillingInfoForm.values.consent ||
            !!updateBillingInfoForm.errors.phoneNumber
          }
          css={{
            backgroundColor: colors.orange,
            borderColor: colors.orange,
            borderRadius: '30px',
            minWidth: '130px',
            '&:hover': {
              backgroundColor: colors.orange,
              borderColor: colors.orange,
            },
          }}
        >
          {content.get('REUSABLE_SAVE')}
        </Buttons>
      </DialogActions>
    </Dialog>
  );
};
