import { jsx, css } from '@emotion/core';

import React from 'react';
import { Link } from 'gatsby';
import { AlertProps } from '@material-ui/lab';
import {
  makeStyles,
  Theme,
  createStyles,
  Box,
  Button,
} from '@material-ui/core';
import colors from '../../themes/main-colors';
import { useTranslation } from '../../hooks/useTranslation';
import Typography from '@material-ui/core/Typography';
import { useIsMobile } from '../../util/style-utils';

import AlertBannerIcon from '../../static/alertBannerIcon.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bodyContainer: {
      borderRadius: '4px',
      backgroundColor: colors.white,
      marginBottom: '2em',
      padding: '20px 22px 16px 18px',
    },
    mainContent: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '16px',
    },
    btnStyle: {
      backgroundColor: colors.buttonHoverPrimary,
      color: colors.white,
      width: 'max-content',
      display: 'block',
      fontSize: '15px',
      '&:hover': {
        backgroundColor: colors.buttonHoverPrimary,
      },
      margin: theme.breakpoints.down('sm') ? '16px auto 0  auto' : '',
      padding: theme.breakpoints.down('sm') ? '6px 16px' : '',
    },
  }),
);

const AlertBanner = ({
  hide,
  title,
  text,
  learnMoreLink,
}: {
  hide?: boolean;
  title: string;
  text: string;
  learnMoreLink: string;
}) => {
  const classes = useStyles();
  const { bodyContainer, mainContent, btnStyle } = classes;
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  if (hide === true) {
    return null;
  }

  const learnMoreBtn = (
    <Link to={learnMoreLink} target="_blank">
      <Button className={btnStyle}>{t('LEARN_MORE')}</Button>
    </Link>
  );

  return (
    <Box className={bodyContainer}>
      <Box className={mainContent}>
        <AlertBannerIcon
          css={{
            alignSelf: 'flex-start',
            marginTop: isMobile ? '0' : '4px',
            width: '100px',
          }}
        />
        <Box>
          <Typography
            css={{
              color: colors.sparkBlue,
              fontWeight: 'bold',
            }}
          >
            {title}
          </Typography>
          <Typography
            css={{
              fontSize: '1rem',
              lineHeight: '1.43',
            }}
          >
            {text}
          </Typography>
        </Box>
        {!isMobile && learnMoreBtn}
      </Box>
      {isMobile && learnMoreBtn}
    </Box>
  );
};

export default AlertBanner;
