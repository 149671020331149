/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, { FC } from 'react';
import {
  Select,
  InputLabel,
  FormControl,
  SelectProps,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import colors from '../../themes/main-colors';

interface DropdownMenuProps {
  name: string | undefined;
  options: { label: string | undefined; value: string }[];
  state: string;
  setState: (option: string) => void;
  errorMsg?: string | null;
}

export const DropdownMenu: FC<DropdownMenuProps> = ({
  name,
  options,
  state,
  setState,
  errorMsg = '',
}) => {
  const handleChange: SelectProps['onChange'] = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
  ) => {
    setState(event.target.value as string);
  };

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel
        id={`${name}-label`}
        css={{
          overflow: 'hidden',
          lineHeight: '1.125',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: 'calc(100% - 24px)',
          '& ..MuiFormLabel-root.Mui-focused': {
            opacity: 0,
          },
        }}
      >
        {name}
      </InputLabel>

      <Select
        labelId={`${name}-label`}
        id={`${name}-selector`}
        label={name}
        disabled={false}
        css={{
          fontSize: '16px',
          backgroundColor: colors.white,
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        value={state}
        onChange={handleChange}
        error={!!errorMsg}
        style={{ width: '100%' }}
      >
        {options.map(opt => (
          <MenuItem key={opt.label} value={opt.value}>
            {opt.label}
          </MenuItem>
        ))}
      </Select>
      {errorMsg && <FormHelperText error>{errorMsg}</FormHelperText>}
    </FormControl>
  );
};
