import React, { Fragment } from 'react';

import { Box, Link, Typography } from '@material-ui/core';

interface StepProps {
  children: React.ReactNode;
}

export function WelcomeBackStepDetails({ children }: StepProps) {
  return <Box>{children}</Box>;
}
