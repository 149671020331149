/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  FormHelperText,
  useTheme,
} from '@material-ui/core';
import { navigate } from 'gatsby';

import routes from '../../../routes';
import colors from '../../../themes/main-colors';
import { UploadPhotos as UploadPhotosFormTypes } from '../evcharger.types';
import useContentMgmt from '../../../hooks/useContentMgmt';
import { useEVChargerFormState } from '../../../providers/EVChargerFormStateProvider';
import useFormState from '../../../hooks/useFormState';
import { createSubmitChargerReceiptValidateFunction } from '../../pge-plus-common/PGEPlusForm.rules';
import { Question } from '../../pge-plus-common/Typography';
import { ProceedOrCancelButton } from '../../pge-plus-common/StepperForm';
import { FileUploader } from '../../pge-plus-common/FileUploader';
import Modal from '../../pge-plus-common/Modal';
import { SaveAndComeBackLink } from '../../pge-plus-common/SaveAndComeBackLink';
import microcopyGroupIds from '../../pge-plus-common/microcopyGroupIds';
import { GridContainer, GridItem } from '../../pge-plus-common/Grid';
import CurrencyTextField from '../../currency-text-field/CurrencyTextField';
import { FullDivider } from '../../pge-plus-common/FullDivider';
import { ContinueFromOtherDeviceButton } from '../../pge-plus-common/ContinueFromOtherDeviceButton';
import useUpdateEVSections from '../../../hooks/pge-plus/useUpdateEVSections';
import useUtil from '../../../hooks/pge-plus/useUtil';
import { uploadPhotosFormStateAdapter } from '../../pge-plus-common/utils/pgeplusFormstateAdapters';
import {
  ApplicationEvSectionType,
  SectionEvPanelInfo,
} from '../../../__generated__/pgeplus-types';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';

interface UploadChargerReceiptProps {
  children?: React.ReactNode;
  path: string;
}

export const UploadChargerReceipt: React.FC<UploadChargerReceiptProps> = props => {
  const {
    formState,
    formState: { uploadPhotos, application },
    updateFormState,
    getSectionData,
  } = useEVChargerFormState();
  const sectionDataUploadPhotos: SectionEvPanelInfo = getSectionData(
    ApplicationEvSectionType.EvPanelInfo,
  );

  const theme = useTheme();
  const { content } = useContentMgmt(microcopyGroupIds.PGE_PLUS_UPLOAD_PHOTOS);

  const form = useFormState(uploadPhotos, {
    validate: createSubmitChargerReceiptValidateFunction(),
    validationContext: {
      content,
    },
  });

  const { updateSectionEVPanelInfo } = useUpdateEVSections();
  const { setErrorNotification } = useUtil();
  const { wrapWithLoader } = useWrapWithLoader();

  const [openExamplePhotoModal, setOpenExamplePhotoModal] = useState<boolean>(
    false,
  );

  const onSubmit = wrapWithLoader(async (data: UploadPhotosFormTypes) => {
    try {
      const response = await updateSectionEVPanelInfo(
        uploadPhotosFormStateAdapter().fromFormState(
          data,
          application?.id!,
          sectionDataUploadPhotos?.id,
        ),
      );

      if (response.data?.updateSectionEVPanelInfo.validation?.success) {
        //Store the data in global store
        updateFormState('uploadPhotos', data);

        //Navigate to the next screen
        void navigate(routes.PGE_PLUS_EV_CHARGERS_SUMMARY);
      } else {
        //TODO: Handle the validation error messages
      }
    } catch (e) {
      setErrorNotification(true);
    }
  });

  const submitSection = async (
    data: UploadPhotosFormTypes,
    saveAndComeBack: boolean = false,
  ) => {
    return updateSectionEVPanelInfo(
      uploadPhotosFormStateAdapter().fromFormState(
        data,
        application?.id!,
        sectionDataUploadPhotos?.id,
        saveAndComeBack,
      ),
    );
  };

  const handleSaveAndExit = wrapWithLoader(async () => {
    try {
      await submitSection(form.values, true);
      void navigate(routes.ACCOUNT);
    } catch (e) {
      setErrorNotification(true);
    }
  });

  return (
    <Box
      mt={4}
      css={{
        maxWidth: '790px',
        margin: 'auto',
        '& .MuiFormControl-root': {
          background: 'transparent',
        },
        '& .MuiInputBase-input': {
          background: '#FFF',
        },
      }}
    >
      <form
        onSubmit={form.submit(values => onSubmit(values), console.log)}
        noValidate
      >
        <GridContainer>
          <GridItem>
            <Question title={content.get('UPLOAD_CHARGER_RECEIPT_TITLE')} />
          </GridItem>
          <GridItem>
            <Typography
              variant={'body1'}
              css={{
                fontSize: '20px',
                fontWeight: 700,
                [theme.breakpoints.down('xs')]: {
                  fontSize: 16,
                },
              }}
            >
              {content.get('UPLOAD_CHARGER_RECEIPT_COST_FIELD')}
            </Typography>
          </GridItem>
          <GridItem>
            <CurrencyTextField
              style={{
                margin: 0,
                width: '100%',
              }}
              label={content.get('UPLOAD_CHARGER_RECEIPT_COST_FIELD') || ''}
              name={content.get('UPLOAD_CHARGER_RECEIPT_COST_FIELD') || ''}
              {...form.props('chargerCost')}
            />
          </GridItem>

          <GridItem>
            <Question
              title={content.get(
                'UPLOAD_CHARGER_RECEIPT_COPY_OF_RECEIPT_HEADER',
              )}
              detail={content.get(
                'UPLOAD_CHARGER_RECEIPT_COPY_OF_RECEIPT_TEXT',
              )}
            />
          </GridItem>
          <GridItem>
            <Button
              css={{
                fontSize: '16px',
                fontWeight: 700,
                padding: 0,
                color: colors.sparkBlue,
              }}
              onClick={() => setOpenExamplePhotoModal(true)}
            >
              {content.get('CHARGER_RECEIPT_EXAMPLE_PHOTO_LINK_TITLE')}
            </Button>
            <Modal
              open={openExamplePhotoModal}
              onClose={() => setOpenExamplePhotoModal(false)}
              title={content.get('EXTENDED_DISTANCE_EXAMPLE_PHOTO_TITLE')}
              showCloseIcon={true}
            >
              {''}
              <Box mt={3}>
                <img
                  css={{
                    width: '100%',
                    maxHeight: '250px',
                  }}
                  src={content.get('CHARGER_RECEIPT_EXAMPLE_PHOTO')}
                />
              </Box>
            </Modal>
          </GridItem>

          <GridItem>
            <FileUploader
              image={form.values.receiptImage as string}
              placeholder={content.get('UPLOAD_A_PHOTO')}
              reuploadLabel={content.get('REUPLOAD_PHOTO')!}
              onUpload={key => form.setValue('receiptImage', key)}
              style={{
                margin: 0,
              }}
            />
            {form.errors.receiptImage && (
              <FormHelperText error>{form.errors.receiptImage}</FormHelperText>
            )}
          </GridItem>
          <FullDivider style={{ margin: 0 }} />
          <ProceedOrCancelButton
            back={routes.PGE_PLUS_EV_CHARGERS_UPLOAD_PHOTOS}
          />
        </GridContainer>

        <SaveAndComeBackLink
          content={content}
          onSaveAndExit={handleSaveAndExit}
        />
      </form>
    </Box>
  );
};
