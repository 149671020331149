/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React from 'react';
import {
  Paper,
  Typography,
  Grid,
  Card,
  CardContent,
  Divider,
} from '@material-ui/core';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import colors from '../../../../themes/main-colors';
import {
  PaymentCardType,
  PaymentConfirmation,
} from '../../../../__generated__/pgeplus-types';
import useContentMgmt, {
  PageTextContentType,
} from '../../../../hooks/useContentMgmt';
import {
  toCurrencyString,
  toDateStringFullMonthName,
} from '../../../../util/format';
import {
  PaymentCategory,
  PaymentProfile,
} from '../../../../__generated__/pge-types';
import { useTranslation } from '../../../../hooks/useTranslation';
import PaymentProfileIcon from '../../../paymentus/payment-profile-icon/PaymentProfileIcon';
import { OneTimePayConfigData } from '../../../payment-form/oneTimePayment.types';
import theme from '../../../../themes/theme';
import ErrorIcon from '@material-ui/icons/Error';
import { parse } from 'date-fns';

const useStyles = makeStyles(() =>
  createStyles({
    paymentOption: {
      color: colors.noirBlur,
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '28px',
      lineHeight: '20px',
      paddingTop: 20,
      paddingBottom: 20,
      fontFamily: 'Untitled-Sans,Roboto',
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
      },
    },
    paymentPaid: {
      color: colors.noirBlur,
      textAlign: 'center',
      fontSize: '20px',
      lineHeight: '20px',
      padding: '0rem 3rem 1rem 3rem',
      fontFamily: 'Untitled-Sans,Roboto',
      [theme.breakpoints.down('md')]: {
        fontSize: '14px',
        lineHeight: '20px',
        padding: '0rem 1rem 1rem 1rem',
      },
    },
    optionDetailsCard: {
      position: 'relative',
      borderRadius: '5px',
      border: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: colors.white,
      boxShadow: '0px 1px 1px #00000029',
      padding: '0 32px',

      '& .MuiFormControlLabel-root': {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        '& .MuiIconButton-label': {
          marginTop: 20,
        },
      },
    },
    greenCheck: {
      color: colors.shoreGreen,
      fontSize: '4rem',
      paddingBottom: 10,
      [theme.breakpoints.down('sm')]: {
        fontSize: '3rem',
      },
    },
    noWrap: {
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('sm')]: {
        whiteSpace: 'normal',
      },
    },
    confirmationValue: {
      color: colors.noirBlur,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
    },
    confirmationNumber: {
      color: colors.noirBlur,
      [theme.breakpoints.down('xs')]: {},
      [theme.breakpoints.up('sm')]: {
        marginRight: 3,
      },
      [theme.breakpoints.up('md')]: {
        marginRight: 3,
      },
      [theme.breakpoints.up('lg')]: {
        marginRight: 3,
      },
    },
    paymentmethodLabel: {
      marginLeft: '0.5em',
      color: colors.noirBlur,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
    },
  }),
);

type Props = {
  content: PageTextContentType;
  programName: string;
  paymentConfirmation: PaymentConfirmation[];
  paymentInfo: OneTimePayConfigData;
  paymentMethodCapLimit: number;
  getMethodDisplayLabel: (paymentConfirmation: PaymentConfirmation) => string;
  getMaskedAccountDisplayLabel: (
    paymentConfirmation: PaymentConfirmation,
    paymentProfile: PaymentProfile,
  ) => React.ReactNode;
};

const PaymentPartialSuccess: React.FC<Props> = ({
  content,
  programName,
  paymentConfirmation,
  paymentInfo,
  paymentMethodCapLimit,
  getMethodDisplayLabel,
  getMaskedAccountDisplayLabel,
}) => {
  const classes = useStyles();
  const { inlineRichText } = useContentMgmt();
  const { richT, t, inlineRichT } = useTranslation();

  return (
    <Paper
      component={'main'}
      style={{
        justifyContent: 'center',
        marginTop: '1.5em',
        padding: '2rem',
        backgroundColor: colors.riverBlue,
      }}
    >
      <Grid item xs={12} md={12} css={{ backgroundColor: colors.white }}>
        <Card
          raised={false}
          component="div"
          className={classes.optionDetailsCard}
        >
          <CardContent
            css={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Grid item>
              <Typography>
                <ErrorIcon className={classes.greenCheck} />
              </Typography>
            </Grid>

            <Typography className={classes.paymentOption}>
              {t('PARTALLY_SUCCEED')}
            </Typography>

            <Divider css={{ width: '100%' }} />

            {paymentConfirmation.map((paymentProfile, index) => (
              <Grid
                container
                key={index}
                css={{
                  justifyContent: 'center',
                  marginTop: theme.spacing(2),
                  marginBottom: theme.spacing(2),
                }}
              >
                <Grid item>
                  {paymentProfile.isSuccess ? (
                    <Typography css={{ textAlign: 'center' }}>
                      {inlineRichText(
                        content.get('POP_PARTIAL_PAYMENT_SUCCESSFUL'),
                        {
                          AMOUNT_PAID: paymentProfile?.paymentAmount
                            ? `${toCurrencyString(
                                parseFloat(
                                  paymentProfile?.paymentAmount.toFixed(2),
                                ),
                                false,
                              )}`
                            : '',
                          PGE_PLUS_SERVICE: programName,
                        },
                      )}
                    </Typography>
                  ) : (
                    <Typography variant={'body1'} css={{ textAlign: 'center' }}>
                      {inlineRichT('ACCOUNT_PAYMENT_FAILED_MESSAGE', {
                        ACCOUNT_PAYMENT: paymentProfile?.paymentAmount
                          ? `$${toCurrencyString(
                              parseFloat(
                                paymentProfile?.paymentAmount.toFixed(2),
                              ),
                              false,
                            )}`
                          : '',
                        PAYMENT_DATE: paymentInfo.paymentDate
                          ? toDateStringFullMonthName(
                              parse(
                                paymentInfo.paymentDate,
                                'MM/dd/yyyy',
                                new Date(),
                              ),
                            )
                          : '',
                      })}
                    </Typography>
                  )}
                </Grid>

                <Grid item>
                  <Typography
                    variant={'body1'}
                    className={`${classes.paymentmethodLabel} ${classes.noWrap}`}
                    component="span"
                  >
                    {getMethodDisplayLabel(paymentProfile)}
                  </Typography>
                  <Typography
                    variant={'body1'}
                    className={`${classes.paymentmethodLabel} ${classes.noWrap}`}
                    component="span"
                    css={{
                      paddingLeft: '4px',
                      '& img': {
                        maxHeight: '1em !important',
                      },
                    }}
                  >
                    <PaymentProfileIcon
                      type={
                        paymentProfile.cardType !== PaymentCardType.Unknown
                          ? paymentProfile.cardType === 'GOOGLE_PAY'
                            ? PaymentCategory.Gp
                            : paymentProfile.cardType === 'APPLE_PAY'
                            ? PaymentCategory.Ap
                            : paymentProfile.cardType
                          : paymentProfile.paymentMethod
                      }
                    ></PaymentProfileIcon>
                    <Typography
                      variant={'h2'}
                      component="span"
                      style={{
                        verticalAlign: 'middle',
                        fontSize: '1.2rem',
                      }}
                    >
                      {getMaskedAccountDisplayLabel(
                        paymentProfile,
                        paymentInfo.paymentSelector.selectedPaymentProfileList[
                          index
                        ].profile,
                      )}
                    </Typography>
                  </Typography>
                </Grid>

                {paymentProfile.isSuccess && (
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography
                      className={classes.confirmationNumber}
                      variant={'body1'}
                    >
                      {t('ONETIMEPAY_YOUR_CONFIRMATION_NEW')}
                    </Typography>
                    <Typography className={classes.confirmationValue}>
                      <strong>{paymentProfile.confirmationId}</strong>
                    </Typography>
                  </Grid>
                )}

                {!paymentProfile.isSuccess && paymentProfile?.errorCode && (
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginTop: '16px',
                      marginBottom: '16px',
                    }}
                  >
                    <Typography component={'span'}>
                      {t(paymentProfile.errorCode)}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            ))}
          </CardContent>
        </Card>
      </Grid>
    </Paper>
  );
};

export default PaymentPartialSuccess;
