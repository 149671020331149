/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import { STEP_STATUS } from '../../welcomeBack.types';
import React, { Fragment } from 'react';
import { WelcomeBackStepHeader } from '../StepHeader';
import { WelcomeBackStepDetails } from '../StepDetails';
import { WelcomeBackSubHeading } from '../../EVApplication';
import { Box, Typography } from '@material-ui/core';
import useContentMgmt, {
  PageTextContentType,
} from '../../../../../hooks/useContentMgmt';
import { useIsMobile } from '../../../../../util/style-utils';
import PGEPlusButtons from '../../../../pge-plus-common/PGEPlusButton';
import PGEPlusLink from '../../../../pge-plus-common/PGEPlusLink';
import {
  Job,
  JobStatus,
  QuoteStatus,
} from '../../../../../__generated__/pgeplus-types';
import { navigate } from '@reach/router';
import { getPOPUrl } from '../../../../pge-plus/pay-out-of-pocket/utils/navigation.util';
import routes from '../../../../../routes';
import { getTimeRangeString } from '../../../../../util/date-utils';
import { formatDate, toCurrencyString } from '../../../../../util/format';
import moment from 'moment';

type Props = {
  status: STEP_STATUS;
  content: PageTextContentType;
  job: Job;
};

const IncomingInstallation = ({ status, content, job }: Props) => {
  const isMobile = useIsMobile();
  const { inlineRichText } = useContentMgmt();

  // If final bill is generated, just look through the payments made before the final bill to get payments related to initial payment.
  let payments = job?.customerPayments;
  if (job?.charges?.isBillGenerated) {
    const billDate = moment(job?.charges?.billDate);
    payments = job?.customerPayments?.filter(p =>
      moment(p.paymentDate).isBefore(billDate),
    );
  }

  return (
    <Fragment>
      <WelcomeBackStepHeader
        title={content.get('INSTALLATION_INCOMING') || ''}
      />
      {(status === STEP_STATUS.IN_PROGRESS ||
        status === STEP_STATUS.COMPLETED) && (
        <WelcomeBackStepDetails>
          {job?.quote?.status === QuoteStatus.Accepted && (
            <Fragment>
              {payments?.length ? (
                <Fragment>
                  {payments?.map(payment => (
                    <WelcomeBackSubHeading
                      subHeading={inlineRichText(
                        content.get('PGE_PLUS_PARTIAL_INITIAL_PAYMENT'),
                        {
                          AMOUNT: toCurrencyString(payment?.amount || 0, true),
                          DATE: formatDate(new Date(payment.paymentDate)),
                        },
                      )}
                    />
                  ))}
                  <Typography css={{ paddingTop: 10 }}>
                    {inlineRichText(
                      content.get('PGE_PLUS_PENDING_AMOUNT_DUE_PAYMENT'),
                      {
                        AMOUNT: toCurrencyString(
                          job?.charges?.amountDue || 0,
                          true,
                        ),
                        DATE: formatDate(new Date(job?.charges?.dueDate)),
                      },
                    )}
                  </Typography>
                </Fragment>
              ) : (
                <WelcomeBackSubHeading
                  subHeading={inlineRichText(
                    content.get('PGE_PLUS_PENDING_INITIAL_PAYMENT'),
                    {
                      AMOUNT: toCurrencyString(
                        job?.charges?.amountDue || 0,
                        true,
                      ),
                      DATE: formatDate(new Date(job?.charges?.dueDate)),
                    },
                  )}
                />
              )}
            </Fragment>
          )}

          {job?.quote?.status === QuoteStatus.FinancingConfirmed && (
            <Fragment>
              {payments?.map(payment => (
                <WelcomeBackSubHeading
                  subHeading={inlineRichText(
                    content.get('PGE_PLUS_MADE_INITIAL_PAYMENT'),
                    {
                      AMOUNT: toCurrencyString(payment?.amount || 0, true),
                      DATE: formatDate(new Date(payment.paymentDate)),
                    },
                  )}
                />
              ))}
              {!job?.serviceTime && (
                <Typography css={{ paddingTop: 10 }}>
                  {content.get('PGE_PLUS_INSTALLATION_DATE_AND_TIME_NOTE')}
                </Typography>
              )}
            </Fragment>
          )}

          {job?.status !== JobStatus.ServiceCompleted &&
            job?.status !== JobStatus.Completed && (
              <WelcomeBackSubHeading
                subHeading={content.get(
                  'PGE_PLUS_INSTALLATION_FINAL_CHARGES_NOTE',
                )}
              />
            )}

          <Box
            display={'flex'}
            justifyContent={isMobile ? 'center' : 'space-between'}
            marginTop={'20px'}
            alignItems={isMobile ? 'normal' : 'center'}
            flexDirection={isMobile ? 'column' : 'row'}
          >
            <Box marginBottom={isMobile ? '1.5rem' : '0rem'}>
              <Typography>
                {content.get('REUSABLE_INSTALLATION_DATE_AND_TIME')}:
              </Typography>
              <Typography css={{ fontWeight: 'bold' }}>
                {job?.serviceTime
                  ? getTimeRangeString(job?.serviceTime)?.date
                  : content.get('TO_BE_SCHEDULED')}
              </Typography>
              <Typography css={{ fontWeight: 'bold' }}>
                {job?.serviceTime
                  ? getTimeRangeString(job?.serviceTime)?.timeRange
                  : content.get('TO_BE_SCHEDULED')}
              </Typography>
            </Box>
            {job?.quote?.status !== QuoteStatus.FinancingConfirmed && (
              <PGEPlusButtons
                buttonMode="Emphasis"
                onClick={() =>
                  navigate(
                    getPOPUrl(
                      routes.PGE_PLUS_PAY_OUT_OF_POCKET_CONFIRM_BILLING_ADDRESS,
                      job?.applicationId,
                    ),
                  )
                }
              >
                {content.get('MAKE_A_PAYMENT')}
              </PGEPlusButtons>
            )}
          </Box>
          {/* <Grid container spacing={2} css={{ marginTop: 20 }}>
            <Grid
              item
              md={6}
              sm={12}
              xs={12}
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <PGEPlusButtons
                onClick={() => alert('handleClickContinueWhereLeftOff')}
              >
                Select Payment Method
              </PGEPlusButtons>
            </Grid>
            <Grid
              item
              md={6}
              sm={12}
              xs={12}
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <PGEPlusLink
                content={'I do not wish to continue'}
                onClick={() => alert('handleClickContinueWhereLeftOff')}
              />
            </Grid>
          </Grid> */}
        </WelcomeBackStepDetails>
      )}
    </Fragment>
  );
};

export default IncomingInstallation;
