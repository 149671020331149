import { RouteComponentProps, Router, useLocation } from '@reach/router';
import React, { useEffect } from 'react';
import {
  PaymentPreference,
  PaymentInfo,
  PaymentVerify,
  PaymentConfirmation,
  Ineligible,
  ConfirmBillingAddress,
} from '../../../components/pge-plus/pay-out-of-pocket';
import routes from '../../../routes';
import usePayOutOfPocket from '../../../hooks/pge-plus/usePayOutOfPocket';
import Backdrop from '../../../components/backdrop';
import { Layout } from '../../../components/pge-plus-common/Layout';
import { Grid } from '@material-ui/core';
import theme from '../../../themes/theme';
import { navigate } from 'gatsby';

const PayOutOfPocket: React.FC<RouteComponentProps> = () => {
  const location = useLocation();

  //Hack for the issue useParams() not fetching the dynamic application id
  const applicationId = location.pathname.split('/')[4];

  const {
    loading,
    application,
    payOutOfPocketState,
    savePaymentInfo,
    submitPayment,
    refetchApplication,
  } = usePayOutOfPocket(applicationId);

  useEffect(() => {
    //TODO: Check for outstanding payment on the quote
    if (!loading && !application) {
      void navigate(routes.ACCOUNT);
      return;
    }
  }, [loading, application]);

  if (!loading && !application) {
    //TODO: Through user to error page
    return null;
  }

  //TODO: Fetch the applicationid and fetch the details from API pass to child components
  return (
    <Layout>
      {loading && <Backdrop forceOpen />}
      {application && (
        <Grid
          container
          spacing={2}
          justify="center"
          css={{
            marginBottom: 36,
            marginTop: 36,
            [theme.breakpoints.up('md')]: {
              '.MuiTypography-root': {
                lineHeight: '26px',
              },
            },
          }}
        >
          <Grid item xs={12} md={9} css={{ maxWidth: 790 }}>
            <Router basepath="/">
              <PaymentPreference
                path={routes.PGE_PLUS_PAY_OUT_OF_POCKET}
                application={application}
                refetchApplication={refetchApplication}
              />
              <ConfirmBillingAddress
                path={routes.PGE_PLUS_PAY_OUT_OF_POCKET_CONFIRM_BILLING_ADDRESS}
                application={application}
                billingInfo={payOutOfPocketState.billingInfo}
              />
              <PaymentInfo
                path={routes.PGE_PLUS_PAY_OUT_OF_POCKET_PAYMENT_INFO}
                application={application}
                paymentInfo={payOutOfPocketState.paymentInfo}
                onSubmit={savePaymentInfo}
              />
              <PaymentVerify
                path={routes.PGE_PLUS_PAY_OUT_OF_POCKET_PAYMENT_VERIFY}
                application={application}
                paymentInfo={payOutOfPocketState.paymentInfo!}
                onSubmit={submitPayment}
              />
              <PaymentConfirmation
                path={routes.PGE_PLUS_PAY_OUT_OF_POCKET_PAYMENT_CONFIRMATION}
                application={application}
                paymentConfirmation={payOutOfPocketState.paymentConfirmation!}
                paymentInfo={payOutOfPocketState.paymentInfo!}
              />
              <Ineligible path={routes.PGE_PLUS_PAY_OUT_OF_POCKET_INELIGIBLE} />
            </Router>
          </Grid>
        </Grid>
      )}
    </Layout>
  );
};

export default PayOutOfPocket;
