import React, { Fragment } from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from '../../hooks/useTranslation';
import AccountDropdown from '../account-dropdown';
import Backdrop from '../backdrop';
import WaysToSaveWidget from '../OpowerWidgets/components/WaysToSaveWidget';
import { AccountDetail, AccountType } from '../../__generated__/pge-types';
import { getPgeEnergyTrackerData } from '../account-summary/single-account/queries';
import colors from '../../themes/main-colors';
import useSelectedAccountParams from '../../hooks/useSelectedAccountParams';
import useAuthQuery from '../../hooks/useAuthQuery';
import useGetAccountType from '../../hooks/useGetAccountType';
import IconMoney from '../../../src/static/waytoSave.svg';
import NextBestActionWidget from '../OpowerWidgets/components/NextBestActionWidget';
import { useAccountCustomerDashboard } from '../../hooks/useAccountCustomerDashboard';
import { checkSMBAccountQuery } from '../../queries/getAccountType.query';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontSize: theme.spacing(4.5),
      lineHeight: theme.typography.pxToRem(28),
      fontWeight: 'bold',
      letterSpacing: '0.720px',
      fontFamily: 'Forma-DJR-Display',
      color: colors.lightCyan,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(3),
        marginBottom: '16px',
      },
    },
    colorTextPrimary: {
      backgroundColor: colors.navBarBackground,
    },
    widget: {
      backgroundColor: colors.lightGray2,
      margin: '20px',
      [theme.breakpoints.down('sm')]: {
        margin: '0px',
        padding: '0px',
      },
    },
    accountBox: {
      margin: '16px 0px 32px',
      [theme.breakpoints.down('sm')]: {
        margin: '16px 0px 20px',
      },
    },
    nonRes: {
      display: 'flex',
    },
    NextBestActionWidgetStyling: {
      color: colors.noirBlur,
      marginTop: '2rem',
      height: 'auto',
    },
    nonResText: {
      padding: '20px',
      fontSize: '20px',
      lineHeight: '25px',
      color: colors.lightCyan,
    },
  }),
);

type Props = {
  path?: string;
};

export default function MyWaysToSave(_: Props) {
  const { accountParams } = useSelectedAccountParams();
  const { t } = useTranslation();
  const classes = useStyles();
  const { accountTypeData, isCommercialAccount } = useGetAccountType({});
  const { showLegacyUI } = useAccountCustomerDashboard();

  const { loading: eTLoading, data: etData } = useAuthQuery<{
    getAccountDetails: Array<AccountDetail>;
  }>(getPgeEnergyTrackerData, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function,no-empty-function
    onError: () => {},
    skip: !accountParams,
  });

  const {
    data: smbAccountTypeData,
    loading: loadingSMBAccount,
    error: errorSMB,
  } = useAuthQuery<{
    getAccountDetails: Array<AccountDetail>;
  }>(checkSMBAccountQuery, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
    },
    errorPolicy: 'all',
    skip: !isCommercialAccount,
  });

  const pgeEnergyTrackerData = etData?.getAccountDetails[0]?.pgeEnergyTracker;
  const isResAccount =
    accountTypeData?.getAccountDetails[0]?.accountType === AccountType?.Res;
  const isSMBAccount =
    smbAccountTypeData?.getAccountDetails[0]?.pgeEnergyTracker?.isSMBAccount;
  const isLoading = etData === undefined || eTLoading || loadingSMBAccount;

  return (
    <Fragment>
      {isLoading || accountTypeData === undefined ? (
        <Backdrop forceOpen message={t('STILL_LOADING')} />
      ) : (
        <>
          <Typography className={classes.heading}>
            {t('MY_WAYS_TO_SAVE')}
          </Typography>
          <Box className={classes.accountBox}>
            <AccountDropdown />
          </Box>
          <Box>
            <Card className={classes.colorTextPrimary}>
              {isResAccount || isSMBAccount ? (
                <CardContent className={classes.widget}>
                  <WaysToSaveWidget
                    entityId={
                      pgeEnergyTrackerData?.energyTrackerInfo?.identifiers?.[0]
                    }
                  />
                </CardContent>
              ) : (
                (isCommercialAccount || !isSMBAccount) && (
                  <CardContent className={classes.widget}>
                    <Grid item className={classes.nonRes}>
                      <IconMoney />
                      <Typography className={classes.nonResText}>
                        {t('MY_WAYS_TO_SAVE_WIDGET_NON_RESIDENTIAL_MESSAGE')}
                      </Typography>
                    </Grid>
                  </CardContent>
                )
              )}
            </Card>
            {!showLegacyUI ? (
              <Card className={classes.NextBestActionWidgetStyling}>
                <CardContent>
                  <NextBestActionWidget
                    entityId={
                      pgeEnergyTrackerData?.energyTrackerInfo?.identifiers?.[0]
                    }
                  />
                </CardContent>
              </Card>
            ) : (
              <div></div>
            )}
          </Box>
        </>
      )}
    </Fragment>
  );
}
