import React from 'react';
import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { navigate } from '@reach/router';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ROUTES from '../../routes';

import colors from '../../themes/main-colors';
import { useTranslation } from '../../hooks/useTranslation';
import { isMobile } from '../../util/style-utils';
import PgeButton from '../../components/pge-button/_PgeButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {},
    root: {
      marginTop: 32,
      marginBottom: 32,
    },
    rootContainer: {
      display: 'flex',
      gap: '20px',
      flexDirection: 'column',
      padding: 50,
      backgroundColor: colors.riverBlue,
    },
    childContainer: {
      gap: '50px',
      [theme.breakpoints.down('xs')]: {
        gap: 'unset',
      },
    },
    headerText: {
      fontWeight: 700,
      fontFamily: 'Forma-DJR-Display',
      fontSize: theme.spacing(3.25),
      letterSpacing: '0.72px',
    },
    imageWrapper: {
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    image: {
      border: `10px solid ${colors.white}`,
      height: '500px',
      width: '500px',
      [theme.breakpoints.down('xs')]: {
        height: '325px',
        width: '325px',
      },
    },
    messageWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '& h2': {
        fontSize: theme.spacing(3),
      },
    },
    errorItem: {
      color: colors.noirBlur,
      paddingTop: 10,
    },
    returnButton: {
      marginTop: 20,
      width: 'max-content',
    },
  }),
);

export default function ErrorPage() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Container className={classes.rootContainer}>
        <Typography variant={'body1'} className={classes.headerText}>
          {t('PAGE_NOT_WORKING')}
        </Typography>
        <Grid container spacing={2} className={classes.childContainer}>
          <Grid item xs={12} sm>
            <Grid item className={classes.imageWrapper}>
              <img
                alt=""
                className={classes.image}
                src={require('../../images/Turtle.jpg')}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm className={classes.messageWrapper}>
            <Typography variant={'h2'} className={classes.errorItem}>
              {t('TECHNICAL_ISSUES')}
            </Typography>
            <Typography variant={'body1'} className={classes.errorItem}>
              {t('PLEASE_TRY_AGAIN')}
            </Typography>
            <Typography variant={'body1'} className={classes.errorItem}>
              {t('HOURS_OF_OPERATION')}
            </Typography>
            <PgeButton
              className={classes.returnButton}
              data-testid="button"
              theme="Primary"
              fullWidth={isMobile()}
              onClick={() => {
                return navigate(ROUTES.HOME);
              }}
            >
              {t('RETURN_TO_HOME_PAGE')}
            </PgeButton>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
