import React from 'react';
import { Model, AlertOptions } from './types';
import { FormState } from '../../../hooks/useFormState.types';
import { useTranslation } from '../../../hooks/useTranslation';
import Tabs from '../../tabs/Tabs';
import AlertsAccordion from './AlertsAccordion';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@material-ui/core';
import { useIsMobile } from '../../../util/style-utils';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextLink from '../../text-link';
import ROUTES from '../../../routes';
import { useStyles } from './styles';
import colors from '../../../themes/main-colors';

type Props = {
  alerts: Array<AlertOptions>;
  form: FormState<Model>;
  handleNavigate: (route: string) => void;
};
type AlertProp = {
  [key: string]: string;
};

enum GroupNames {
  CLASSIFICATION = 'classification',
  NOTIFICATIONGROUP = 'notificationGroup',
}

export default function ManageAlertsControls({
  alerts,
  form,
  handleNavigate,
}: Props) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const classes = useStyles();
  const alertGrouping = (
    alertsData: Array<AlertOptions>,
    propertyName: string,
  ) => {
    return alertsData?.reduce((result: { [key: string]: any }, alert) => {
      const alertInfo = (alert as unknown) as AlertProp;
      const groupType = alertInfo[propertyName];

      if (!result[groupType]) {
        result[groupType] = [];
      }
      result[groupType].push(alert);
      if (propertyName === GroupNames.CLASSIFICATION.toString()) {
        result[groupType]?.sort(
          (currentAlert: AlertOptions, previousAlert: AlertOptions) =>
            currentAlert.notificationGroupSequence -
            previousAlert.notificationGroupSequence,
        );
      } else if (propertyName === GroupNames.NOTIFICATIONGROUP.toString()) {
        result[groupType]?.sort(
          (currentAlert: AlertOptions, previousAlert: AlertOptions) =>
            currentAlert.notificationTypeSequence -
            previousAlert.notificationTypeSequence,
        );
      }
      return result;
    }, {});
  };
  const sortAlerts = (alertsData: Array<AlertOptions>) => {
    alertsData?.sort(
      (currentAlert, previousAlert) =>
        currentAlert.classificationSequence -
        previousAlert.classificationSequence,
    );
    return alertsData;
  };
  const renderNotificationGroupInfo = (
    result: Array<AlertOptions>,
    tabInfo: string,
  ) => {
    const groupResult = alertGrouping(result, GroupNames.NOTIFICATIONGROUP);

    const response = Object.keys(groupResult).map(notificationGroup => {
      return (
        <AlertsAccordion
          alertOptions={groupResult[notificationGroup]}
          form={form}
          headingName={t(`NOTIFICATION_${notificationGroup}_LABEL`)}
          subHeading={t(`NOTIFICATION_${notificationGroup}_SUB_HEADING`)}
          categoryName={notificationGroup}
          handleNavigate={handleNavigate}
        />
      );
    });
    return <>{response}</>;
  };
  const renderTabContent = () => {
    const alertOptions = alertGrouping(
      sortAlerts(alerts),
      GroupNames.CLASSIFICATION,
    );
    return (
      alertOptions &&
      Object.keys(alertOptions).map(tabInfo => {
        return {
          label: t(`CLASSIFICATION_${tabInfo}_LABEL`),
          data: renderNotificationGroupInfo(alertOptions[tabInfo], tabInfo) || (
            <></>
          ),
        };
      })
    );
  };
  const renderTabs = () => {
    return (
      <div>
        {!isMobile ? (
          <Tabs
            tabBackgroundOverride={colors.navBarHoverBackground}
            tabs={renderTabContent() || [{ data: <></>, label: '' }]}
          />
        ) : (
          <>
            {renderTabContent()?.map(notificationType => {
              return (
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant={'body1'} style={{ fontWeight: 600 }}>
                      {notificationType.label}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={classes.renderCardData}>
                      {notificationType.data}
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </>
        )}
        <div className={classes.privacyPolicyContent}>
          <Box mt={2} mb={2}>
            <Typography
              variant={'body1'}
              component="span"
              className={classes.footerText}
            >
              {t('ENROLL_IN_TEXT')}
              <TextLink
                to={ROUTES.PRIVACY_POLICY}
                onClick={e => {
                  e.preventDefault();
                  handleNavigate(ROUTES.PRIVACY_POLICY);
                }}
              >
                {t('PRIVACY_POLICY_AND_SECURITY')}
              </TextLink>
            </Typography>
          </Box>
          <Box mt={2} mb={2}>
            <Typography
              variant={'body1'}
              component="span"
              className={classes.footerText}
            >
              {t('ENROLL_IN_TEXT_WITH_PHONE_NUMBERS')}
            </Typography>
          </Box>
        </div>
      </div>
    );
  };
  return renderTabs();
}
