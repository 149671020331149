/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React, { FC, ReactNode } from 'react';
import { Divider, DividerProps } from '@material-ui/core';

interface FullDividerProps {}

export const FullDivider: FC<DividerProps> = (props: DividerProps | any) => {
  return (
    <Divider
      css={css`
        width: 100%;
        margin-top: 12px;
        margin-bottom: 12px;
      `}
      style={props.style}
    />
  );
};
