import React, { FC, Fragment } from 'react';
import { Grid, Typography, useTheme } from '@material-ui/core';
import { ScheduleFormState } from '.';
import { PageTextContentType } from '../../../hooks/useContentMgmt';
import colors from '../../../themes/main-colors';
import DatePicker from '../../date-picker';
import { DropdownMenu } from '../../pge-plus-common/DropdownMenu';
import { FormState } from '../../../hooks/useFormState.types';
import { formatDate } from '../../../util/format';

interface Props {
  formState: FormState<ScheduleFormState>;
  content: PageTextContentType;
  isFromReScheduleInstallationRoute: boolean;
}

const ScheduleForm: FC<Props> = ({
  formState,
  content,
  isFromReScheduleInstallationRoute,
}: Props) => {
  const today = formatDate(new Date(), 'MM/dd/yyyy');
  const theme = useTheme();

  return (
    <Fragment>
      <Grid container item xs={12} css={{ rowGap: 20 }}>
        <Grid item xs={12}>
          <Typography
            variant={'body1'}
            css={{
              fontSize: '20px !important',
              [theme.breakpoints.down('sm')]: {
                fontSize: '16px !important',
              },
            }}
          >
            {isFromReScheduleInstallationRoute
              ? content.get(
                  'RESCHEDULE_SELECT_NEW_INSTALLATION_DATE_TIME_COLON',
                )
              : content.get('SELECT_INSTALLATION_DATE_TIME')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            style={{ width: '100%' }}
            minDate={today}
            name={content.get('REUSABLE_SELECT_A_DATE')}
            label={content.get('REUSABLE_SELECT_A_DATE')}
            variant={'outlined'}
            {...formState.props('date')}
            mask={'__/__/____'}
            css={{
              backgroundColor: colors.white,
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          css={{
            '.MuiFormControl-root': {
              backgroundColor: 'unset !important',
            },
          }}
        >
          <DropdownMenu
            name={content.get('SELECT_TIME')}
            options={[
              {
                value: `${content.get('TIME_SLOT_1')}`,
                label: `${content.get('TIME_SLOT_1')}`,
              },
              {
                value: `${content.get('TIME_SLOT_2')}`,
                label: `${content.get('TIME_SLOT_2')}`,
              },
              {
                value: `${content.get('TIME_SLOT_3')}`,
                label: `${content.get('TIME_SLOT_3')}`,
              },
            ]}
            state={formState.values.time || ''}
            setState={e => {
              void formState.setValue('time', e);
            }}
            errorMsg={(formState.errors.time as string) || ''}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ScheduleForm;
