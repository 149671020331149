import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FunctionComponent, useContext, useState } from 'react';
import clsx from 'clsx';
import { AccessTime, PictureAsPdf } from '@material-ui/icons';
import {
  BillInfo,
  PaymentPlanType,
  AutoPayInfo,
  ViewBillDetails,
} from '../../../__generated__/pge-types';
import { useTranslation } from '../../../hooks/useTranslation';
import colors from '../../../themes/main-colors';
import { toDateString } from '../../../util/format';
import {
  Button,
  CardContent,
  ClickAwayListener,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ErrorOverlay from '../../error/error-overlay';
import useDownloadBill from '../../../hooks/useDownloadBill';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';
import ROUTES from '../../../routes';
import { navigate } from 'gatsby';
import InvoiceIcon from '../../../../src/static/invoiceIcon.svg';
import DownloadIcon from '../../../../src/static/DownloadIcon.svg';
import BillingOptions from '../billing-options/BillingOptions';
import ResCurrentBillPaymentSummary from '../res-current-bill-payment-summary';
import { Option } from '../program-options-list/ProgramOptionsList';
import ReactMarkdown from 'react-markdown/with-html';
import { useIsMobile } from '../../../util/style-utils';
import {
  LanguageContext,
  SupportedLanguages,
} from '../../../providers/LanguageProvider';
import InfoIcon from '../../../static/InfoIcon.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      display: 'block',
    },
    colorTextPrimary: {
      color: colors.noirBlur,
    },
    paymentSummaryLinks: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    vdivider: {
      margin: theme.spacing(1, 0, 0, 0),
    },
    container: {
      padding: theme.spacing(2),
    },
    cardLayout: {
      width: '100%',
      borderRadius: '4px',
      boxShadow: 'inset 0px 2px 0px #EB7100, 0px 3px 6px #00000029',
    },

    autopayPaperCard: {
      boxShadow: 'inset 0px 2px 0px #27788C, 0px 3px 6px #00000029',
    },
    noBillPaperCard: {
      boxShadow: 'inset 0px 2px 0px #1C304A, 0px 3px 6px #00000029',
    },
    pastDuePaperCard: {
      boxShadow: 'inset 0px 2px 0px red, 0px 3px 6px #00000029',
    },
    boldText: {
      fontWeight: 'bold',
    },
    billContainer: {
      '& .MuiPaper-elevation1': {
        boxShadow: 'none',
      },
    },
    columnView: {
      justifyContent: 'space-between',
      height: '100%',
      display: 'grid',
    },
    textDisplay: {
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0, 2),
      },
    },
    tooltip: {
      display: 'flex',
      textAlign: 'left',
      fontSize: '16px',
      fontFamily: 'Untitled-Sans',
      background: 'white',
      color: colors.noirBlurLight,
      opacity: 1,
      padding: '8px 5px',
    },
  }),
);

interface CurrentBillProps {
  billInfo: BillInfo | null;
  autoPay: AutoPayInfo | null;
  paymentPlanType?: PaymentPlanType | null;
  isPendingDisconnect: boolean | undefined;
  billDetails: ViewBillDetails | null;
  isSummaryBillAccount: boolean | undefined;
  optionList: Option[];
  isTpaEligible: boolean;
  isNewAccount: boolean | null | undefined;
}

const ResCurrentBill: FunctionComponent<CurrentBillProps> = ({
  billInfo,
  autoPay,
  isPendingDisconnect,
  billDetails,
  isSummaryBillAccount,
  optionList,
  isTpaEligible,
  isNewAccount,
}) => {
  const classes = useStyles();
  const { language } = useContext(LanguageContext);
  const { t, richT } = useTranslation();
  const isMobile = useIsMobile();
  const papercardStyle = clsx({
    [classes.cardLayout]: true, //always applies
    [classes.noBillPaperCard]: !Boolean(billDetails),
    [classes.pastDuePaperCard]: isPendingDisconnect,
    [classes.autopayPaperCard]: Boolean(autoPay?.isEnrolled),
  });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openTooltip, setOpenTooltip] = useState(false);
  const handleTooltipClick = () => {
    setOpenTooltip(prev => !prev);
  };
  const { wrapWithLoader } = useWrapWithLoader();
  const downloader = useDownloadBill();
  const downloadBill = wrapWithLoader(
    async (e: React.MouseEvent, isSummary: boolean, isNonDetailed: boolean) => {
      if (e) {
        e.preventDefault();
        setAnchorEl(null);
      }

      const downloadLink = document.createElement('a');

      downloadLink.href = await downloader.downloadBill(
        billDetails?.encryptedBillId,
        isSummary,
        isNonDetailed,
      );

      downloadLink.download = `${t('VIEW_BILL').replace(
        ' ',
        '_',
      )}-${toDateString(billDetails?.billingPeriodStartDate)}_${toDateString(
        billDetails?.billingPeriodEndDate,
      )}.pdf`;

      downloadLink.click();
    },
    () => t('NO_BILL_FOUND'),
  );

  return (
    <Paper className={papercardStyle}>
      <CardContent style={{ padding: '0' }}>
        {billInfo && autoPay ? (
          <Grid container direction="row">
            <Grid item container direction="row">
              <ResCurrentBillPaymentSummary
                billInfo={billInfo}
                autoPay={autoPay}
                amountCreditDisplay={'CR'}
                isMobile={isMobile}
                viewBillDetails={billDetails}
                isPendingDisconnect={isPendingDisconnect}
              />
            </Grid>
            {!isNewAccount && (
              <Grid
                item
                container
                direction="row"
                spacing={2}
                className={classes.container}
                justify="center"
              >
                <Grid item xs={isMobile ? 12 : 4}>
                  <Grid style={{ display: 'flex' }}>
                    <Button
                      style={{
                        width: '100%',
                        whiteSpace: 'nowrap',
                        background: colors.white,
                        color: colors.sparkBlue,
                      }}
                      variant="outlined"
                      onClick={handleClick}
                      size={'large'}
                      startIcon={<DownloadIcon width="20px" height="20px" />}
                      data-testid="positioned-button"
                      id="positioned-button"
                      aria-controls={open ? 'positioned-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                    >
                      {t('DOWNLOAD_PDF')}
                    </Button>
                    {isMobile ? (
                      <ClickAwayListener
                        onClickAway={() => setOpenTooltip(false)}
                      >
                        <Tooltip
                          open={openTooltip}
                          arrow
                          onClick={() => handleTooltipClick()}
                          title={
                            <div className={classes.tooltip}>
                              {richT('NON_DETAILED_BILL_ANNOUNCEMENT_REV_1')}
                            </div>
                          }
                          placement={'bottom'}
                        >
                          <div style={{ padding: '16px 7px 0px' }}>
                            <InfoIcon />
                          </div>
                        </Tooltip>
                      </ClickAwayListener>
                    ) : (
                      <Tooltip
                        title={
                          <div className={classes.tooltip}>
                            {richT('NON_DETAILED_BILL_ANNOUNCEMENT_REV_1')}
                          </div>
                        }
                        placement={'bottom'}
                      >
                        <div style={{ padding: '16px 7px 0px' }}>
                          <InfoIcon />
                        </div>
                      </Tooltip>
                    )}
                  </Grid>
                  <Menu
                    id="positioned-menu"
                    aria-labelledby="positioned-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    PaperProps={{
                      style: {
                        background: colors.brightBlue,
                        color: colors.white,
                        padding: '10px',
                      },
                    }}
                  >
                    <MenuItem onClick={e => downloadBill(e, false, true)}>
                      <PictureAsPdf style={{ marginRight: '10px' }} />
                      {t('DOWNLOAD_SIMPLIFIED_BILL')}
                    </MenuItem>
                    <MenuItem onClick={e => downloadBill(e, false, false)}>
                      <PictureAsPdf style={{ marginRight: '10px' }} />
                      {t('DOWNLOAD_DETAILED_BILL')}
                    </MenuItem>
                  </Menu>
                </Grid>
                <Grid item xs={isMobile ? 12 : 4}>
                  <Button
                    fullWidth
                    onClick={() => navigate(ROUTES.PAYMENT_HISTORY)}
                    size={'large'}
                    variant="outlined"
                    style={{
                      whiteSpace: 'nowrap',
                      background: colors.white,
                      color: colors.sparkBlue,
                    }}
                    startIcon={<InvoiceIcon width="22px" height="22px" />}
                  >
                    {t('VIEW_BILL_HISTORY')}
                  </Button>
                </Grid>
                {isTpaEligible && (
                  <Grid item xs={isMobile ? 12 : 4}>
                    <Button
                      fullWidth
                      onClick={() => navigate(ROUTES.NEED_MORE_TIME_TO_PAY)}
                      size={'large'}
                      variant="outlined"
                      style={{
                        whiteSpace: 'nowrap',
                        background: colors.white,
                        color: colors.sparkBlue,
                      }}
                      startIcon={<AccessTime />}
                    >
                      {t('NMTTP')}
                    </Button>
                  </Grid>
                )}
              </Grid>
            )}
            <Grid
              container
              item
              justify="center"
              className={classes.textDisplay}
            >
              <Typography
                variant="body2"
                style={{ fontSize: '1rem', margin: 0 }}
              >
                <ReactMarkdown
                  source={t('CURRENT_BILL_CARD_UNDERSTAND_MY_BILL_TEXT', {
                    PAGE_LINK:
                      language === SupportedLanguages.English
                        ? ROUTES.UNDERSTANDING_MY_BILL
                        : language === SupportedLanguages.Spanish
                        ? ROUTES.UNDERSTANDING_MY_BILL_SPANISH
                        : ROUTES.UNDERSTANDING_MY_BILL,
                  })}
                  escapeHtml={false}
                />
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ padding: '0px 16px' }}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <BillingOptions
                isMobile={isMobile}
                optionList={optionList}
                defaultExpand={!Boolean(billInfo?.billDetails?.amountDue)}
              />
            </Grid>
          </Grid>
        ) : (
          <ErrorOverlay title={t('CURRENT_BILL')} />
        )}
      </CardContent>
    </Paper>
  );
};

export default ResCurrentBill;
