/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, { Fragment } from 'react';
import { Box, Typography, FormHelperText } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { navigate } from 'gatsby';

import routes from '../../../routes';
import colors from '../../../themes/main-colors';
import { locationTypes, chargerInstallationlocationTypes } from './location';

import useContentMgmt from '../../../hooks/useContentMgmt';
import { useEVChargerFormState } from '../../../providers/EVChargerFormStateProvider';
import useFormState from '../../../hooks/useFormState';
import { createInstallationLocationValidateFunction } from '../../pge-plus-common/PGEPlusForm.rules';
import { Installation as InstallationFormType } from '../evcharger.types';

import CommonButton from '../../pge-plus-common/CommonButton';
import { TextAreaInput } from '../../pge-plus-common/text-area-input';
import PgeVideoPlayer from '../../pge-video-player';
import { ProceedOrCancelButton } from '../../pge-plus-common/StepperForm';

import PopoverToolTip from '../../pge-plus-common/PopoverToolTip';
import { SaveAndComeBackLink } from '../../pge-plus-common/SaveAndComeBackLink';
import {
  ApplicationEvSectionType,
  ChargerInstallLocation,
  PanelLocation,
  SectionEvInstall,
} from '../../../__generated__/pgeplus-types';

import microcopyGroupIds from '../../pge-plus-common/microcopyGroupIds';
import { Question } from '../../pge-plus-common/Typography';
import useUtil from '../../../hooks/pge-plus/useUtil';
import useUpdateEVSections from '../../../hooks/pge-plus/useUpdateEVSections';
import { installationFormStateAdapter } from '../../pge-plus-common/utils/pgeplusFormstateAdapters';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';
import { FullDivider } from '../../pge-plus-common/FullDivider';
import { useIsMobile } from '../../../util/style-utils';

interface InstallationLocationProps {
  children?: React.ReactNode;
  path: string;
}

export const InstallationLocation: React.FC<InstallationLocationProps> = () => {
  const {
    formState,
    formState: { application, installation },
    updateFormState,
    getSectionData,
  } = useEVChargerFormState();

  const { content } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_INSTALLATION_LOCATION,
  );
  const sectionDataInstallationInfo: SectionEvInstall = getSectionData(
    ApplicationEvSectionType.EvInstall,
  );

  const { updateSectionEVInstall } = useUpdateEVSections();
  const { wrapWithLoader } = useWrapWithLoader();
  const isMobile = useIsMobile();

  const form = useFormState(installation, {
    validate: createInstallationLocationValidateFunction(),
    validationContext: {
      content,
    },
  });
  const { setErrorNotification } = useUtil();

  const onSubmit = (data: InstallationFormType) => {
    updateFormState('installation', data);

    void navigate(routes.PGE_PLUS_EV_CHARGERS_INSTALLATION_PANEL_DETAILS);
  };

  const isShowChargerLocations =
    form.values.panelLocation === PanelLocation.Other ||
    form.values.panelLocation === PanelLocation.Basement ||
    form.values.panelLocation === PanelLocation.Inside ||
    form.values.panelLocation === PanelLocation.NotSure;

  const submitSection = async (
    data: InstallationFormType,
    saveAndComeBack: boolean = false,
  ) => {
    return updateSectionEVInstall(
      installationFormStateAdapter().fromFormState(
        data,
        application?.id!,
        sectionDataInstallationInfo?.id,
        saveAndComeBack,
      ),
    );
  };

  const handleSaveAndExit = wrapWithLoader(async () => {
    try {
      await submitSection(form.values, true);
      void navigate(routes.ACCOUNT);
    } catch (e) {
      setErrorNotification(true);
    }
  });

  return (
    <Fragment>
      <form
        onSubmit={form.submit(values => onSubmit(values), console.log)}
        noValidate
      >
        <Box
          display="flex"
          flexDirection="column"
          mt={1}
          css={{
            maxWidth: '790px',
            margin: 'auto',
          }}
        >
          <Question
            title={content.get('ELECTRICAL_PANEL_LOCATION_TITLE')}
            detail={content.get('ELECTRICAL_PANEL_LOCATION_INFO')}
          />

          <Box>
            <Grid
              css={{ marginTop: 24, display: 'flex', justifyContent: 'center' }}
            >
              <PgeVideoPlayer
                video={{
                  url: content.get(
                    'ELECTRICAL_PANEL_LOCATION_INFO_YOUTUBE_SOURCE',
                  ),
                  height: '281px',
                  width: isMobile ? '100%' : '500px',
                }}
              />
            </Grid>
          </Box>
          <Box css={{ marginTop: 24 }}>
            <Grid container spacing={2}>
              {locationTypes({ content }).map(loc => (
                <Grid item md={4} xs={6}>
                  <CommonButton
                    buttonLabel={loc.textLabel}
                    handleOnClick={(e: React.MouseEvent<HTMLElement>) =>
                      form.setValue('panelLocation', loc.formValue)
                    }
                    isActive={form.values.panelLocation === loc.formValue}
                    img={loc.img}
                  />
                </Grid>
              ))}
            </Grid>
            {form.errors.panelLocation && (
              <FormHelperText error>{form.errors.panelLocation}</FormHelperText>
            )}
          </Box>
          {form.values.panelLocation === PanelLocation.Other && (
            <Box css={{ marginTop: 24 }}>
              <TextAreaInput
                {...form.props('comment')}
                value={form.values.comment as string}
                placeholder="Comment"
                inputLabel={content.get('OTHER_COMMENTS_FIELD_LABEL')}
                errorMsg={(form.errors.comment as string) || ''}
              />
            </Box>
          )}
          {/* Show charger install locations (garage or outside home)*/}
          {isShowChargerLocations && (
            <Fragment>
              <Box display={'flex'}>
                <Box>
                  <Question
                    title={content.get('CHARGER_IN_GARAGE_OR_OUTSIDE')}
                  />
                </Box>
              </Box>
              <Grid
                container
                spacing={2}
                css={{ marginTop: 24 }}
                justify="center"
              >
                {chargerInstallationlocationTypes({ content }).map(loc => (
                  <Grid item md={4} xs={6}>
                    <CommonButton
                      buttonLabel={loc.textLabel}
                      handleOnClick={(e: React.MouseEvent<HTMLElement>) =>
                        form.setValue('installLocation', loc.formValue)
                      }
                      isActive={form.values.installLocation === loc.formValue}
                      img={loc.img}
                    />
                  </Grid>
                ))}
              </Grid>
            </Fragment>
          )}

          <FullDivider style={{ marginTop: 24, marginBottom: 18 }} />
          <ProceedOrCancelButton
            back={routes.PGE_PLUS_EV_CHARGERS_INSTALLATION}
            disable={
              !form.values.panelLocation ||
              (isShowChargerLocations && !form.values.installLocation) ||
              (form.values.panelLocation === PanelLocation.Other &&
                !form.values.comment)
            }
          />

          <Grid xs={12} css={{ marginTop: 24 }}>
            <SaveAndComeBackLink
              content={content}
              onSaveAndExit={handleSaveAndExit}
            />
          </Grid>
        </Box>
      </form>
    </Fragment>
  );
};
