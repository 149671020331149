import {
  Box,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  makeStyles,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import { navigate } from '@reach/router';
import React, { FunctionComponent, useContext, useState } from 'react';
import useFormState from '../../../hooks/useFormState';
import { useTranslation } from '../../../hooks/useTranslation';
import {
  RenewablePowerContext,
  RenewablePowerContextActionType,
} from '../../../providers/RenewablePowerProvider';
import ROUTES from '../../../routes';
import colors from '../../../themes/main-colors';
import { useIsMobile } from '../../../util/style-utils';
import { RenewablesAccountEligibleResponse } from '../../../__generated__/pge-types';
import PGEButton from '../../buttons';
import NumericTextField from '../../numeric-text-field/NumericTextField';
import TextLink from '../../text-link';
import { Model, validationRules } from './RenewablePower.rules';
import {
  LanguageContext,
  SupportedLanguages,
} from '../../../providers/LanguageProvider';

/*
  A word about the strategy on how this form is written: 
  
  This form started out using the RenewablePowerProvider but a developer decided to 
  switch to useFormState for this form instead. That is because useFormState is our 
  standard for form state, field format and validation. This turned out to be a partial
  removal of the context state responsibilities for this component. In the interest of 
  time, we decided that this will do for initial release. 

  Before this page is rendered, the context state is populated with data from backend 
  services and passed to this form as initial state. From there, useFormState handles 
  state changes until the 'Next' button is pressed at which point, the form state is 
  passed to the context state for the confirmation page which this component routes to. 
*/

const useStyles = makeStyles((theme: Theme) => ({
  optionLink: {
    cursor: 'pointer',
    color: colors.sparkBlue,
  },
  optionText: {
    paddingLeft: theme.spacing(4),
  },
  formAction: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    padding: '0.8em',
  },
  bottomAction: {
    marginTop: 10,
  },
  greyedOut: {
    color: colors.lightGray1,
    '& a': {
      color: colors.lightGray1,
    },
  },
}));

interface RenewablePowerFormProps {
  path?: string;
  content: any;
  programAnswers: any;
  settings: any;
  eligibility: RenewablesAccountEligibleResponse;
}

const RenewablePowerForm: FunctionComponent<RenewablePowerFormProps> = props => {
  const { language } = useContext(LanguageContext);
  const theme = useTheme();
  const { t } = useTranslation();
  const showMobile = useIsMobile();
  const { content, programAnswers, eligibility } = props;
  const classes = useStyles();
  const { state, dispatch } = useContext(RenewablePowerContext);

  const [isNoChangeError, setIsNoChangeError] = useState(false);

  const initialState = {
    isGreenSource: state.greenSource.isSelected || false,
    isCleanWind: state.cleanWind.isSelected || false,
    blocks: state.cleanWind.blocks || 0,
    isSolarSource: state.solarSource.isSelected || false,
    includeInPromotion: state.includeInPromotion || false,
    isHabitatSupport: state.habitatSupport.isSelected || false,
    promoCode: state.promoCode || '',
    selectedAnswer: state.selectedProgramAnswer || 'Select',
    companyName: state.companyName || '',
    promoteYourCompany: state.includeInPromotion,
  };

  const enrolledState = {
    isGreenSource: state.greenSource.isEnrolled || false,
    isCleanWind: state.cleanWind.isEnrolled || false,
    blocks: state.cleanWind.blocks,
    isSolarSource: state.solarSource.isEnrolled || false,
    includeInPromotion: eligibility.isSmallBusiness || false,
    isHabitatSupport: state.habitatSupport.isEnrolled || false,
    promoCode: state.promoCode || '',
    selectedAnswer: 'Select',
    companyName: state.companyName || '',
    promoteYourCompany: state.includeInPromotion,
  };

  const defaultFormValues: Model = initialState;

  const form = useFormState(defaultFormValues, {
    validate: validationRules,
  });

  function isRelevantChangeForSubmission() {
    const considerCompanyName = form.values.includeInPromotion;
    return (
      form.values.isGreenSource !== enrolledState.isGreenSource ||
      form.values.isCleanWind !== enrolledState.isCleanWind ||
      form.values.blocks !== enrolledState.blocks ||
      form.values.isHabitatSupport !== enrolledState.isHabitatSupport ||
      (considerCompanyName &&
        form.values.companyName !== enrolledState.companyName) ||
      form.values.isSolarSource !== enrolledState.isSolarSource
    );
  }

  const {
    isGreenSource,
    isSolarSource,
    isCleanWind,
    isHabitatSupport,
    blocks,
    includeInPromotion,
  } = form.values;

  const blockPriceText = '$ 1.88 x';
  const getBlocksPrice = () => {
    return blocks ? 1.88 * (blocks || 1) : 0;
  };

  const handleNext = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (!isRelevantChangeForSubmission()) {
      setIsNoChangeError(true);
      return;
    }
    if (isHabitatSupportError()) {
      return;
    }
    dispatch({
      type: RenewablePowerContextActionType.SAVE,
      data: form.values,
    });
    return form.submit(async () => {
      return navigate(ROUTES.RENEWABLE_POWER_CONFIRM);
    })(e);
  };

  const isHabitatSupportError = () => {
    return isHabitatSupport && !(isGreenSource || isCleanWind || isSolarSource);
  };

  const isSmallBusiness = eligibility.isSmallBusiness || false;

  const isEnrolledInAnOption =
    state.greenSource.isEnrolled ||
    state.cleanWind.isEnrolled ||
    state.solarSource.isEnrolled;

  return (
    <Grid container direction={'column'} spacing={4}>
      <form noValidate onSubmit={handleNext}>
        <Grid item>
          <CardContent>
            <Paper
              style={{
                padding: 30,
                color: colors.noirBlur,
              }}
            >
              <Grid container direction={'column'} spacing={1}>
                <Grid item>
                  {isNoChangeError && !isRelevantChangeForSubmission() && (
                    <Typography
                      variant={'body1'}
                      style={{ color: colors.roseRed }}
                    >
                      {content.configPage.enrollmentInfo.errorNoChanges}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <Typography variant={'h2'}>
                    {content.configPage.enrollmentInfo.title}
                  </Typography>
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={'isGreenSource'}
                        disabled={!state.greenSource.isEnable}
                        checked={isGreenSource}
                        color={'primary'}
                        {...form.props('isGreenSource')}
                        onChange={form.onChange}
                      />
                    }
                    label={content.configPage.enrollmentInfo.greenSource.option}
                  />
                </Grid>
                <Grid item>
                  <Grid container direction={'column'} spacing={2}>
                    <Grid item>
                      <Typography variant={'h4'}>
                        {content.configPage.enrollmentInfo.greenSource.text}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant={'body2'} component={'span'}>
                        {content.configPage.enrollmentInfo.greenSource.note}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <TextLink
                        external
                        plain
                        to={
                          language === SupportedLanguages.Spanish
                            ? content.configPage.enrollmentInfo.greenSource
                                .urlSpanish
                            : content.configPage.enrollmentInfo.greenSource.url
                        }
                      >
                        <Typography
                          variant={'body2'}
                          style={{ color: colors.sparkBlue }}
                        >
                          {content.configPage.enrollmentInfo.greenSource.link}
                        </Typography>
                      </TextLink>
                    </Grid>
                    <Divider />
                  </Grid>
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={form.onChange}
                        checked={isCleanWind}
                        disabled={!state.cleanWind.isEnable}
                        name={'isCleanWind'}
                        color={'primary'}
                      />
                    }
                    label={content.configPage.enrollmentInfo.cleanWind.option}
                  />
                </Grid>
                <Grid item>
                  <Grid container direction={'column'} spacing={1}>
                    <Grid
                      container
                      direction={showMobile ? 'column' : 'row'}
                      spacing={1}
                      alignItems={showMobile ? 'flex-start' : 'center'}
                    >
                      <Grid item>
                        <Typography variant={'h4'}>
                          {content.configPage.enrollmentInfo.cleanWind.text}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography> {blockPriceText} </Typography>
                      </Grid>

                      <Grid item style={{ paddingTop: '30' }}>
                        <NumericTextField
                          style={{ width: '10ch' }}
                          label={
                            content.configPage.enrollmentInfo.cleanWind.blocks
                          }
                          name={'blocks'}
                          type="number"
                          {...form.props('blocks')}
                          inputProps={{
                            maxLength: 5,
                            minLength: 1,
                          }}
                        ></NumericTextField>
                      </Grid>
                      <Grid item>
                        <Typography>
                          {' '}
                          = $ {getBlocksPrice().toFixed(2)}{' '}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography variant={'body2'} component={'span'}>
                        {content.configPage.enrollmentInfo.cleanWind.note}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <TextLink
                        external
                        plain
                        to={
                          language === SupportedLanguages.Spanish
                            ? content.configPage.enrollmentInfo.cleanWind
                                .urlSpanish
                            : content.configPage.enrollmentInfo.cleanWind.url
                        }
                      >
                        <Typography
                          variant={'body2'}
                          style={{ color: colors.sparkBlue }}
                        >
                          {content.configPage.enrollmentInfo.cleanWind.link}
                        </Typography>
                      </TextLink>
                    </Grid>
                    <Divider />
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography
                    variant={'body2'}
                    style={{
                      paddingTop: 30,
                    }}
                  >
                    {t('RENEWABLE_POWER_GENERATION_DECLARATION')}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </CardContent>
        </Grid>
        <Grid item>
          <CardContent>
            <Paper
              style={{
                padding: 30,
                color: colors.noirBlur,
              }}
            >
              <Grid container direction={'column'} spacing={2}>
                <Grid item>
                  <Typography variant={'h2'}>
                    {content.configPage.habitatInfo.title}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant={'body1'} component={'span'}>
                    {content.configPage.habitatInfo.text}
                  </Typography>
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color={'primary'}
                        checked={isHabitatSupport}
                        disabled={false}
                        onChange={form.onChange}
                        name="isHabitatSupport"
                      />
                    }
                    label={content.configPage.habitatInfo.option}
                  />
                  {isHabitatSupportError() && (
                    <FormHelperText error>
                      <span>
                        {
                          content.configPage.enrollmentInfo
                            .errorNoOptionSelected
                        }
                      </span>
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </CardContent>
        </Grid>
        {!isEnrolledInAnOption && (
          <Grid item>
            <CardContent>
              <Paper
                style={{
                  padding: 30,
                  color: colors.noirBlur,
                }}
              >
                <Grid container direction={'column'} spacing={2}>
                  <Grid item>
                    <Typography variant={'h2'}>
                      {content.configPage.programInfo.title}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      spacing={1}
                      direction={showMobile ? 'column' : 'row'}
                      justify={'space-between'}
                    >
                      <Grid item>
                        <Grid container direction={'column'} spacing={1}>
                          <Grid item>
                            <Typography variant={'body1'}>
                              {content.configPage.programInfo.question}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Select
                              name="selectedAnswer"
                              variant="outlined"
                              style={{ width: '100%' }}
                              value={form.values.selectedAnswer}
                              onChange={async event => {
                                await form.setValue(
                                  'selectedAnswer',
                                  event.target.value,
                                );
                              }}
                            >
                              <MenuItem key={0} value={'Select'}>
                                {content.configPage.programInfo.noAnswer}
                              </MenuItem>
                              {programAnswers.map(
                                (
                                  answer: any,
                                  i: number,
                                ): React.ReactElement => (
                                  <MenuItem key={i + 1} value={answer?.key}>
                                    {answer?.value}
                                  </MenuItem>
                                ),
                              )}
                            </Select>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container direction={'column'} spacing={1}>
                          <Grid item>
                            <Typography variant={'body1'}>
                              {content.configPage.programInfo.promo}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <TextField
                              label={content.configPage.programInfo.promoLabel}
                              name={'promoCode'}
                              variant={'outlined'}
                              inputProps={{ maxLength: 10 }}
                              style={{ width: '100%' }}
                              {...form.props('promoCode')}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </CardContent>
          </Grid>
        )}
        {isSmallBusiness && (
          <Grid item>
            <CardContent>
              <Paper
                style={{
                  padding: 30,
                  color: colors.noirBlur,
                }}
              >
                <Grid container direction={'column'} spacing={2}>
                  <Grid item>
                    <Typography variant={'h2'}>
                      {content.configPage.companyInfo.title}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant={'body2'}>
                      {content.configPage.companyInfo.text}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <RadioGroup
                      aria-label={'direct-access-authorization'}
                      name={'includeInPromotion'}
                      value={includeInPromotion}
                      {...form.props}
                      onChange={async event => {
                        await form.setValue(
                          'includeInPromotion',
                          event.target.value === 'true',
                        );
                      }}
                    >
                      <Grid container direction={'column'} spacing={1}>
                        <Grid item>
                          <FormControlLabel
                            value={true}
                            control={<Radio color={'primary'} />}
                            label={content.configPage.companyInfo.yesOption}
                          />
                        </Grid>
                        <Grid item>
                          <FormControlLabel
                            value={false}
                            control={<Radio color={'primary'} />}
                            label={content.configPage.companyInfo.noOption}
                          />
                        </Grid>
                        {includeInPromotion && (
                          <Grid item>
                            <Grid container direction={'column'}>
                              <Grid item>
                                <Divider style={{ marginBottom: '1em' }} />
                              </Grid>
                              <Grid item>
                                <Typography variant={'body1'}>
                                  {content.configPage.companyInfo.question}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <TextField
                                  label={
                                    content.configPage.companyInfo.companyName
                                  }
                                  inputProps={{ maxLength: 50 }}
                                  variant={'outlined'}
                                  style={
                                    showMobile
                                      ? { width: '100%' }
                                      : { width: '65%' }
                                  }
                                  name={'companyName'}
                                  margin="normal"
                                  {...form.props('companyName')}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </RadioGroup>
                  </Grid>
                </Grid>
              </Paper>
            </CardContent>
          </Grid>
        )}
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems={showMobile ? 'stretch' : 'flex-end'}
          style={
            showMobile
              ? {
                  paddingLeft: theme.spacing(4),
                  paddingRight: theme.spacing(4),
                }
              : {}
          }
        >
          <Grid item>
            <TextLink plain to={ROUTES.ACCOUNT}>
              <Typography className={classes.formAction}>
                {content.cancel}
              </Typography>
            </TextLink>
          </Grid>
          <Grid item>
            <Box className={classes.formAction}>
              <PGEButton
                variant={'contained'}
                color={'primary'}
                type={'submit'}
              >
                {content.next}
              </PGEButton>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default RenewablePowerForm;
