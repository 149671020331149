import React, { useState, useEffect } from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import { styled, Theme } from '@material-ui/core/styles';
import { RouteComponentProps, navigate } from '@reach/router';

import Grid from '@material-ui/core/Grid';
import colors from '../../../../themes/main-colors';
import ROUTES from '../../../../routes';

import useContentMgmt from '../../../../hooks/useContentMgmt';
import AccountSearch from '../../../account-search/AccountSearch';
import { ProgramCard } from '../../../pge-plus-common/ProgramCard';
import useAccountListHeader from '../../../account-summary/multi-accounts/useAccountListHeader';
import useAccountDetailList from '../../../../hooks/useAccountDetailList';
import { getAccounts } from '../../../pge-plus-common/queries/ChooseAccount';
import Backdrop from '../../../backdrop';
import {
  AccountRelationship,
  PremiseInfo,
} from '../../../../__generated__/pge-types';
import useAccountCustomer from '../../../../hooks/useAccountCustomer';
import microcopyGroupIds from '../../../pge-plus-common/microcopyGroupIds';
import useUtil from '../../../../hooks/pge-plus/useUtil';
import { VirtualAccountType } from '../../../../constants/customEnum';

const ITEMS_PER_PAGE = 6;

const PREFIX = 'EVChargerChooseAccount';
const classes = {
  accountCard: `${PREFIX}-accountCard`,
  showMoreAccounts: `${PREFIX}-showMoreAccounts`,
  cardSearch: `${PREFIX}-cardSearch`,
};

const StyledGrid = styled(Grid)(({ theme }: { theme: Theme }) => ({
  [`& .${classes.accountCard}`]: {
    padding: theme.spacing(3, 3),
    minHeight: '125px',
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.showMoreAccounts}`]: {
    fontSize: '18px',
    lineHeight: '30px',
    color: colors.sparkBlue,
    textAlign: 'center',
    cursor: 'pointer',
    fontWeight: '700',
  },
  [`& .${classes.cardSearch}`]: {
    marginBottom: '5px',
    zIndex: 100,
  },
}));

export const ChooseAccount: React.FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const theme = useTheme();

  const { accountListParams, onAccountSearch } = useAccountListHeader();
  const [showAccountByLimit, setShowAccountByLimit] = useState(ITEMS_PER_PAGE);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    accounts,
    totalCount,
    loading: accountsLoading,
  } = useAccountDetailList(getAccounts, VirtualAccountType.ALL_ACCTS, {
    accountListParams: {
      ...accountListParams,
      pageSize: showAccountByLimit,
      accountStatus: 'ACTIVE',
    },
  });

  const { setErrorNotification } = useUtil();

  const { content } = useContentMgmt(microcopyGroupIds.PGE_PLUS_CONSULTATION);

  const { customer, loading: loadingCustomer } = useAccountCustomer();

  useEffect(() => {
    // user is ineligible if no accounts found
    if (!accountsLoading && !accountListParams.query) {
      if (accounts.length === 0) {
        //TODO: Handle no accounts
      } else if (
        accounts.length === 1 &&
        accounts[0].premiseInfo?.length === 1
      ) {
        void handleSelectedAccount(
          accounts[0].accountNumber,
          accounts[0].encryptedPersonId,
          accounts[0].premiseInfo[0]!,
        );
      }
    }
  }, [accounts.length, customer, accountsLoading]);

  const handleSelectedAccount = async (
    accountNumber: string,
    encryptedPersonId: string,
    premise: PremiseInfo,
  ) => {
    try {
      let completeAddress: string = '';
      if (premise && premise.addressLine1 && premise.city && premise.postal) {
        completeAddress = `${premise.addressLine1.trim()}, ${premise.city.trim()}, ${premise.postal.trim()}`;
      }
      void navigate(ROUTES.PGE_PLUS_SECURE_CONSULTATION_DETAILS, {
        state: {
          accountNumber: accountNumber,
          encryptedPersonId: encryptedPersonId,
          encryptedPremiseId: premise.encryptedPremiseId,
          address: completeAddress,
          hasMultipleAccounts: accounts.length > 1, // Used in on click back logic
        },
      });
    } catch (e) {
      setErrorNotification(true);
      setIsLoading(false);
    }
  };

  return (
    <StyledGrid>
      <Grid
        xs={12}
        md={9}
        css={{ maxWidth: 790, margin: '35px auto 135px auto' }}
      >
        {(accountsLoading || isLoading) && <Backdrop forceOpen />}
        <Box display={'flex'}>
          <Typography
            component="h1"
            variant="h1"
            css={{
              width: '100%',
              textAlign: 'center',
              fontWeight: 700,
              lineHeight: '43px !important',
            }}
          >
            {content.get('SELECT_ACCOUNT_TITLE')}
          </Typography>
        </Box>
        <Box
          css={{ marginBottom: theme.spacing(5), padding: theme.spacing(0, 2) }}
        >
          <Box css={{ marginTop: theme.spacing(2) }}>
            <Typography
              variant="h6"
              gutterBottom
              css={{
                color: colors.noirBlurLight,
                fontSize: '20px !important',
                lineHeight: '26px !important',
                fontFamily: 'Untitled-Sans',
              }}
            >
              {content.get('SELECT_ACCOUNT_DESCRIPTION')}
            </Typography>
          </Box>
          <Box css={{ marginTop: theme.spacing(3) }}>
            <AccountSearch
              onSearch={(search: string) => {
                onAccountSearch(search);
              }}
              value={accountListParams.query}
              styleClass={classes.cardSearch}
              hideSearchIcon={true}
              maxLength={10}
            />
          </Box>
          {!accountsLoading &&
            accounts.length === 0 &&
            accountListParams.query && (
              <Box>
                <Typography variant="body1" css={{ color: colors.roseRed }}>
                  {content.get('NO_ACCOUNTS_FOUND_ERROR_TEXT')}
                </Typography>
              </Box>
            )}
          <Grid container direction="column">
            <Grid container item spacing={2}>
              {accounts?.slice(0, showAccountByLimit).map(account => {
                return account?.premiseInfo?.map((premise, idx) => (
                  <Grid item xs={12} md={6}>
                    <Box css={{ marginTop: theme.spacing(3) }} key={idx}>
                      <ProgramCard
                        key={`${account.accountNumber} - card`}
                        className={classes.accountCard}
                        title={premise?.addressLine1}
                        description={
                          <>
                            {content.get('ACCOUNT')}:{' '}
                            <strong>{account.accountNumber}</strong>
                          </>
                        }
                        onClick={() =>
                          handleSelectedAccount(
                            account.accountNumber,
                            account.encryptedPersonId,
                            premise!,
                          )
                        }
                      />
                    </Box>
                  </Grid>
                ));
              })}
            </Grid>
          </Grid>

          {accounts?.length >= ITEMS_PER_PAGE &&
            accounts?.length !== totalCount && (
              <Box css={{ marginTop: theme.spacing(2) }}>
                <Typography
                  variant="subtitle1"
                  component="div"
                  className={classes.showMoreAccounts}
                  onClick={() =>
                    setShowAccountByLimit(showAccountByLimit + ITEMS_PER_PAGE)
                  }
                >
                  {content.get('SHOW_MORE_ACCOUNTS')}
                </Typography>
              </Box>
            )}
        </Box>
      </Grid>
    </StyledGrid>
  );
};
