export default Object.freeze({
  PGE_PLUS_WELCOME_MODAL: '4a6qDASjr1DZf0tqFthdeJ',
  PGE_PLUS_CHOOSE_ACCOUNT: '6jod2dDA9CyaiZT69kpvf',
  PGE_PLUS_CONFIRM_YOUR_CHARGER: '2LdbtIoI9mpINYtzG2np6',
  PGE_PLUS_CONFIRM_YOUR_CHARGER_PRODUCT_LIST: '63LMZJ1ThYnlfiEbLVevCw',
  PGE_PLUS_CONTACT_INFO: '5q6Swp2LrayuJWBaBgokQx',
  PGE_PLUS_COST_ESTIMATION: '14vXfNcdMf0rJqbke3g7xY',
  PGE_PLUS_EV_PULSE_ENROLLMENT_INELIGIBLE: '4VRENsG3xGhqMhAYwDatxZ',
  PGE_PLUS_INSTALLATION_LOCATION: '6rhVUkoVDyDv97C3L0KLnv',
  PGE_PLUS_REBATE_ELIGIBILITY: '4XvcaAApKXnncaRnTaX7o5',
  PGE_PLUS_SELECT_CHARGER: '7tBDUIas6hy1I9YYKqufLK',
  PGE_PLUS_SELECT_CHARGER_PRODUCT_LIST: '1608EButdhosUkUk4SBP2f',
  PGE_PLUS_SUMMARY: '2KGovzhMh0WxVirGpI88dG',
  PGE_PLUS_UPLOAD_PHOTOS: 'F4LZiWMWFx4Uf1mq376ZT',
  PGE_PLUS_EV_SUCCESS: '5y4OG2gTpndkedxTgFeRfU',
  PGE_PLUS_STEPPER: '6zB83Hp7UOX8Mh2LVXWIdW',
  PGE_PROGRAM_EV_CHARGE: '32SWlNAQa5lkWFIeyogDfZ',
  PGE_PLUS_WELCOME_BACK: '4c9EnZT9As1EA6vl025jwX',
  PGE_PLUS_WELCOME_BACK_GET_SUPPORT: '2XuLWaggHWTYVgj0GalFL4',
  PGE_PLUS_INSTALLER_JOB_LISTING: '2OcmF1fidm3rnuDLOa7lM2',
  PGE_PLUS_REUSABLE_SLUGS: '1HHWDQukGtNrW4MjtE9BVi',
  PGE_PLUS_PAGE_TITLES: '1uRmRxNu3c85L44iTDCoTP',
  PGE_PLUS_INSTALLER_REVIEW_LEAD: '5NCu8EWVOo3e2KpJyJXKT0',
  PGE_PLUS_INSTALLER_COMPLETE_QUOTE: '25iY80NYglDNcc4NVA79S1',
  PGE_PLUS_INSTALLER_VIEW_QUOTE: '1mWQPhc8PZRDHherUKSFZE',
  PGE_PLUS_FINAL_QUOTE: 'WztvxGAlL768l1RJLVx8g',
  PGE_PLUS_FINAL_QUOTE_ACCEPT_DECLINE: '7LQot1mXZKJ88UhIjk8XxG',
  PGE_PLUS_INSTALLER_CONFIRMATION_DETAILS_AND_COST: 'LSIzjKk2nDJqy6oO4AgfG',
  PGE_PLUS_SAVE_AND_COME_BACK_LATER: '66AocNRqSxaSejQHtalEnv',
  PGE_PLUS_INSTALLER_SIGN_IN: '5qdGSYpcOnXcPURHT4Wzgn',
  PGE_PLUS_INSTALLER_FORGOT_PASSWORD: '2RMnNiMfWBHPp6VcL4yC1B',
  PGE_PLUS_INSTALLER_CHANGE_PASSWORD: '2X1POUKEBJBj09LLQtigco',
  PGE_PLUS_INSTALLER_RESCHEDULE_CANCEL: '1mnSKyIs9AL4heY63SmCTa',
  PGE_PLUS_INSTALLER_CANCELLED_JOB: '1b01GXOPBR6QUAtIazqMCQ',
  PGE_PLUS_REBATE_ONLY_STEPPER: '1J5fkss5Etp8Wgoc3dRHt0',
  PGE_PLUS_REBATE_ONLY_PAGE_TITLES: '3bj7dIY95dOaBhldCMDnRO',
  PGE_PLUS_REBATE_ONLY_CHOOSE_ACCOUNT: '21eIWZCLdLWWfelnXEBFyB',
  PGE_PLUS_REBATE_ONLY_CHARGER_INSTRUCTIONS: '20bNAb5tImyQb4vdR8zYFp',
  PGE_PLUS_REBATE_ONLY_CUSTOMER_INFORMATION: '2ktezkMwlwLDrcrbYtcHXf',
  PGE_PLUS_REBATE_ONLY_CONFIRM_YOUR_CHARGER: 'oiFxzRugdCcCFL8ihhcfu',
  PGE_PLUS_REBATE_ONLY_SUCCESS: '4CSs1PTGGB5JtWrcC8WF7C',
  PGE_PLUS_REBATE_ONLY_EV_PULSE_ENTROLLMENT: '5D5wDGdn4jukOTlhpS80V8',
  PGE_PLUS_REBATE_ONLY_VERIFY_REBATE: '2K46pP15UayHNI9YKprkuh',
  PGE_PLUS_OCR: '4szk5wU3sg7tbwKetwJ0mp',
  OUTAGES_CUSTOMERS_IMPACTED_BY_COUNTY: '2dLnWMj7FB4yeRJRjDNxV7',
  PGE_PLUS_REBATE_ONLY_EV_PULSE_REBATE_OFFER: 'SHSMuAaeajqeUvzFNs4dc',
  PGE_PLUS_REBATE_ONLY_ELIGIBILITY_CONFIRMATION: '6r9oRmaq6S4GQOj1hh4lJR',
  PGE_PLUS_REBATE_ONLY_REBATE_RESULTS: '6se4bqiGEnlK8writMVhjx',
  PGE_PLUS_REBATE_ONLY_MULTI_UPLOAD: '70gyOTHyTGQRR4FbTpCsEC',
  PGE_PLUS_REBATE_ONLY_EV_PULSE_CONFIRM_YOUR_INFORMATION:
    '32dnZkJzPfl21Y1OahS6eZ',
  OUTAGES_BY_COUNTY: '6MFYKWIbrYjCGarwhNisMR',
  PGE_PLUS_REBATE_ONLY_CHARGER_AND_INSTALLATION_COST: '6otSS8xclowvERoLouAG5m',
  PGE_PLUS_REBATE_ONLY_CHARGER_CONNECT_TO_WIFI: '6IrZnBvizcIjrbn2YMob2',
  PGE_PLUS_REBATE_ONLY_ELIGIBLE_FOR_EVPLUS: '6DHLFiIUbaTlqpvIkOdSse',
  PGE_PLUS_REBATE_ONLY_REBATE_CONFIRM: '5Drunl1hB9ZdFAdkmYy36A',
  PGE_PLUS_INSTALLER_MANAGE_USER_SCREEN: '71VtejqhiuHzLZsGDcxEgn',
  PGE_PLUS_INSTALLER_ADD_USER_SCREEN: '3hPZ8z5u4mrutJAe8D2ufH',
  PGE_PLUS_INSTALLER_DELETE_USER_SCREEN: '3cqNx9v6sq1QOB0lEQBBBP',
  PGE_PLUS_COMMON_FILE_UPLOAD_COMPONENT: '7utV1Vdwr0Cxvir3xfEE44',
  PGE_PLUS_POP_INELIGIBLE: '1gptyY2JNcaMsqF0axiIrV',
  PGE_PLUS_POP_PAYMENT_FORM: '7MToWKAMpijxaLX0hj1QBq',
  PGE_PLUS_POP_SUCCESS: '5fsJMHdMiDDIwrj21CnS8N',
  PGE_PLUS_POP_PAYMENT_PREFERENCE: 'BPk6y0NBqwQi3iMSyjF46',
  PGE_PLUS_POP_CONFIRM_BILLING_INFORMATION: '1PAIkCHDYK3RF4i88NK8Un',
  PGE_PLUS_APPLICATION_STATUS_DASHBOARD_CARD: '3Ht9c0XUIzsS8TDDCN4hy9',
  PGE_PLUS_CONSULTATION: '68CakqmPWqNyNj7uDb44an',
});
