import React, { Fragment } from 'react';

import { Link, Typography } from '@material-ui/core';
import colors from '../../../../themes/main-colors';
import theme from '../../../../themes/theme';
import ChevronRight from '@material-ui/icons/ChevronRight';

interface StepProps {
  title: string;
  isLink?: boolean;
  onClick?: () => void;
}

export function WelcomeBackStepHeader({
  title,
  isLink = false,
  onClick,
}: StepProps) {
  return (
    <Typography
      component="h3"
      variant="h3"
      className="title"
      css={{
        fontFamily: 'Forma-DJR-Display',
        color: colors.noirBlur,
        letterSpacing: '0.4px',
        fontWeight: 700,
        fontSize: '1.3rem',
        [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
          fontSize: '1.28rem',
        },
      }}
    >
      {!isLink && title}
      {isLink && onClick && (
        <Link
          onClick={onClick}
          css={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: '1.3rem',
            [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
              fontSize: '1.28rem',
            },
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'none',
            },
          }}
        >
          <span>{title}</span>
          <ChevronRight />
        </Link>
      )}
    </Typography>
  );
}
