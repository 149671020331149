/** @jsx jsx */
import { jsx } from '@emotion/core';
import { STEP_STATUS } from '../../welcomeBack.types';
import { Fragment } from 'react';
import { WelcomeBackStepHeader } from '../StepHeader';
import { WelcomeBackStepDetails } from '../StepDetails';
import { WelcomeBackSubHeading } from '../../EVApplication';
import { Grid } from '@material-ui/core';
import { PageTextContentType } from '../../../../../hooks/useContentMgmt';
import PGEPlusButtons from '../../../../pge-plus-common/PGEPlusButton';
import PGEPlusLink from '../../../../pge-plus-common/PGEPlusLink';
import { ApplicationType } from '../../../../../__generated__/pgeplus-types';

type Props = {
  status: STEP_STATUS;
  content: PageTextContentType;
  handleRestartApplication: () => void;
  handleResumeApplication: () => Promise<void>;
  handleViewApplication: () => void;
  appType: ApplicationType;
};

const ApplicationInprogress = ({
  status,
  content,
  handleRestartApplication,
  handleResumeApplication,
  handleViewApplication,
  appType,
}: Props) => {
  return (
    <Fragment>
      {status === STEP_STATUS.IN_PROGRESS && (
        <Fragment>
          <WelcomeBackStepHeader
            title={
              content.get('INSTALLATION_CUSTOMER_WELCOME_BACK_STEP_1_HEADER') ||
              ''
            }
          />
          <WelcomeBackStepDetails>
            <WelcomeBackSubHeading
              subHeading={content.get(
                'INSTALLATION_CUSTOMER_WELCOME_BACK_STEP_1_TEXT',
              )}
            />
            <Grid container spacing={2} css={{ marginTop: 20 }}>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <PGEPlusButtons onClick={handleResumeApplication}>
                  {content.get('CONTINUE')}
                </PGEPlusButtons>
              </Grid>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <PGEPlusLink
                  content={content.get('DISCARD_MY_APPLICATION')}
                  onClick={handleRestartApplication}
                />
              </Grid>
            </Grid>
          </WelcomeBackStepDetails>
        </Fragment>
      )}

      {status === STEP_STATUS.COMPLETED && (
        <WelcomeBackStepHeader
          title={
            appType !== ApplicationType.Er
              ? content.get('VIEW_COMPLETED_AND_SUBMITTED_ANSWERS') || ''
              : content.get('COMPLETED_AND_SUBMITTED_ANSWERS') || ''
          }
          isLink={appType !== ApplicationType.Er}
          onClick={handleViewApplication}
        />
      )}
    </Fragment>
  );
};

export default ApplicationInprogress;
