/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React from 'react';
import { Box, Typography } from '@material-ui/core';
import colors from '../../../../themes/main-colors';
import MUICheckCircle from '@material-ui/icons/CheckCircle';
import { STEP_STATUS } from '../welcomeBack.types';

interface StepIndicatorProps {
  step: number;
  status: STEP_STATUS;
  isClosed?: boolean;
}

export function StepIndicator({ step, status, isClosed }: StepIndicatorProps) {
  const CheckCirlce = (
    <MUICheckCircle
      css={{
        width: '28px',
        height: '28px',
      }}
      htmlColor={isClosed ? colors.grey2 : colors.shoreGreen}
    />
  );

  if (status === STEP_STATUS.COMPLETED) {
    return CheckCirlce;
  }

  return (
    <Box
      css={{
        borderRadius: '100%',
        backgroundColor:
          status === STEP_STATUS.IN_PROGRESS
            ? colors.noirBlurLight
            : colors.lightGray1,
        minWidth: '28px',
        height: '28px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {isClosed ? (
        CheckCirlce
      ) : (
        <Typography
          align="center"
          css={{
            color:
              status === STEP_STATUS.IN_PROGRESS
                ? colors.white
                : colors.noirBlur,
            fontSize: '20px',
            paddingTop: '3px',
            fontWeight: 'bold',
            fontFamily: 'Forma-DJR-Display',
          }}
        >
          {step}
        </Typography>
      )}
    </Box>
  );
}
