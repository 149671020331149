/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, { Fragment } from 'react';
import { PageTextContentType } from '../../../hooks/useContentMgmt';
import { ApplicationEr } from '../../../__generated__/pgeplus-types';
import { Box, Typography } from '@material-ui/core';
import WelcomeBackHeader from './common/Header';
import { useIsMobile } from '../../../util/style-utils';
import { STEP_STATUS } from './welcomeBack.types';
import { WelcomeBackStep } from './common/Step';
import ApplicationInprogress from './common/steps/ApplicationInprogress';
import { WelcomeBackStepHeader } from './common/StepHeader';
import { WelcomeBackStepDetails } from './common/StepDetails';
import { WelcomeBackSubHeading } from './EVApplication';
import { formatDate } from '../../../util/format';
import { WelcomeBackAccordion } from './common/Accordion';
import colors from '../../../themes/main-colors';
import theme from '../../../themes/theme';

type Props = {
  application: ApplicationEr;
  content: PageTextContentType;
  activeStep: number;
  handleRestartApplication: () => void;
  handleResumeApplication: () => Promise<void>;
  handleViewApplication: () => void;
  isClosed?: boolean;
};

const WelcomeBackERApplication = ({
  application,
  content,
  activeStep,
  handleRestartApplication,
  handleResumeApplication,
  handleViewApplication,
  isClosed,
}: Props) => {
  const isMobile = useIsMobile();

  const getStepStatus = (step: number) => {
    if (activeStep === step) {
      return STEP_STATUS.IN_PROGRESS;
    }
    if (step > activeStep) {
      return STEP_STATUS.PENDING;
    }

    return STEP_STATUS.COMPLETED;
  };

  const AppMetadata = (
    <Box
      css={{
        display: 'flex',
        padding: '12px',
        justifyContent: 'space-between',
        backgroundColor: colors.navBarBackground,
      }}
    >
      <Box
        gridGap={2}
        display={'flex'}
        flexDirection={isMobile ? 'column' : 'row'}
      >
        <Typography component="span">
          {content.get('APPLICATION_NO')}
        </Typography>{' '}
        <Typography component="span" style={{ fontWeight: 700 }}>
          {application?.applicationNo}
        </Typography>
      </Box>
      <Box
        display={'flex'}
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems={'flex-end'}
      >
        <Typography component="span">
          {content.get('APPLICATION_START_DATE')}:
        </Typography>{' '}
        <Typography component="span" style={{ fontWeight: 700 }}>
          {formatDate(new Date(application?.createdAt))}
        </Typography>
      </Box>
    </Box>
  );

  const AppDetails = (
    <Fragment>
      <WelcomeBackStep step={1} status={getStepStatus(1)} isClosed={isClosed}>
        <ApplicationInprogress
          status={getStepStatus(1)}
          content={content}
          handleRestartApplication={handleRestartApplication}
          handleResumeApplication={handleResumeApplication}
          handleViewApplication={handleViewApplication}
          appType={application.applicationType}
        />
      </WelcomeBackStep>

      <WelcomeBackStep
        step={2}
        status={
          getStepStatus(1) === STEP_STATUS.COMPLETED
            ? STEP_STATUS.COMPLETED
            : STEP_STATUS.PENDING
        }
        isClosed={isClosed}
      >
        <WelcomeBackStepHeader
          title={content.get('YOU_ARE_ON_YOUR_WAY') || ''}
        />
        {getStepStatus(1) === STEP_STATUS.COMPLETED && (
          <WelcomeBackStepDetails>
            <WelcomeBackSubHeading
              subHeading={content.get('CONGRATULATIONS_YOU_DID_IT')}
            />
          </WelcomeBackStepDetails>
        )}
      </WelcomeBackStep>
    </Fragment>
  );

  return (
    <Fragment>
      {isClosed ? (
        <WelcomeBackAccordion
          title={content.get('EV_CHARGING') || ''}
          category={content.get('COMPLETED_JOB') || ''}
          secondaryBar={AppMetadata}
          cardContent={AppDetails}
        />
      ) : (
        <Box css={{ marginBottom: theme.spacing(4) }}>
          <WelcomeBackHeader
            applicationId={application.id}
            title={content.get('EV_CHARGING') || ''}
            appNumber={
              <Box
                gridGap={2}
                display={'flex'}
                flexDirection={isMobile ? 'column' : 'row'}
              >
                <Typography component="span">
                  {content.get('APPLICATION_NO')}
                </Typography>{' '}
                <Typography component="span" style={{ fontWeight: 700 }}>
                  {application?.applicationNo}
                </Typography>
              </Box>
            }
            appDate={
              <Box
                display={'flex'}
                flexDirection={isMobile ? 'column' : 'row'}
                alignItems={'flex-end'}
              >
                <Typography component="span">
                  {content.get('APPLICATION_START_DATE')}:
                </Typography>{' '}
                <Typography component="span" style={{ fontWeight: 700 }}>
                  {formatDate(new Date(application?.createdAt))}
                </Typography>
              </Box>
            }
          />
          {AppDetails}
        </Box>
      )}
    </Fragment>
  );
};

export default WelcomeBackERApplication;
