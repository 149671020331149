import { Box, Typography } from '@material-ui/core';
import { navigate } from 'gatsby';
import React from 'react';
import { ApplicationType } from '../../../__generated__/pgeplus-types';
import routes from '../../../routes';
import colors from '../../../themes/main-colors';
import theme from '../../../themes/theme';
import { useIsMobile } from '../../../util/style-utils';
import PGEPlusButtons from '../../pge-plus-common/PGEPlusButton';
import useContentMgmt, {
  PageTextContentType,
} from '../../../hooks/useContentMgmt';
import microcopyGroupIds from '../../pge-plus-common/microcopyGroupIds';

export function CreateANewApplication({
  type,
  content,
}: {
  type: ApplicationType;
  content: PageTextContentType;
}) {
  const isMobile = useIsMobile();
  const info = {
    title: '',
    route: routes.PGE_PLUS_GET_STARTED,
  };

  if (type === ApplicationType.Ev) {
    info.title = content.get('EV_CHARGING') || '';
  } else if (type === ApplicationType.Hv) {
    info.title = content.get('HV_APP') || '';
  }

  return (
    <Box
      css={{
        marginTop: theme.spacing(4),
      }}
    >
      <Box
        css={{
          backgroundColor: colors.navBarHoverBackground,
          width: '100%',
          padding: '10px 20px 10px 20px',
        }}
      >
        <Typography
          variant="h4"
          css={{
            color: colors.noirBlur,
            fontWeight: theme.typography.fontWeightBold,
            [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
              textAlign: 'start',
              fontSize: '12px',
            },
          }}
        >
          {info.title}
        </Typography>
      </Box>

      <Box
        css={{
          padding: '24px 20px 24px 20px',
          backgroundColor: colors.white,
          display: 'flex',
          justifyContent: isMobile ? 'center' : 'space-between',
          alignItems: isMobile ? 'normal' : 'center',
          width: isMobile ? '100%' : 'unset',
        }}
        flexDirection={isMobile ? 'column' : 'row'}
      >
        <Typography>{content.get('NO_APP_CURRENTLY')}</Typography>
        <Box
          css={{
            marginTop: isMobile ? theme.spacing(2) : 'unset',
          }}
        >
          <PGEPlusButtons onClick={() => void navigate(info.route)}>
            {content.get('START_NEW_APP')}
          </PGEPlusButtons>
        </Box>
      </Box>
    </Box>
  );
}
