import { Container } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import React from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from '../../../../hooks/useTranslation';
import { Success } from '../../../../components/pge-plus/consultation/success';
import { navigate } from 'gatsby';
import routes from '../../../../routes';
import { useConsultation } from '../../../../components/pge-plus/consultation/useConsultation';

const SuccessPage: React.FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const { t } = useTranslation();
  const {
    isPGEPlusConsultationEnabled,
    isBrowser,
    featureFlagLoading,
  } = useConsultation();

  if (!isPGEPlusConsultationEnabled && isBrowser && !featureFlagLoading) {
    void navigate(routes.ACCOUNT);
  }

  return (
    <>
      <Helmet>
        <title>{t('PGE+ Consultation - Success')}</title>
      </Helmet>
      <Container>
        <Success />
      </Container>
    </>
  );
};

export default SuccessPage;
