/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, { Fragment } from 'react';
import { Box, Typography, Grid, FormHelperText } from '@material-ui/core';
import { navigate } from 'gatsby';

import routes from '../../../routes';
import colors from '../../../themes/main-colors';
import { Installation as InstallationFormType } from '../evcharger.types';
import { distanceFromPanelTypes } from './distance';

import useContentMgmt from '../../../hooks/useContentMgmt';
import { useEVChargerFormState } from '../../../providers/EVChargerFormStateProvider';
import useFormState from '../../../hooks/useFormState';
import { createInstallationPanelValidateFunction } from '../../pge-plus-common/PGEPlusForm.rules';

import PopoverToolTip from '../../pge-plus-common/PopoverToolTip';
import RadioGroupWithQuestion from '../../pge-plus-common/RadioGroupWithQuestion';
import CommonButton from '../../pge-plus-common/CommonButton';
import PgeVideoPlayer from '../../pge-video-player';
import { CheckBoxWithPaper } from '../../pge-plus-common/CheckBoxWithPaper';
import { ProceedOrCancelButton } from '../../pge-plus-common/StepperForm';
import { SaveAndComeBackLink } from '../../pge-plus-common/SaveAndComeBackLink';
import {
  ApplicationEvSectionType,
  ChargerInstallLocation,
  PanelLocation,
  PanelSideLocation,
  SectionEvInstall,
} from '../../../__generated__/pgeplus-types';
import useUpdateEVSections from '../../../hooks/pge-plus/useUpdateEVSections';
import { installationFormStateAdapter } from '../../pge-plus-common/utils/pgeplusFormstateAdapters';
import microcopyGroupIds from '../../pge-plus-common/microcopyGroupIds';
import useUtil from '../../../hooks/pge-plus/useUtil';
import { Question } from '../../pge-plus-common/Typography';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';
import { FullDivider } from '../../pge-plus-common/FullDivider';
import { useIsMobile } from '../../../util/style-utils';

interface PanelDetailsProps {
  children?: React.ReactNode;
  path: string;
}

export const InstallationPanelDetails: React.FC<PanelDetailsProps> = () => {
  const {
    formState: { installation, application },
    updateFormState,
    getSectionData,
  } = useEVChargerFormState();

  const sectionDataInstallationInfo: SectionEvInstall = getSectionData(
    ApplicationEvSectionType.EvInstall,
  );

  const { updateSectionEVInstall } = useUpdateEVSections();
  const { setErrorNotification } = useUtil();
  const { wrapWithLoader } = useWrapWithLoader();
  const isMobile = useIsMobile();

  const { content } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_INSTALLATION_LOCATION,
  );

  const form = useFormState(installation, {
    validate: createInstallationPanelValidateFunction(),
    validationContext: {
      content,
    },
  });

  const submitSection = async (
    data: InstallationFormType,
    saveAndComeBack: boolean = false,
  ) => {
    return updateSectionEVInstall(
      installationFormStateAdapter().fromFormState(
        data,
        application?.id!,
        sectionDataInstallationInfo?.id,
        saveAndComeBack,
      ),
    );
  };

  const onSubmit = wrapWithLoader(async (data: InstallationFormType) => {
    try {
      const response = await submitSection(data);
      if (response.data?.updateSectionEVInstall.validation?.success) {
        //Store the data in global store
        updateFormState('installation', data);

        //Navigate to the next screen
        void navigate(routes.PGE_PLUS_EV_CHARGERS_UPLOAD_PHOTOS);
      } else {
        //TODO: Handle the validation error messages
      }
    } catch (e) {
      setErrorNotification(true);
    }
  });

  const handleSaveAndExit = wrapWithLoader(async () => {
    try {
      await submitSection(form.values, true);
      void navigate(routes.ACCOUNT);
    } catch (e) {
      setErrorNotification(true);
    }
  });

  const isGarageInstallation =
    form.values.panelLocation === PanelLocation.GarageAttached ||
    form.values.installLocation === ChargerInstallLocation.Garage;

  return (
    <Fragment>
      <form
        onSubmit={form.submit(values => onSubmit(values), console.log)}
        noValidate
      >
        <Box
          display="flex"
          flexDirection="column"
          mt={1}
          css={{
            maxWidth: '790px',
            margin: 'auto',
          }}
        >
          <Question
            title={content.get('ELECTRICAL_PANEL_DETAILS_TITLE')}
            detail={content.get('ELECTRICAL_PANEL_DETAILS_INFO')}
          />

          <Box>
            <Grid
              css={{ marginTop: 30, display: 'flex', justifyContent: 'center' }}
            >
              <PgeVideoPlayer
                video={{
                  url: content.get(
                    'ELECTRICAL_PANEL_DISTANCE_INFO_YOUTUBE_SOURCE',
                  ),
                  height: '281px',
                  width: isMobile ? '100%' : '500px',
                }}
              />
            </Grid>
          </Box>
          <Box mt={3}>
            <CheckBoxWithPaper
              option={{
                label: content.get('PREFER_INSTALLER_RECOMMENDATION') as string,
                value: content.get('PREFER_INSTALLER_RECOMMENDATION') as string,
              }}
              state={
                (form.values.installerRecommendPanelDistance
                  ? content.get('PREFER_INSTALLER_RECOMMENDATION')
                  : '')!
              }
              setState={e =>
                form.setValue('installerRecommendPanelDistance', !!e)
              }
              errorMsg={
                (form.errors.installerRecommendPanelDistance as string) || ''
              }
            />
          </Box>
          {!form.values.installerRecommendPanelDistance && (
            <Fragment>
              {/* Note: Removing this image temporary as business ask
              12/14/2023  */}
              {/* <Box mt={3}>
                <img
                  css={{
                    width: '100%',
                    maxHeight: '250px',
                  }}
                  src={
                    'https://placeholder.pics/svg/350x150/DEDEDE/434245-D4D4D4/Garage%20Photo'
                  }
                />
              </Box> */}
              <FullDivider style={{ marginTop: 30, marginBottom: 30 }} />
              <Box>
                <Grid container spacing={2}>
                  {distanceFromPanelTypes({ content }).map(distance => (
                    <Grid item md={4} xs={6}>
                      <CommonButton
                        buttonLabel={distance.textLabel}
                        handleOnClick={(e: React.MouseEvent<HTMLElement>) =>
                          form.setValue('distanceToPanel', distance.formValue)
                        }
                        isActive={
                          form.values.distanceToPanel === distance.formValue
                        }
                        img={distance.img}
                      />
                    </Grid>
                  ))}
                </Grid>
                {form.errors.distanceToPanel && (
                  <FormHelperText error>
                    {form.errors.distanceToPanel}
                  </FormHelperText>
                )}
              </Box>
              {isGarageInstallation && (
                <Box mt={3}>
                  <RadioGroupWithQuestion
                    question={content.get('PANEL_LOCATION')}
                    options={[
                      {
                        label: content.get('LEFT_SIDE')!,
                        value: PanelSideLocation.Left,
                      },
                      {
                        label: content.get('BACK_SIDE')!,
                        value: PanelSideLocation.BackWall,
                      },
                      {
                        label: content.get('RIGHT_SIDE')!,
                        value: PanelSideLocation.Right,
                      },
                    ]}
                    selectedValue={form.values.panelSideLocation || ''}
                    onValueChange={e => {
                      void form.setValue('panelSideLocation', e);
                    }}
                  />
                  {form.errors.panelSideLocation && (
                    <FormHelperText error>
                      {form.errors.panelSideLocation}
                    </FormHelperText>
                  )}
                </Box>
              )}
            </Fragment>
          )}
          <FullDivider style={{ marginTop: 30, marginBottom: 18 }} />

          <ProceedOrCancelButton
            back={routes.PGE_PLUS_EV_CHARGERS_INSTALLATION_LOCATION}
            disable={
              !form.values.installerRecommendPanelDistance &&
              ((isGarageInstallation &&
                (!form.values.panelSideLocation ||
                  !form.values.distanceToPanel)) ||
                (!isGarageInstallation && !form.values.distanceToPanel))
            }
          />
          <Box mt={3}>
            <SaveAndComeBackLink
              content={content}
              onSaveAndExit={handleSaveAndExit}
            />
          </Box>
        </Box>
      </form>
    </Fragment>
  );
};
