/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import {
  Box,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import React from 'react';
import PaymentInfoForm from '../../payment-form/payment-info-form';
import usePaymentForm from '../../../hooks/usePaymentForm';
import routes from '../../../routes';
import useOneTimePayment from '../../../hooks/useOneTimePayment';
import { OneTimePayConfigData } from '../../payment-form/oneTimePayment.types';
import Backdrop from '../../backdrop/Backdrop';
import { formatDate, toCurrencyString } from '../../../util/format';
import { Link, navigate } from 'gatsby';
import useContentMgmt from '../../../hooks/useContentMgmt';
import microcopyGroupIds from '../../pge-plus-common/microcopyGroupIds';
import {
  Application,
  ApplicationType,
} from '../../../__generated__/pgeplus-types';
import { getPOPUrl } from './utils/navigation.util';
import { useTranslation } from '../../../hooks/useTranslation';
import PGEButton from '../../buttons';

type Props = RouteComponentProps & {
  application: Application;
  paymentInfo: OneTimePayConfigData | null;
  onSubmit: (data: OneTimePayConfigData) => void;
};

const PaymentInfo: React.FC<Props> = ({
  application,
  paymentInfo,
  onSubmit,
}) => {
  const payBillProps = usePaymentForm();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const childProps = { isMobile, ...payBillProps };
  const { customer, savedProfileList, accountLoading } = useOneTimePayment({
    skipAccountInfo: true,
  });
  const { t } = useTranslation();
  const { content, inlineRichText, richText } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_POP_PAYMENT_FORM,
  );

  const paymentMethodCapLimit: number = 2;

  const handleEligibleValue = (value: boolean) => {
    return false;
  };

  if (
    accountLoading ||
    customer === undefined ||
    savedProfileList === undefined
  ) {
    return <Backdrop forceOpen />;
  }

  const handleSaveAndExit = () => {
    void navigate(
      getPOPUrl(
        routes.PGE_PLUS_PAY_OUT_OF_POCKET_CONFIRM_BILLING_ADDRESS,
        application.id,
      ),
    );
  };

  const isInitialPayment = !application?.job?.charges?.isBillGenerated;
  const financialStandingNotGood =
    application?.financialStanding?.hasActiveBankruptcy ||
    application?.financialStanding?.hasMissedPGEPlusPayment;

  const program =
    application.applicationType === ApplicationType.Ev
      ? content.get('PGE_PLUS_PROGRAM_EV')
      : '';

  const title = isInitialPayment
    ? content.get('POP_PAYMENT_TITLE')
    : content.get('POP_FINAL_PAYMENT_TITLE');
  const description = !isInitialPayment
    ? inlineRichText(content.get('POP_FINAL_PAYMENT_TEXT'), {
        PROGRAM: program,
      })
    : financialStandingNotGood
    ? richText(content.get('POP_PAYMENT_NOT_GOOD_STANDING_TEXT'))
    : content.get('POP_PAYMENT_TEXT');

  const isNoDue = application?.job?.charges?.remainingAmount! <= 0;

  return (
    <Grid
      container
      justify={isInitialPayment ? 'center' : 'flex-start'}
      alignItems={isInitialPayment ? 'center' : 'flex-start'}
      direction="column"
    >
      <Grid item>
        <Typography variant={'h1'} css={{ marginBottom: 30 }}>
          {title}
        </Typography>
      </Grid>

      <Grid item>
        <Typography variant="body1" component="div" css={{ fontSize: 20 }}>
          {description}
        </Typography>
      </Grid>

      <Grid
        item
        css={{
          width: '100%',
          '& #navigation-panel': { display: isNoDue ? 'none' : 'flex' },
        }}
      >
        <PaymentInfoForm
          path={routes.PGE_PLUS_PAY_OUT_OF_POCKET_PAYMENT_INFO}
          {...childProps}
          disableSplitPay={true}
          savedProfileList={savedProfileList}
          onSubmit={onSubmit}
          handleEligible={handleEligibleValue}
          oneTimePayConfigData={paymentInfo}
          paymentMethodCapLimit={paymentMethodCapLimit}
          defaultAmount={application?.job?.charges?.amountDue}
          minimumAmount={String(
            isInitialPayment ? application?.job?.charges?.amountDue || 0 : 0,
          )}
          maximumAmount={application?.job?.charges?.remainingAmount || 0}
          paymentInfo={{
            amountDue: application?.job?.charges?.remainingAmount || 0,
            dueDate: formatDate(new Date(application?.job?.charges?.dueDate)),
          }}
          disableAmountField={isInitialPayment && financialStandingNotGood}
          disableDateField={!isInitialPayment}
          nextButtonText={content.get('REUSABLE_BUTTON_NEXT')}
          backButtonText={content.get('REUSABLE_BUTTON_BACK')}
          backButtonRoute={getPOPUrl(
            routes.PGE_PLUS_PAY_OUT_OF_POCKET_CONFIRM_BILLING_ADDRESS,
            application.id,
          )}
          enableDonation={false}
          cardFooterContent={
            <Box css={{ padding: theme.spacing(0, 4, 4, 4) }}>
              <Box
                css={{
                  display: 'flex',
                  justifyContent: isInitialPayment
                    ? 'space-between'
                    : 'flex-end',
                  margin: '16px 0',
                }}
              >
                {isInitialPayment && (
                  <Typography variant="subtitle2" css={{ fontSize: '16px' }}>
                    {inlineRichText(content.get('MINIMUM_AMOUNT_DUE'), {
                      AMOUNT: toCurrencyString(
                        application?.job?.charges?.amountDue || 0,
                        true,
                      ),
                    })}
                  </Typography>
                )}
                {
                  <Typography variant="subtitle2" css={{ fontSize: '16px' }}>
                    {inlineRichText(content.get('AMOUNT_DUE'), {
                      AMOUNT: toCurrencyString(
                        application?.job?.charges?.remainingAmount || 0,
                        true,
                      ),
                    })}
                  </Typography>
                }
              </Box>
              {isInitialPayment && (
                <Box>
                  <Divider />
                  <Typography css={{ marginTop: theme.spacing(2) }}>
                    {inlineRichText(content.get('INITIAL_PAYMENT_DISCLAIMER'))}
                  </Typography>
                </Box>
              )}
            </Box>
          }
        />
      </Grid>

      {isNoDue && (
        <Grid
          container
          spacing={2}
          css={{
            marginTop: 24,
            marginBottom: 24,
            justifyContent: 'center',
          }}
        >
          <Grid item xs={6} md={3}>
            <Link
              to={routes.PGE_PLUS_EV_CHARGERS_WELCOME_BACK}
              state={{ accountNumber: application?.accountId }}
            >
              <PGEButton
                type="button"
                fullWidth
                variant="contained"
                color="secondary"
                css={{
                  fontSize: '18px',
                  borderRadius: '30px',
                  '.MuiButton-label': {
                    fontWeight: 500,
                  },
                  '&:hover': {
                    borderWidth: '2px',
                  },
                }}
              >
                {content.get('DONE')}
              </PGEButton>
            </Link>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default PaymentInfo;
