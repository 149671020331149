import { Box, Typography } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import React from 'react';
import { ApplicationType } from '../../../__generated__/pgeplus-types';
import { PageTextContentType } from '../../../hooks/useContentMgmt';
import routes from '../../../routes';
import colors from '../../../themes/main-colors';
import theme from '../../../themes/theme';
import { formatDate } from '../../../util/format';
import { useIsMobile } from '../../../util/style-utils';
import { WelcomeBackAccordion } from './common/Accordion';

import { WelcomeBackStepHeader } from './common/StepHeader';

export function InactiveApplication({
  type,
  applicationNumber,
  applicationStartDate,
  handleViewApplication,
  content,
}: {
  type: ApplicationType;
  applicationNumber: string;
  applicationStartDate: string;
  handleViewApplication: () => void;
  content: PageTextContentType;
}) {
  const isMobile = useIsMobile();

  const info = {
    title: '',
    route: '',
  };

  if (type === ApplicationType.Ev) {
    info.title = content.get('EV_CHARGING') || '';
    info.route = routes.PGE_PLUS_EV_CHARGERS_CHOOSE_ACCOUNT;
  } else if (type === ApplicationType.Er) {
    info.title = content.get('EV_CHARGING') || '';
    info.route = routes.PGE_PLUS_REBATE_ONLY_HOME;
  } else if (type === ApplicationType.Hv) {
    info.title = content.get('HV_APP') || '';
    info.route = routes.HOME;
  }

  const AppMetadata = (
    <Box
      css={{
        display: 'flex',
        padding: '12px',
        justifyContent: 'space-between',
        backgroundColor: colors.navBarBackground,
      }}
    >
      <Box
        gridGap={2}
        display={'flex'}
        flexDirection={isMobile ? 'column' : 'row'}
      >
        <Typography component="span">
          {content.get('APPLICATION_NO')}
        </Typography>{' '}
        <Typography component="span" style={{ fontWeight: 700 }}>
          {applicationNumber}
        </Typography>
      </Box>
      <Box
        display={'flex'}
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems={'flex-end'}
      >
        <Typography component="span">
          {content.get('APPLICATION_START_DATE')}:
        </Typography>{' '}
        <Typography component="span" style={{ fontWeight: 700 }}>
          {formatDate(new Date(applicationStartDate))}
        </Typography>
      </Box>
    </Box>
  );

  const Details = (
    <Box
      css={{
        display: 'flex',
        padding: '20px',
      }}
    >
      <Box>
        <CheckCircle
          css={{
            width: '28px',
            height: '28px',
          }}
          htmlColor={colors.grey2}
        />
      </Box>
      <Box
        css={{
          width: '100%',
          display: 'flex',
        }}
        flexDirection={'column'}
      >
        <Box
          css={{
            width: '100%',
            display: 'flex',
            marginLeft: theme.spacing(2),
          }}
          flexDirection={'column'}
        >
          <Typography
            component="h3"
            variant="h3"
            className="title"
            css={{
              fontFamily: 'Forma-DJR-Display',
              color: colors.noirBlur,
              letterSpacing: '0.4px',
              fontWeight: 500,
              fontSize: '1.3rem',
              [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
                fontSize: '1.28rem',
              },
            }}
          >
            {content.get('YOU_HAVE_CANCELLED_THIS_APP')}
          </Typography>
          <Typography
            component="h3"
            variant="h3"
            className="title"
            css={{
              fontFamily: 'Forma-DJR-Display',
              marginTop: theme.spacing(2),
              color: colors.noirBlur,
              letterSpacing: '0.4px',
              fontWeight: 700,
              fontSize: '1.3rem',
              [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
                fontSize: '1.28rem',
              },
            }}
          >
            <WelcomeBackStepHeader
              title={
                type !== ApplicationType.Er
                  ? content.get('VIEW_COMPLETED_AND_SUBMITTED_ANSWERS') || ''
                  : content.get('COMPLETED_AND_SUBMITTED_ANSWERS') || ''
              }
              isLink={type !== ApplicationType.Er}
              onClick={handleViewApplication}
            />
          </Typography>
        </Box>
      </Box>
    </Box>
  );

  return (
    <WelcomeBackAccordion
      title={info.title}
      category={content.get('CANCELLED') || ''}
      secondaryBar={AppMetadata}
      cardContent={Details}
    />
  );
}
