import { Container } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import React from 'react';
import Helmet from 'react-helmet';
import { SelectAddress } from '../../../../components/pge-plus/consultation/select-address';
import { useTranslation } from '../../../../hooks/useTranslation';
import useAuth from '../../../../hooks/useAuth';
import { navigate } from 'gatsby';
import routes from '../../../../routes';
import { useConsultation } from '../../../../components/pge-plus/consultation/useConsultation';

const SelectAddressPage: React.FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();
  const {
    isPGEPlusConsultationEnabled,
    isBrowser,
    featureFlagLoading,
  } = useConsultation();

  if (!isPGEPlusConsultationEnabled && isBrowser && !featureFlagLoading) {
    void navigate(routes.ACCOUNT);
  }

  if (isAuthenticated) {
    void navigate(routes.PGE_PLUS_SECURE_CONSULTATION_DETAILS);
  }

  return (
    <>
      <Helmet>
        <title>{t('PGE+ Consultation - Select Address')}</title>
      </Helmet>
      <Container>
        <SelectAddress />
      </Container>
    </>
  );
};

export default SelectAddressPage;
