import { PageTextContentType } from '../../../hooks/useContentMgmt';
import useFormState from '../../../hooks/useFormState';
import { ConsultationFormModel } from './types';
import {
  createConsultationDetailsValidateFunction,
  createSelectAddressValidateFunction,
} from './validations';

export const selectAddressForm = (content: PageTextContentType) => {
  const form = useFormState(
    {
      inputAddress: '',
      associationWithAddress: undefined,
      address: undefined,
    },
    {
      validate: createSelectAddressValidateFunction(),
      validationContext: { content },
    },
  );

  return { form };
};

export const detailsForm = (content: PageTextContentType) => {
  const DEFAULT_VALUES: ConsultationFormModel = {
    address: undefined,
    associationWithAddress: undefined,
    product: undefined,
    firstName: undefined,
    emailAddress: undefined,
    lastName: undefined,
    middleName: undefined,
    phoneNumber: undefined,
    isMainContact: undefined,
    preferredLanguage: undefined,
    preferredDay: undefined,
    preferredTimeSlot: undefined,
    service: undefined,
    hvacDiscussion: undefined,
    evDiscussion: undefined,
    otherDiscussion: undefined,
    userRelationship: undefined,
  };

  const form = useFormState(DEFAULT_VALUES, {
    validate: createConsultationDetailsValidateFunction(),
    validationContext: { content },
  });

  return { form };
};
