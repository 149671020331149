import { useApolloClient } from '@apollo/react-hooks';
import { getAccountDetails } from '../components/account-summary/single-account/queries';
import {
  OneTimePayConfigData,
  OneTimePayConfirmationResponse,
  SubmitOneTimePay,
} from '../components/payment-form/oneTimePayment.types';
import paymentInfoFormRules, {
  Model as PaymentInfoModel,
} from '../components/payment-form/payment-info-form/PaymentInfoForm.rules';
import {
  getAccountDetailsQuery,
  submitOnetimePaymentMutation,
} from '../queries/oneTimePayment.query';
import { getFormattedToday } from '../util/format';
import { AccountDetail, PaymentInputList } from '../__generated__/pge-types';
import useAccountCustomer from './useAccountCustomer';
import useAuthQuery from './useAuthQuery';
import useFormState from './useFormState';
import { FormState } from './useFormState.types';
import { useSavedProfileList } from './usePaymentus';
import useSelectedAccountParams from './useSelectedAccountParams';

export default ({ skipAccountInfo = false } = {}) => {
  const { encryptedAccountNumber, accountParams } = useSelectedAccountParams();
  const { customer } = useAccountCustomer();
  const apolloClient = useApolloClient();

  const {
    loading: accountLoading,
    data: accountData,
    refetch: refetchAccount,
  } = useAuthQuery<{
    getAccountDetails: Array<AccountDetail>;
  }>(getAccountDetailsQuery, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
    },
    skip: !accountParams || skipAccountInfo,
  });

  const accountDetails = accountData?.getAccountDetails?.[0];

  const encryptedPersonId = accountDetails?.isLoggedInUserOnAccount
    ? customer?.encryptedPersonId
    : accountParams?.encryptedPersonId;

  const {
    loading: profileListLoading,
    savedProfileList,
    refetch: refetchProfileList,
  } = useSavedProfileList(customer);

  const refetchPayBillAccountDetails = () => refetchAccount();
  const refetchProfileListDetails = () => refetchProfileList();

  const {
    loading: hookLoading,
    data,
    refetch: refetchSingleAccount,
  } = useAuthQuery<{
    getAccountDetails: Array<AccountDetail>;
  }>(getAccountDetails, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
    },
    errorPolicy: 'all',
    skip: !accountParams,
  });
  const refetchSingleAccountDetails = () => refetchSingleAccount();
  function submitOneTimePay(
    account: AccountDetail,
    config: OneTimePayConfigData,
    oneTimePayAdditionalInfo: SubmitOneTimePay,
  ) {
    const payload: PaymentInputList = {
      donationAmount: null,
      payment: [],
    };
    config.paymentSelector.selectedPaymentProfileList.map(p => {
      payload.payment.push({
        accountNumber: account.accountNumber,
        encryptedPersonId: oneTimePayAdditionalInfo.encryptedPersonId,
        tokenId: p.profile.token,
        paymentAmount:
          config.charitySelection &&
          config.charitySelection !== 'none' &&
          config?.charitySelection !== ''
            ? Number(
                Number(
                  Number(config.paymentAmount) + Number(config.charityAmount),
                ).toFixed(2),
              )
            : config.paymentSelector.selectedPaymentProfileList.length === 2
            ? Number(Number(p.value).toFixed(2))
            : Number(Number(config.paymentAmount).toFixed(2)),
        paymentDate: config.paymentDate,
      });
    });
    return apolloClient.mutate<{
      makePayment: OneTimePayConfirmationResponse;
    }>({
      mutation: submitOnetimePaymentMutation,
      variables: {
        payload: {
          ...payload,
          donationAmount:
            config?.charitySelection &&
            config?.charitySelection !== 'none' &&
            config?.charitySelection !== ''
              ? Number(Number(config?.charityAmount).toFixed(2))
              : null,
        },
      },
      errorPolicy: 'all',
    });
  }

  return {
    accountLoading,
    accountDetails,
    refetchPayBillAccountDetails,
    encryptedAccountNumber,
    accountData,
    encryptedPersonId,
    submitOneTimePay,
    customer,
    savedProfileList,
    profileListLoading,
    refetchProfileListDetails,
    refetchSingleAccountDetails,
  };
};

export function defaultPaymentInfoFormData(
  data: OneTimePayConfigData,
): PaymentInfoModel {
  return {
    paymentAmount: data.paymentAmount || '',
    paymentDate: data.paymentDate || getFormattedToday(),
  };
}

export function usePaymentInfoFormState(
  data: OneTimePayConfigData,
): FormState<PaymentInfoModel> {
  return useFormState(defaultPaymentInfoFormData(data), {
    validate: paymentInfoFormRules,
    validationContext: {
      maxPayDate: data.maxPaymentDate,
      fullAmount: data.fullAmount,
      selectedReconnectOption: data.selectedReconnectOption,
      minimumAmount: data.minimumAmount,
      maximumAmount: data.maximumAmount,
      charitySelection: data.charitySelection,
    },
  });
}
